import { SvgIcon } from '@mui/material';

export const DragIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 10 16" style={{ width: 16, height: 16 }}>
      <path
        d="M1.75 16C1.26667 16 0.854167 15.8292 0.5125 15.4875C0.170833 15.1458 0 14.7333 0 14.25C0 13.7667 0.170833 13.3542 0.5125 13.0125C0.854167 12.6708 1.26667 12.5 1.75 12.5C2.23333 12.5 2.64583 12.6708 2.9875 13.0125C3.32917 13.3542 3.5 13.7667 3.5 14.25C3.5 14.7333 3.32917 15.1458 2.9875 15.4875C2.64583 15.8292 2.23333 16 1.75 16ZM8.25 16C7.76667 16 7.35417 15.8292 7.0125 15.4875C6.67083 15.1458 6.5 14.7333 6.5 14.25C6.5 13.7667 6.67083 13.3542 7.0125 13.0125C7.35417 12.6708 7.76667 12.5 8.25 12.5C8.73333 12.5 9.14583 12.6708 9.4875 13.0125C9.82917 13.3542 10 13.7667 10 14.25C10 14.7333 9.82917 15.1458 9.4875 15.4875C9.14583 15.8292 8.73333 16 8.25 16ZM1.75 9.75C1.26667 9.75 0.854167 9.57917 0.5125 9.2375C0.170833 8.89583 0 8.48333 0 8C0 7.51667 0.170833 7.10417 0.5125 6.7625C0.854167 6.42083 1.26667 6.25 1.75 6.25C2.23333 6.25 2.64583 6.42083 2.9875 6.7625C3.32917 7.10417 3.5 7.51667 3.5 8C3.5 8.48333 3.32917 8.89583 2.9875 9.2375C2.64583 9.57917 2.23333 9.75 1.75 9.75ZM8.25 9.75C7.76667 9.75 7.35417 9.57917 7.0125 9.2375C6.67083 8.89583 6.5 8.48333 6.5 8C6.5 7.51667 6.67083 7.10417 7.0125 6.7625C7.35417 6.42083 7.76667 6.25 8.25 6.25C8.73333 6.25 9.14583 6.42083 9.4875 6.7625C9.82917 7.10417 10 7.51667 10 8C10 8.48333 9.82917 8.89583 9.4875 9.2375C9.14583 9.57917 8.73333 9.75 8.25 9.75ZM1.75 3.5C1.26667 3.5 0.854167 3.32917 0.5125 2.9875C0.170833 2.64583 0 2.23333 0 1.75C0 1.26667 0.170833 0.854167 0.5125 0.5125C0.854167 0.170833 1.26667 0 1.75 0C2.23333 0 2.64583 0.170833 2.9875 0.5125C3.32917 0.854167 3.5 1.26667 3.5 1.75C3.5 2.23333 3.32917 2.64583 2.9875 2.9875C2.64583 3.32917 2.23333 3.5 1.75 3.5ZM8.25 3.5C7.76667 3.5 7.35417 3.32917 7.0125 2.9875C6.67083 2.64583 6.5 2.23333 6.5 1.75C6.5 1.26667 6.67083 0.854167 7.0125 0.5125C7.35417 0.170833 7.76667 0 8.25 0C8.73333 0 9.14583 0.170833 9.4875 0.5125C9.82917 0.854167 10 1.26667 10 1.75C10 2.23333 9.82917 2.64583 9.4875 2.9875C9.14583 3.32917 8.73333 3.5 8.25 3.5Z"
        fill="#242424"
      />
    </SvgIcon>
  );
};
