import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import TextField from '@/components/TextField';

const useStyles = makeStyles((theme) => ({
  searchField: {
    position: 'absolute',
    right: '0',
    marginTop: '-1px',
    color: `${theme.palette.secondary.darkBlue} !important`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  error: {
    color: `${theme.palette.ui.error} !important`,
  },
  disabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
    '&:hover': {
      cursor: 'default',
    },
  },
}));

const SearchField = (props) => {
  const { isDarkTheme, onChange, ...rest } = props;
  const styles = useStyles();

  const [currentValue, setCurrentValue] = useState(props.value ?? props.defaultValue);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    onChange(event);
  };

  const handleCancel = () => {
    const newEvent = { target: { value: '' } };
    handleChange(newEvent);
  };

  return (
    <TextField
      {...rest}
      variant="search"
      isDarkTheme={isDarkTheme}
      value={currentValue}
      onChange={handleChange}
      endAdornment={
        <InputAdornment
          position="start"
          classes={{
            root: cx(styles.searchField, {
              [styles.error]: props.error,
              [styles.disabled]: props.disabled,
            }),
          }}
        >
          {currentValue ? (
            <CancelIcon onClick={handleCancel} id="SearchField_CancelButton" />
          ) : (
            <SearchIcon />
          )}
        </InputAdornment>
      }
    />
  );
};

SearchField.propTypes = {
  isDarkTheme: PropTypes.bool,
};

SearchField.defaultProps = {
  isDarkTheme: false,
};

export default SearchField;
