import { Button, Modal, Spacing, TypeaheadDropdown, Typography } from '@/components';
import { ThreeUsersIcon } from '@/assets/icons';
import { userStore } from '@/services/store/UserStore';
import { theme } from '@/config';
import styles from './DeleteMemberModal.module.css';

const PAGE_NAME = 'DeleteMemberModal';

export const DeleteMemberModal = ({
  userOptions,
  member,
  value,
  error,
  taskCount,
  onReassignMembers,
  onDelete,
  onClose,
}) => {
  const renderAssign = (value) => {
    const assignItem = userStore.getAssign(value, userOptions);
    if (!assignItem || !assignItem.label)
      return (
        <Typography
          id={`${PAGE_NAME}_dropdown_selectedValue`}
          variant="body1"
          className={styles.placeholder}
          style={{
            color: theme.palette.neutrals.greyMed,
          }}
        >
          {'Select Assignee'}
        </Typography>
      );
    return <Typography variant="body1">{assignItem.label || 'None'}</Typography>;
  };

  return (
    <Modal
      open
      isCloseIconHidden
      id={PAGE_NAME}
      onClose={onClose}
      containerClassName={styles.modalContainer}
      contentClassName={styles.successContent}
      titleClassName={styles.modalTitle}
      actions={
        <>
          <Button size="large" onClick={onDelete} id={`${PAGE_NAME}_removeMemberButton`}>
            {taskCount ? 'Remove User & Reassign Tasks' : 'Remove User'}
          </Button>
          <Button
            size="large"
            variant="tertiary"
            onClick={onClose}
            id={`${PAGE_NAME}_cancelRemoveButton`}
          >
            Cancel
          </Button>
        </>
      }
    >
      {!taskCount && <ThreeUsersIcon className={styles.threeUsersIcon} />}
      <Typography variant="h5">{`Remove ${member?.name}?`}</Typography>
      {taskCount ? (
        <>
          <Typography variant="subtitle3" className={styles.description} style={{ marginTop: 8 }}>
            They will no longer be able to access to HelpDesk.
            <br />
            <br />
            {`${member?.first} has ${taskCount} tasks. Please reassign them to another member.`}
          </Typography>
          <Spacing gap={1} />
          <TypeaheadDropdown
            label="Assign to"
            id={`${PAGE_NAME}_reAssignDropdown`}
            value={value}
            options={userOptions}
            classes={{ container: styles.assignContainer, svgIcon: styles.moreIcon }}
            renderValue={renderAssign}
            onChange={(value) => onReassignMembers(value)}
          />
        </>
      ) : (
        <Typography variant="subtitle3" className={styles.description}>
          Are you sure you want to remove this member? They will no longer be able to access to
          HelpDesk.
        </Typography>
      )}
    </Modal>
  );
};
