import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';

import { withStore } from '@/services/store';
import { Button, Chip, SearchField, Typography } from '@/components';
import { AmazonStatus } from '@/types';
import { theme } from '@/config';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { FilterPanel as FilterPanel } from './FilterPanel';
import styles from './Filter.module.css';
import CreateTaskModal from '@/pages/detail/components/DetailCreateTask/CreateTaskModal';

const PAGE_NAME = 'TaskLog_Filter';

export const Filter = withStore(
  observer(() => {
    const [openCreateModal, setOpenCreateModal] = useState(false);

    const { filterStore, accountStore, taskLogStore } = useMainStore();

    const handleMyRequestsClick = () => {
      if (filterStore.myRequestsSelected) {
        filterStore.clearFilter();
      } else {
        filterStore.applyMyRequestFilter();
      }
    };

    const handleMyTasksClick = () => {
      if (filterStore.myTasksSelected) {
        filterStore.clearFilter();
      } else {
        filterStore.applyMyTaskFilter();
      }
    };

    const searchChangeHandler = (event) => {
      filterStore.setSearch(event.target.value.trim());
    };

    const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 500), []);

    const handleCloseCreateModal = useCallback(() => {
      setOpenCreateModal(false);
    }, []);

    const handleOpenCreateModal = useCallback(() => {
      setOpenCreateModal(true);
    }, []);

    return (
      <div id={`${PAGE_NAME}_container`} className={styles.filtersRoot}>
        <div className={styles.filtersContainer}>
          <Typography variant="h5" className={styles.title}>
            {accountStore.selectedAccount?.name}
          </Typography>
          <div className={styles.filterButtons} id={`${PAGE_NAME}_buttonGroupContainer`}>
            {!filterStore.getDefaultStatusSelected && (
              <Button
                id="btn-clear-filter"
                size="medium"
                variant="tertiary"
                onClick={() => filterStore.clearFilter()}
              >
                Reset
              </Button>
            )}
            <SearchField
              id="txt-log-search"
              placeholder="Search"
              value={filterStore.search}
              style={{ width: 270 }}
              classes={{ root: styles.searchRoot }}
              onChange={debouncedChangeHandler}
            />
            <Button
              id="btn-my-requests"
              size="medium"
              variant="secondary"
              classes={{ startIcon: styles.checkButtonIcon }}
              sx={{
                backgroundColor: filterStore.myRequestsSelected && '#ebe5ff !important',
                border:
                  filterStore.myRequestsSelected &&
                  `2px solid ${theme.palette.primary.purple} !important`,
              }}
              onClick={handleMyRequestsClick}
            >
              <Typography className={styles.myListLabel}>My Requests</Typography>
            </Button>
            <Button
              id="btn-my-tasks"
              size="medium"
              variant="secondary"
              classes={{ startIcon: styles.checkButtonIcon }}
              sx={{
                backgroundColor: filterStore.myTasksSelected && '#ebe5ff !important',
                border:
                  filterStore.myTasksSelected &&
                  `2px solid ${theme.palette.primary.purple} !important`,
              }}
              onClick={handleMyTasksClick}
            >
              <Typography className={styles.myListLabel}>My Tasks</Typography>
            </Button>
            <Button
              id="btn-filter"
              size="medium"
              variant="secondary"
              sx={{
                backgroundColor: filterStore.filterSelected && '#ebe5ff !important',
                border:
                  filterStore.filterSelected &&
                  `2px solid ${theme.palette.primary.purple} !important`,
              }}
              onClick={() => filterStore.onToggleFilterPanel()}
            >
              Filter
              {filterStore.allFilteredCount > 0 && (
                <Chip
                  variant={AmazonStatus.closed}
                  label={filterStore.allFilteredCount}
                  className={styles.chip}
                />
              )}
            </Button>
            <Button
              id="btn-export-csv"
              size="large"
              variant="tertiary"
              onClick={() => taskLogStore.exportToCSV()}
            >
              Export
            </Button>
            <Button
              id="btn-create-request"
              variant="gradient"
              startIcon={<AddIcon className={styles.addIcon} />}
              onClick={handleOpenCreateModal}
            >
              Create
            </Button>
            <CreateTaskModal open={openCreateModal} handleClose={handleCloseCreateModal} />
          </div>
        </div>

        {filterStore.isFilterPanelOpen && <FilterPanel />}
      </div>
    );
  }),
);
