import PropTypes from 'prop-types';

import AddIcon from '@/assets/icons/Add.svg';
import CloseIcon from '@/assets/icons/Close.svg';
import CloseFilledIcon from '@/assets/icons/Close-Filled.svg';
import ExpandMoreIcon from '@/assets/icons/ExpandMore.svg';
import ExpandLessIcon from '@/assets/icons/ExpandLess.svg';
import ExportIcon from '@/assets/icons/Export.svg';
import FilterIcon from '@/assets/icons/Filter.svg';
import FlagIcon from '@/assets/icons/Flag.svg';
import FlagFilledIcon from '@/assets/icons/Flag-Filled.svg';
import PinIcon from '@/assets/icons/Pin.svg';
import PinFilledIcon from '@/assets/icons/Pin-Filled.svg';
import SearchIcon from '@/assets/icons/Search.svg';
import UserIcon from '@/assets/icons/User.svg';
import CsvFileIcon from '@/assets/icons/CsvFile.svg';
import ExcelFileIcon from '@/assets/icons/ExcelFile.svg';
import DocFileIcon from '@/assets/icons/DocFile.svg';
import CancelTask from '@/assets/icons/CancelTask.svg';
import CloseCase from '@/assets/icons/CloseCase.svg';
import Success from '@/assets/icons/Success.svg';
import Hourglass from '@/assets/icons/Hourglass.svg';
import CancelIcon from '@/assets/icons/Cancel.svg';
import RemoveUserIcon from '@/assets/icons/RemoveUser.svg';
import FeedbackIcon from '@/assets/icons/Feedback.svg';
import PhotoIcon from '@/assets/icons/Photo.svg';
import ImageIcon from '@/assets/icons/ImageFile.svg';
import EmptyCommentIcon from '@/assets/icons/EmptyComment.svg';
import EmptyQuestionIcon from '@/assets/icons/EmptyQuestion.svg';
import {
  BulletIcon,
  CopyIcon,
  CopyOutlineIcon,
  DoneIcon,
  WorkflowIcon,
  UploadIcon,
  SaveAltIcon,
  DeleteIcon,
  RemoveIcon,
} from '@/assets/icons';

const IconFamiles = [
  { type: 'add', icon: AddIcon, defaultWidth: 29.93 },
  { type: 'close', icon: CloseIcon, variant: 'outlined' },
  { type: 'close', icon: CloseFilledIcon, variant: 'filled' },
  { type: 'expand-more', icon: ExpandMoreIcon },
  { type: 'expand-less', icon: ExpandLessIcon },
  { type: 'export', icon: ExportIcon },
  { type: 'filter', icon: FilterIcon },
  { type: 'flag', icon: FlagIcon, variant: 'outlined' },
  { type: 'flag', icon: FlagFilledIcon, variant: 'filled' },
  { type: 'pin', icon: PinIcon, variant: 'outlined' },
  { type: 'pin', icon: PinFilledIcon, variant: 'filled' },
  { type: 'search', icon: SearchIcon },
  { type: 'user', icon: UserIcon },
  { type: 'csv', icon: CsvFileIcon },
  { type: 'doc', icon: DocFileIcon },
  { type: 'excel', icon: ExcelFileIcon },
  { type: 'cancel', icon: CancelIcon },
  { type: 'cancel-task', icon: CancelTask },
  { type: 'close-case', icon: CloseCase },
  { type: 'success', icon: Success },
  { type: 'hourglass', icon: Hourglass },
  { type: 'remove-user', icon: RemoveUserIcon },
  { type: 'feedback', icon: FeedbackIcon },
  { type: 'photo', icon: PhotoIcon },
  { type: 'image', icon: ImageIcon },
  { type: 'comments', icon: EmptyCommentIcon },
  { type: 'questions', icon: EmptyQuestionIcon },
  { type: 'bullet' },
  { type: 'copy' },
  { type: 'copy-outline' },
  { type: 'done' },
  { type: 'workflow' },
  { type: 'upload' },
  { type: 'save-alt' },
  { type: 'delete' },
  { type: 'remove' },
];

const Icon = ({ type, variant, width, height, className, onClick }) => {
  let matchedIcon;
  matchedIcon = IconFamiles.find(
    (icon) => icon.type === type && (!icon?.variant || icon?.variant === variant),
  );
  const imgWidth = width || matchedIcon?.defaultWidth || 24;
  const imgHeight = height || matchedIcon?.mediumHeight || 24;

  if (type === 'bullet') return <BulletIcon />;
  if (type === 'copy') return <CopyIcon />;
  if (type === 'copy-outline') return <CopyOutlineIcon />;
  if (type === 'done') return <DoneIcon />;
  if (type === 'workflow') return <WorkflowIcon />;
  if (type === 'upload') return <UploadIcon />;
  if (type === 'save-alt') return <SaveAltIcon />;
  if (type === 'delete') return <DeleteIcon />;
  if (type === 'remove') return <RemoveIcon />;

  return (
    <img
      src={matchedIcon.icon}
      style={{ width: imgWidth, height: imgHeight }}
      className={className}
      alt={type}
      onClick={onClick}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.oneOf(IconFamiles.map((item) => item.type)).isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined']),
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  variant: 'outlined',
  width: null,
  height: null,
};

export default Icon;
