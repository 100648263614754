import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: '26px !important',
    boxShadow: '0px 8px 25px rgb(0 0 0 / 15%) !important',
  },
  menuList: {
    background: '#fff !important',
    padding: '0px !important',
  },
  menuItem: {
    height: '46px !important',
    background: '#fff !important',
    '&:hover': {
      background: `${theme.palette.options.hover} !important`,
    },
  },
}));

function ContextMenu({ options, mouseX, mouseY, onClose, onClick }) {
  const classes = useStyles();

  const handleClick = (item) => () => {
    onClick(item);
  };

  if (options.length === 0 || !mouseX) {
    return null;
  }

  return (
    <Menu
      keepMounted
      open={mouseY !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
      }
      classes={{ paper: classes.menuPaper, list: classes.menuList }}
    >
      {options.map((item) => (
        <MenuItem onClick={handleClick(item)} key={item.label} classes={{ root: classes.menuItem }}>
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

export default ContextMenu;
