import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  sideContainer: {
    display: 'flex',
    zIndex: 10,
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.darkBlue,
  },
  sideContent: {
    position: 'relative',
    padding: '10px 0 0 10px',
    height: 'calc(100% - 10px)',
    width: '228px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'all 0.3s ease-in-out',
  },
  collapseWidth: {
    width: '64px',
    padding: '0',
  },
  collapsedGap: {
    gap: '12px',
  },
  topDivider: {
    width: 'calc(100% - 12px)',
    height: 1,
    background: '#364E92',
  },
  divider: {
    width: '100%',
    height: 1,
    margin: '12px 0',
    background: '#364E92',
  },
  topItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingRight: '8px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&:hover': {
      /* width */
      '&::-webkit-scrollbar': {
        width: 8,
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        borderRadius: 8,
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#546596',
        borderRadius: 8,
      },
    },
  },
  customizeItem: {
    bottom: 0,
    paddingRight: 8,
  },
  orgSelect: {
    flex: 1,
    display: 'flex !important',
    height: '40px !important',
    alignItems: 'center !important',
    backgroundColor: `transparent !important`,
    border: `none !important`,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  orgIcon: {
    transform: 'rotate(0deg) !important',
    color: '#fff !important',
  },
  orgIconOpen: {
    transform: 'rotate(180deg) !important',
  },
  paper: {
    marginTop: '-16px !important',
    borderRadius: '26px !important',
    maxHeight: '500px !important',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15) !important',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      marginTop: 24,
      marginBottom: 24,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      background: 'rgba(16, 20, 30, 0.15)',
      borderRadius: 26,
    },
  },
  selectValue: {
    fontWeight: '700 !important',
    fontSize: '16px !important',
    letterSpacing: '0.15px !important',
    color: '#fff',
    lineHeight: '26px',
    height: '26px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '4px 4px 20px 10px',
  },
  menuItem: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    gap: '16px !important',
  },
  accordionRoot: {
    height: 'fit-content !important',
    backgroundColor: `transparent !important`,
  },
  accordionSelectedSummary: {
    background: `${theme.palette.secondary.lightBlue} !important`,
    borderRadius: '8px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    fontWeight: '700 !important',
  },
  accordionSummary: {
    height: '44px !important',
    minHeight: '44px !important',
    padding: '0px !important',
    flexDirection: 'row-reverse',
    borderRadius: '8px !important',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: 8,
      color: theme.palette.secondary.contrast,
      fontWeight: 400,
    },
    '&:hover > .expandIcon': {
      color: `${theme.palette.primary.darkPurple} !important`,
    },
  },
  accordionSummaryContent: {
    paddingRight: '0px !important',
    margin: '0px !important',
  },
  collapseContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  collapseIcon: {
    zIndex: 10,
    top: '18px',
    width: '15px !important',
    height: '13px !important',
    background: '#FFF',
    padding: '6px 5px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    borderRadius: '100px',
    cursor: 'pointer',
    position: 'absolute',
    right: '-12px',
    '&:hover': {
      background: `${theme.palette.neutrals.grayBlue} !important`,
    },
  },
  collapsed: {
    transform: 'rotate(180deg) !important',
  },
  expandIcon: {
    color: `${theme.palette.primary.purple} !important`,
  },
  accordionExpanded: {
    backgroundColor: `transparent !important`,
    '&:before': {
      opacity: `1 !important`,
    },
  },
  accordionDetails: {
    padding: '6px 0px 6px 16px !important',
    gap: 0,
  },
  searchRoot: {
    width: 'calc(100% - 25px)',
    marginBottom: '10px',
  },
}));
