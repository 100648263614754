import { PageType } from './page';

export const DetailTaskType = {
  task: PageType.generalTask,
  question: PageType.question,
  case: PageType.case,
};

export const DetailTaskTypeOptions = [
  {
    value: DetailTaskType.task,
    label: 'Trademark Infringement',
  },
  {
    value: DetailTaskType.question,
    label: 'Question',
  },
  {
    value: DetailTaskType.case,
    label: 'Contact',
  },
];

export const FeedbackType = {
  satisfied: ':smile:',
  dissatisfied: ':disappointed:',
  qaError: ':warning:',
  prodError: ':red_circle:',
};
