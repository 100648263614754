import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { BulletIcon } from '@/assets/icons';
import './TimeLine.css';

const TimeLine = ({ children, isLastNode }) => {
  const ref = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (setHeight) {
      setHeight(ref.current.clientHeight);
    }
  }, []);

  return (
    <div id={`TimeLine_div`} className="timeLine" ref={ref}>
      <div className="markWrapper">
        <BulletIcon style={{ zIndex: 2 }} />
        {!isLastNode && <div className="line" style={{ marginTop: 3, height }} />}
      </div>
      {children}
    </div>
  );
};

TimeLine.propTypes = {
  children: PropTypes.node.isRequired,
  isLastNode: PropTypes.bool,
  height: PropTypes.number,
  setHeight: PropTypes.func,
};

TimeLine.defaultProps = {
  isLastNode: false,
  height: 0,
  setHeight: undefined,
};

export default TimeLine;
