import { MAX_QUANTITY } from './constants';

export function getExpandedChildrenCount(accounts, selectedAccountId) {
  let count = 0;
  for (let i = 0; i < accounts.length; i += 1) {
    const account = accounts[i];
    count += 1;
    if (account.id === selectedAccountId) {
      return count;
    }
    if (account.isExpanded && account.children.length > 0) {
      count += getExpandedChildrenCount(account.children, selectedAccountId);
      return count;
    }
  }
  return 0;
}

export function isValidQuantity(quantity) {
  return Number(quantity) > 0 && Number(quantity) < MAX_QUANTITY;
}

export function pluralToSingular(plural) {
  const irregularPlurals = {
    children: 'child',
  };

  if (irregularPlurals.hasOwnProperty(plural)) {
    return irregularPlurals[plural];
  }

  const rules = [
    [/(quiz)zes$/i, '$1'],
    [/([m|l])ice$/i, '$1ouse'],
    [/(matr|vert|ind)ices$/i, '$1ix'],
    [/(x|ch|ss|sh)es$/i, '$1'],
    [/s$/i, ''],
  ];

  for (const [pattern, replacement] of rules) {
    if (pattern.test(plural)) {
      return plural.replace(pattern, replacement);
    }
  }

  return plural;
}
