import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { detailApi } from '@/api';
import { getAssignFromName, showErrorNotification } from '@/utils';
import { NotificationText } from '@/types';
import { moveTicket } from '@/api/ticket';
import { userStore } from '@/services/store';
import { setError } from '@/utils/errors';

export class WorkListStore {
  isLoading = true;
  ticketList = null;
  organizationId = null;
  isEditing = false;
  originalTicket = null;
  assigns = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action,
      ticketList: observable,
      updateTaskList: action,
      organizationId: observable,
      fetchRequestDetail: action,
      filteredTicketList: computed,
      isEditing: observable,
      setIsEditing: action,
      fetchMoveTicket: action,

      assigns: observable,

      originalTicket: observable,
      setOriginalTicket: action,
    });

    reaction(
      () => this.assigns,
      () => this.updateTicketList(),
      { fireImmediately: true },
    );
  }

  setIsLoading(status) {
    this.isLoading = status;
  }

  updateTaskList(
    ticketId,
    status,
    assign,
    title,
    description,
    priority,
    remainingWork,
    estimatedCompletion,
  ) {
    this.ticketList = this.ticketList.map((item) => ({
      ...item,
      state: item.ticketId === ticketId ? status : item.state,
      assign: item.ticketId === ticketId ? assign : item.assign,
      title: item.ticketId === ticketId ? title : item.title,
      description: item.ticketId === ticketId ? description : item.description,
      priority: item.ticketId === ticketId ? priority : item.priority,
      remainingWork: item.ticketId === ticketId ? remainingWork : item.remainingWork,
      estimatedCompletion:
        item.ticketId === ticketId ? estimatedCompletion : item.estimatedCompletion,
    }));
  }

  updateTicketList() {
    if (this.assigns && this.ticketList) {
      this.ticketList = this.ticketList.map((ticket) => ({
        ...ticket,
        assign: getAssignFromName(ticket.assignedToName, this.assigns),
      }));
    }
  }

  async fetchRequestDetail(ticketId, isRefresh = true) {
    if (isRefresh) this.setIsLoading(true);
    try {
      const taskTree = await detailApi.getTaskTree(ticketId);
      const organizationId = taskTree?.[0]?.organizationId;
      runInAction(() => {
        this.organizationId = organizationId;
        this.ticketList = taskTree;
        this.updateTicketList();
      });
    } catch (err) {
      setError(err, true);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
    if (isRefresh) this.setIsLoading(false);
  }

  get filteredTicketList() {
    return this.ticketList.map((ticket) => ({
      ...ticket,
      serviceType:
        userStore.workflowConfigList.find(({ workflow }) => workflow === ticket.serviceType)
          ?.displayName ||
        ticket.serviceType ||
        ticket.objectType,
    }));
  }

  setIsEditing(value) {
    this.isEditing = value;
  }

  async fetchMoveTicket(ticketId, toParentId) {
    this.setIsLoading(true);
    let result;
    try {
      result = await moveTicket(ticketId, toParentId);
    } catch (err) {
      setError(err, false, 'Move ticket tree failed');
    }
    if (result) {
      try {
        await this.fetchRequestDetail(ticketId);
      } catch (err) {
        setError(err, false, 'Refresh ticket tree failed');
      }
    }
    this.setIsLoading(false);
  }

  setOriginalTicket(value) {
    this.originalTicket = value;
  }

  dispose() {
    // TBD
  }
}
