import { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { DetailList } from './DetailList.js';
const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: 'fit-content',
  opacity: 0.2,
};
function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  let { x, y } = currentOffset;
  const transform = `translate(${x - 360}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
export const CustomDragLayer = ({ dataList }) => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const previewTicketList = useMemo(
    () => (item ? dataList.filter((ticket) => ticket.ticketPath.includes(item.id)) : []),
    [item, dataList],
  );

  return (
    isDragging && (
      <div style={layerStyles}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          {item && <DetailList data={previewTicketList} />}
        </div>
      </div>
    )
  );
};
