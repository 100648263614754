import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { InputLabel, Popover, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { AnchorOrigin } from '@/types';
import { getExpandedChildrenCount } from '@/utils';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@/components';
import { useCreateTaskStore } from '@/pages/create/stores/useCreateTaskStore';
import { userStore } from '@/services/store';
import TreeSubItem from '../OrgAccessDropdown/TreeSubItem';

import styles from './TreeListDropdown.module.css';

const PAGE_NAME = 'TreeListDropdown';

export const TreeListDropdown = observer(
  ({
    placeholder,
    anchorPosition = AnchorOrigin.auto,
    label,
    value,
    width,
    disabled = false,
    onChange,
  }) => {
    const classes = makeStyles({ paper: { width } })();
    const createTaskStore = useCreateTaskStore();
    const dropdownRef = useRef();
    const listContainerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [hoverWorkflowId, setHoverWorkflowId] = useState(value);
    const selectedWorkflow = createTaskStore.getWorkflowFromName(
      createTaskStore.workflowTreeList,
      value,
    );
    const popoverPosition =
      anchorPosition === AnchorOrigin.bottom ||
      (anchorPosition === AnchorOrigin.auto &&
        dropdownRef?.current?.getBoundingClientRect().y < window.innerHeight - 356)
        ? { anchorOrigin: 'bottom', transformOrigin: 'top', marginTop: '6px' }
        : { anchorOrigin: 'top', transformOrigin: 'bottom', marginTop: '-6px' };

    useEffect(() => {
      if (Boolean(anchorEl)) {
        let count = getExpandedChildrenCount(createTaskStore.workflowTreeList, value);
        const timeout = setTimeout(() => {
          listContainerRef.current?.scrollTo({
            top: (count - 1) * 50,
            behavior: 'smooth',
          });
        }, 500);
        return () => clearTimeout(timeout);
      } else {
        setSearch('');
        setHoverWorkflowId(value);
      }
    }, [anchorEl, value]);

    const handleClose = () => {
      setAnchorEl(null);
      setSearch('');
    };

    const handleSelectWorkflow = (workflow) => {
      setAnchorEl(null);
      onChange(workflow);
    };

    const filteredWorkflowList = useMemo(() => {
      if (!userStore.isNonAdmin && search) {
        return userStore.workflowConfigList.reduce((res, config) => {
          const startIndex = config.workflow.toLowerCase().indexOf(search.toLowerCase().trim());
          if (startIndex === -1) return res;
          return [
            ...res,
            {
              ...config,
              match: [startIndex, startIndex + search.length],
            },
          ];
        }, []);
      }
      return createTaskStore.workflowTreeList;
    }, [
      search,
      createTaskStore.workflowTreeList,
      userStore.workflowConfigList,
      userStore.isNonAdmin,
    ]);

    const handleKeyDownProcess = (event) => {
      if (!search) return;
      let selectedIndex = filteredWorkflowList.findIndex(
        (config) => config.workflow === hoverWorkflowId,
      );

      const optionCount = filteredWorkflowList?.length;
      if (optionCount) {
        let isChangeSelectedIndex = false;
        if (event.keyCode === 40) {
          event.stopPropagation();
          selectedIndex = (selectedIndex + 1) % optionCount;
          isChangeSelectedIndex = true;
        } else if (event.keyCode === 38) {
          event.stopPropagation();
          selectedIndex = selectedIndex - 1;
          if (selectedIndex < 0) selectedIndex = optionCount - 1;
          isChangeSelectedIndex = true;
        } else if (event.keyCode === 13) {
          event.stopPropagation();
          onChange(hoverWorkflowId);
          handleClose();
        }
        if (isChangeSelectedIndex) {
          listContainerRef.current.scrollTo(
            0,
            selectedIndex === optionCount - 1
              ? listContainerRef.current.scrollHeight
              : ((listContainerRef.current.scrollHeight - listContainerRef.current.offsetHeight) *
                  selectedIndex) /
                  optionCount,
          );
          setHoverWorkflowId(filteredWorkflowList[selectedIndex].workflow);
        }
      }
    };

    const workflowCategoryItem = (config, layer) => {
      return (
        <Accordion
          key={config.workflow}
          classes={{
            accordionRoot: styles.accordionRoot,
            accordionExpanded: styles.accordionExpanded,
          }}
          sx={{
            '&:before': {
              display: layer !== 0 && 'none',
            },
          }}
          id={`${PAGE_NAME}_workflows_accordion`}
          expanded={!!config.isExpanded}
        >
          <AccordionSummary
            id={`${PAGE_NAME}_workflows_accordion_content`}
            containerClassName={cx({
              [styles.accordionSummary]: 1,
              [styles.accordionSelectedSummary]: hoverWorkflowId === config.workflow,
            })}
            contentClassName={styles.accordionSummaryContent}
          >
            <TreeSubItem
              key={config.workflow}
              isChild={config.children?.length > 0}
              isExpanded={!!config.isExpanded}
              isSelected={value === config.workflow}
              isHover={hoverWorkflowId === config.workflow}
              label={config.displayName || config.workflow}
              match={config.match}
              onSelect={(e) => {
                if (!config.children?.length) {
                  e.stopPropagation();
                  handleSelectWorkflow(config.workflow);
                }
              }}
              onToggleExpand={(e) => {
                e.stopPropagation();
                createTaskStore.onToggleExpandCategory(config.workflow);
              }}
            />
          </AccordionSummary>
          {config.children?.length > 0 && (
            <AccordionDetails className={cx(styles.accordionDetails)}>
              {config.children.map((category) => workflowCategoryItem(category, layer + 1))}
            </AccordionDetails>
          )}
        </Accordion>
      );
    };

    const handleDropdownClick = (event) => {
      if (!disabled && createTaskStore.workflowTreeList.length > 0) {
        createTaskStore.expandSelectedWorkflow(value);
        setAnchorEl(event.currentTarget);
      }
    };

    return (
      <>
        {!!label && (
          <InputLabel
            shrink
            htmlFor="component-selection-label"
            classes={{
              root: cx(styles.selectLabel, {
                [styles.selectLabelError]: false,
                [styles.selectLabelDisabled]: false,
              }),
            }}
          >
            {label}
          </InputLabel>
        )}
        <div
          ref={dropdownRef}
          className={cx(styles.accessDropdown, styles.defaultDropdown, {
            [styles.selected]: Boolean(anchorEl),
            [styles.disabled]: disabled || createTaskStore.workflowTreeList.length === 0,
          })}
          onClick={handleDropdownClick}
        >
          <Typography
            variant="body1"
            className={(disabled || !selectedWorkflow) && styles.selectLabelDisabled}
          >
            {selectedWorkflow?.displayName || selectedWorkflow?.workflow || placeholder}
          </Typography>
          <div
            className={cx(styles.moreDefaultIcon, {
              [styles.moreDefaultRotate]: Boolean(anchorEl),
            })}
          >
            <ExpandMoreIcon
              className={cx(styles.moreDefaultSvg, { [styles.selectLabelDisabled]: disabled })}
            />
          </div>
        </div>

        <Popover
          anchorEl={anchorEl}
          id={`${PAGE_NAME}_Popover`}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: popoverPosition.anchorOrigin,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: popoverPosition.transformOrigin,
            horizontal: 'center',
          }}
          onClose={handleClose}
          classes={{ paper: cx(styles.paper, classes.paper) }}
          sx={{ marginTop: popoverPosition.marginTop }}
        >
          <div className={styles.popoverContent}>
            {!userStore.isNonAdmin && (
              <div className={styles.searchWrapper}>
                <TextField
                  autoFocus
                  value={search}
                  placeholder="Search Services"
                  classes={{ root: styles.searchMembers }}
                  InputProps={{
                    classes: {
                      root: styles.inputRoot,
                      input: styles.inputInput,
                      notchedOutline: styles.inputNotchedOutline,
                      focused: styles.inputFocused,
                    },
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={handleKeyDownProcess}
                  style={{ width: '100%' }}
                  id={`${PAGE_NAME}_Search`}
                />
              </div>
            )}
            <div className={styles.sideContainer} ref={listContainerRef}>
              {!filteredWorkflowList.length && search && (
                <div className={styles.noMatchingWrapper} id={`${PAGE_NAME}_noMatching_container`}>
                  <SearchIcon className={styles.noMatchingIcon} />
                  <Typography variant="h6">No Matching Criteria</Typography>
                  <Typography variant="body1" className={styles.noMatchingLabel}>
                    Please try another search term.
                  </Typography>
                </div>
              )}
              {filteredWorkflowList.map((config) => workflowCategoryItem(config, 0))}
            </div>
          </div>
        </Popover>
      </>
    );
  },
);
