import { observer } from 'mobx-react-lite';
import { useRef, useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import cx from 'clsx';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { ListItemText } from '@mui/material';

import { Config } from '@/config';
import { FilterType, MAX_QUERY_LENGTH } from '@/utils/constants';
import {
  MultiSelect,
  Checkbox,
  Switch,
  TypeaheadDropdownV2,
  Typography,
  MultiRadioDropdown,
  DateTimePicker,
} from '@/components';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { RequestTypes } from '@/types';
import { cloneDeep } from 'lodash';
import {
  formatLocalDateTime,
  getFiltersQuery,
  getLogsEndpointURL,
  showErrorNotification,
} from '@/utils';

import styles from './FilterPanel.module.css';

const isMobile = false;
const SCROLL_STATE = {
  none: 0,
  left: 1,
  middle: 2,
  right: 3,
};
const PAGE_NAME = 'TaskLog_FilterPanel';

export const FilterPanel = withSize()(
  observer(({ size }) => {
    const { filterStore, tableStore, accountStore } = useMainStore();
    const [scrollState, setScrollState] = useState(SCROLL_STATE.none);
    const [scrollChanged, setScrollChanged] = useState(false);
    const filterContainerRef = useRef(null);

    useEffect(() => {
      if (!filterContainerRef.current) return;

      const offsetX = filterContainerRef.current.scrollLeft;
      const clientWidth = size.width;
      const scrollWidth = filterContainerRef.current.scrollWidth;

      if (clientWidth < scrollWidth) {
        if (offsetX === 0) {
          setScrollState(SCROLL_STATE.right);
        } else if (offsetX > 0 && offsetX + clientWidth < scrollWidth) {
          setScrollState(SCROLL_STATE.middle);
        } else if (offsetX + clientWidth >= scrollWidth) {
          setScrollState(SCROLL_STATE.left);
        }
      } else {
        setScrollState(SCROLL_STATE.none);
      }
    }, [size, scrollChanged]);

    const handleSelectedValueChange = (filterKey, value) => {
      const newFilters = cloneDeep(filterStore.filters);
      newFilters[filterKey].selectedValues = value;

      const query = getFiltersQuery(
        tableStore.pageNumber,
        tableStore.rowsPerPage,
        newFilters,
        filterStore.search,
      );
      const sort = { key: tableStore.sortKey, dir: tableStore.sortDirection };
      const url = getLogsEndpointURL(
        `${Config.NEW_API}/Ticket/log`,
        accountStore.selectedAccount.id,
        query,
        sort,
      );
      if (url.length < MAX_QUERY_LENGTH) {
        filterStore.updateSelectedValues(filterKey, value);
      } else {
        showErrorNotification("You can't select too many filters");
      }
    };

    const handleMoveLeftScroll = (e) => {
      if (isMobile) {
        filterContainerRef.current.scrollTo({
          left: Math.min(filterContainerRef.current.scrollLeft - 216, 0),
          behavior: 'smooth',
        });
        setTimeout(() => setScrollChanged((prev) => !prev), 200);
      } else {
        filterContainerRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        setScrollState(SCROLL_STATE.right);
      }
    };

    const handleMoveRightScroll = (e) => {
      if (isMobile) {
        const left = Math.min(
          filterContainerRef.current.scrollLeft + 216,
          filterContainerRef.current.scrollWidth - size.width,
        );
        filterContainerRef.current.scrollTo({ left, behavior: 'smooth' });
        setTimeout(() => setScrollChanged((prev) => !prev), 200);
      } else {
        const left = filterContainerRef.current.scrollWidth - size.width;
        filterContainerRef.current.scrollTo({ left, behavior: 'smooth' });
        setScrollState(SCROLL_STATE.left);
      }
    };

    return (
      <div className={styles.filtersGroup} ref={filterContainerRef} id={`${PAGE_NAME}_container`}>
        {(scrollState === SCROLL_STATE.middle || scrollState === SCROLL_STATE.left) && (
          <div id={`${PAGE_NAME}_leftShadow`} className={styles.leftShadow}>
            <div
              className={cx(styles.arrowIcon, styles.leftArrowIcon)}
              onClick={handleMoveLeftScroll}
            >
              <ChevronLeft />
            </div>
          </div>
        )}
        {Object.keys(filterStore.filteredResult).map((filterKey) => {
          const label = filterStore.filteredResult[filterKey].label;
          const selectedValue = filterStore.filteredResult[filterKey]?.selectedValues;
          const options = filterStore.filteredResult[filterKey].values.map((item) => ({
            email: item.email,
            value: item.value,
            label: item.label,
            type: item.type,
          }));
          const hasSelectAllClear = filterStore.filteredResult[filterKey]?.hasSelectAllClear;

          if (filterKey === FilterType.assign || filterKey === FilterType.owner) {
            return (
              <TypeaheadDropdownV2
                id={`btn-filter-${filterKey}`}
                key={filterKey}
                label={label}
                selectedValue={selectedValue}
                hasSelectAllClear={hasSelectAllClear}
                classes={{ container: styles.searchMembers, svgIcon: styles.moreIcon }}
                renderValue={(selected, label) => {
                  let firstAssignName = filterStore.getOwnerName(selected[0]);
                  firstAssignName = `${firstAssignName.slice(0, 15)}${
                    firstAssignName.length > 15 ? '...' : ''
                  }`;
                  let valueLabel;
                  if (selected.length === 0 && label) {
                    valueLabel = label;
                  } else if (selected.length > 1) {
                    valueLabel = `${firstAssignName} +${selected.length - 1} more`;
                  } else {
                    valueLabel = firstAssignName;
                  }
                  return (
                    <Typography
                      id={`${PAGE_NAME}_selectedValue`}
                      variant="body1"
                      style={{ whiteSpace: 'nowrap', minWidth: '158px' }}
                    >
                      {valueLabel}
                    </Typography>
                  );
                }}
                options={options}
                onChange={(value) => handleSelectedValueChange(filterKey, value)}
              />
            );
          }
          if (filterKey === FilterType.type) {
            return (
              <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
                <MultiRadioDropdown
                  id={`service-filter-${filterKey}`}
                  key={filterKey}
                  value={selectedValue}
                  defaultValue={RequestTypes.all}
                  placeholder={label}
                  options={options}
                  style={{ width: 'fit-content', minWidth: '220px' }}
                  onChange={(value) => handleSelectedValueChange(filterKey, value)}
                />
              </div>
            );
          }
          if (filterKey === FilterType.createdAfter) {
            return (
              <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
                <DateTimePicker
                  isDate
                  isSmallWidth
                  placeholder="Created After"
                  className={styles.dateFilter}
                  value={selectedValue?.[0]}
                  maxDate={Date.now()}
                  onChange={(value) =>
                    handleSelectedValueChange(filterKey, [formatLocalDateTime(value, 'MM/DD/YYYY')])
                  }
                />
              </div>
            );
          }
          return (
            <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
              <MultiSelect
                id={`btn-filter-${filterKey}`}
                key={filterKey}
                isSearchBar={hasSelectAllClear}
                value={selectedValue}
                placeholder={label}
                options={options}
                hasSelectAllClear={hasSelectAllClear}
                style={{ width: 'fit-content', minWidth: '220px' }}
                renderValue={(selected, placeholder) => {
                  if (selected.length === 0 && placeholder) {
                    return <Typography variant="body1">{placeholder}</Typography>;
                  }
                  const selectedLabel =
                    options.find(({ value }) => value === selected?.[0])?.label || '';
                  const label = `${selectedLabel.slice(0, 20)}${
                    selectedLabel.length > 20 ? '...' : ''
                  }`;
                  if (selected.length > 1) {
                    return `${label} +${selected.length - 1} more`;
                  }
                  return label;
                }}
                onChange={(value) => handleSelectedValueChange(filterKey, value)}
                MenuItemComponent={({ label, checked, match }) => {
                  if (filterKey === FilterType.viewBy) {
                    return (
                      <>
                        <Switch checked={checked} id={`${PAGE_NAME}_switchItem`} />
                        <ListItemText>{label}</ListItemText>
                      </>
                    );
                  }
                  return (
                    <>
                      <Checkbox
                        checked={checked}
                        id={`${PAGE_NAME}_checkboxItem`}
                        classes={{ formRoot: styles.menuItemCheckbox }}
                      />
                      <span>
                        {match ? (
                          <>
                            {label.slice(0, match[0])}
                            <span className={styles.highlight}>
                              {label.slice(match[0], match[1])}
                            </span>
                            {label.slice(match[1])}
                          </>
                        ) : (
                          label
                        )}
                      </span>
                    </>
                  );
                }}
              />
            </div>
          );
        })}
        {(scrollState === SCROLL_STATE.middle || scrollState === SCROLL_STATE.right) && (
          <div id={`${PAGE_NAME}_rightShadow`} className={styles.rightShadow}>
            <div
              className={cx(styles.arrowIcon, styles.rightArrowIcon)}
              onClick={handleMoveRightScroll}
            >
              <ChevronRight />
            </div>
          </div>
        )}
      </div>
    );
  }),
);
