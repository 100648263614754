import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MuiAccordion from '@mui/material/Accordion';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    borderBottom: `1px solid #F6F6F6`,
    boxSizing: 'border-box',
  },
  accordionExpanded: {
    margin: '0px !important',
    background: 'rgba(58, 28, 157, 0.02) !important',
    '& .MuiCollapse-root': {
      overflow: 'hidden !important',
    },
  },
}));

const AccordionComponent = ({ classes, ...props }) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    id="AccordionComponent_MuiAccordion"
    classes={{
      root: classes.accordionRoot,
      expanded: classes.accordionExpanded,
    }}
    {...props}
  />
);

const Accordion = ({ children, expanded, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const handleChange = (panel) => (event, newExpanded) => {
    setIsExpanded(newExpanded ? panel : false);
  };

  return (
    <AccordionComponent
      classes={useStyles()}
      expanded={isExpanded}
      onChange={handleChange(true)}
      {...props}
    >
      {children}
    </AccordionComponent>
  );
};

Accordion.propTypes = {
  children: PropTypes.node,
};

Accordion.defaultProps = {
  children: null,
};

export default Accordion;
