import PropTypes from 'prop-types';
import cx from 'clsx';
import TableCell from '@mui/material/TableCell';
import { Chip } from '@/components';
import { useStyles } from './sharedStyles';

export const StatusCell = ({ value, isLastRow }) => {
  const styles = useStyles();

  return (
    <TableCell
      key="status"
      id="StatusCell"
      className={cx(styles.bodyCell, styles.statusCell, { [styles.bodyLastLeftCell]: isLastRow })}
    >
      <Chip variant={value} label={value} />
    </TableCell>
  );
};

StatusCell.propTypes = {
  value: PropTypes.string,
};

StatusCell.defaultProps = {
  value: 'Unknown',
};
