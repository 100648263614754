import PropTypes from 'prop-types';
import cx from 'clsx';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  accordionSummaryRoot: {
    padding: '0px 20px',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.secondary.darkBlue,
    },
  },
  accordionSummaryContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
}));

const AccordionSummary = ({
  children,
  containerClassName,
  contentClassName,
  classes,
  ...props
}) => {
  const styles = useStyles();
  return (
    <MuiAccordionSummary
      id="AccordionSummary_MuiAccordionSummary"
      classes={{
        ...classes,
        root: cx(styles.accordionSummaryRoot, containerClassName),
        content: cx(styles.accordionSummaryContent, contentClassName),
      }}
      {...props}
    >
      {children}
    </MuiAccordionSummary>
  );
};

AccordionSummary.propTypes = {
  children: PropTypes.node,
  containerClassName: PropTypes.string,
};

AccordionSummary.defaultProps = {
  children: null,
  containerClassName: null,
};

export default AccordionSummary;
