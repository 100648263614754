import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useCreateTaskStore } from '@/pages/create/stores/useCreateTaskStore';
import { withStore } from '@/services/store';
import { ServiceTypes } from '@/types';

const SpecificForm = ({ showError, showWarning }) => {
  const [SpecifiedForm, setSpecifiedForm] = useState(null);
  const { workflowStore, workflowInfo } = useCreateTaskStore();
  useEffect(() => {
    const staticFormName = workflowInfo?.workflow;
    if (staticFormName) {
      import(`../SpecificForms/${staticFormName}`)
        .then((module) => {
          setSpecifiedForm(() => module[staticFormName]);
        })
        .catch(() => {
          workflowStore.setServiceType(ServiceTypes.single);
          workflowStore.updateStaticFormValue(null, true);
          setSpecifiedForm(null);
        });
    }
  }, [workflowInfo?.workflow]);

  if (!SpecifiedForm) {
    return <></>;
  }

  return <SpecifiedForm showError={showError} showWarning={showWarning} />;
};

export default withStore(observer(SpecificForm));
