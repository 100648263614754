import PropTypes from 'prop-types';
import cx from 'clsx';
import { noop } from 'lodash';
import { Modal as MUIModal } from '@mui/material';
import Button from '../Button';

import { useStyles } from './styles';
const PAGE_NAME = 'Confirm_Dialog';
function ConfirmDialog({
  open,
  title,
  description,
  okText,
  cancelText,
  middleText,
  onOk,
  onCancel,
  containerClassName,
  contentClassName,
  onMiddle,
  ...otherProps
}) {
  const styles = useStyles();

  return (
    <MUIModal
      id={`${PAGE_NAME}_MUIModal`}
      open={open}
      BackdropProps={{ classes: { root: styles.backDrop } }}
      className={styles.modalWrapper}
      {...otherProps}
    >
      <div className={cx(styles.modalContainer, containerClassName)}>
        {title ? (
          <div className={styles.modalTitle}>
            <div className={styles.modalTitleText}>{title}</div>
          </div>
        ) : (
          <div style={{ height: 40 }} />
        )}

        {description && (
          <div className={cx(styles.modalContent, contentClassName)}>{description}</div>
        )}

        <div className={styles.modalActions}>
          <Button
            variant="primary"
            size="large"
            onClick={onOk}
            id="ConfirmDialog_MUIModal_actionButton"
          >
            {okText}
          </Button>
          {middleText && onMiddle && (
            <Button
              className={styles.cancelButton}
              variant="tertiary"
              size="large"
              onClick={onMiddle}
              id="ConfirmDialog_MUIModal_middleButton"
            >
              {middleText}
            </Button>
          )}
          {cancelText && (
            <Button
              className={styles.cancelButton}
              variant="tertiary"
              size="large"
              onClick={onCancel}
              id="ConfirmDialog_MUIModal_cancelButton"
            >
              {cancelText}
            </Button>
          )}
        </div>
      </div>
    </MUIModal>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  contentClassName: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  onOk: noop,
  onCancel: noop,
  contentClassName: null,
};

export default ConfirmDialog;
