export const PriorityOptions = [
  {
    value: 2,
    label: 'Normal',
  },
  {
    value: 1,
    label: 'High',
  },
  {
    value: 0,
    label: 'Urgent',
  },
];

export const getPriority = (key) => {
  return PriorityOptions.find((option) => option.value === key);
};

export const getPriorityLabel = (key) => {
  return getPriority(key)?.label || PriorityOptions[0].label;
};

export const ObjectType = {
  none: 'none',
  request: 'Request',
  case: 'Case',
  task: 'Task',
  workflow: 'Workflow',
  question: 'Question',
  issue: 'Issue',
  phase: 'Phase',
};
