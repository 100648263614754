import PropTypes from 'prop-types';
import cx from 'clsx';
import TableCell from '@mui/material/TableCell';
import Typography from '@/components/Typography';
import { ColumnType } from '@/types';
import { useStyles } from './sharedStyles';

export const TextCell = ({ column, value, match = null }) => {
  const styles = useStyles();

  return (
    <TableCell key={column} className={cx(styles.bodyCell, styles.textCell)}>
      <Typography
        variant="caption"
        className={cx({
          [styles.description]: column === ColumnType.description,
          [styles.textLabel]: column !== ColumnType.description,
        })}
      >
        {match ? (
          <>
            {value.slice(0, match[0])}
            <span style={{ fontWeight: 700, backgroundColor: '#E8E4F9' }}>
              {value.slice(match[0], match[1])}
            </span>
            {value.slice(match[1])}
          </>
        ) : (
          value
        )}
      </Typography>
    </TableCell>
  );
};

TextCell.propTypes = {
  column: PropTypes.string.isRequired,
  match: PropTypes.arrayOf(PropTypes.number),
};

TextCell.defaultProps = {
  match: null,
};
