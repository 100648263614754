import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Config } from '@/config';
import { theme } from '@/config/theme';
import { withStore, userStore } from '@/services/store';
import { BASE_ROUTES, UrlSearch } from '@/types';
import { Typography, Avatar, Tooltip, TextLink, Icon } from '@/components';
import { useMainStore } from '../main/stores/useMainStore';
import { useCreateTaskStore } from '../create/stores/useCreateTaskStore';
import { SettingsPopopver } from './SettingsPopover';
import { isMobileDetected } from '@/utils';
import { FeedbackLink } from '@/utils/constants';

import styles from './TopNavigation.module.css';

const TopNavigation = () => {
  const { routerStore } = useMainStore();
  const createTaskStore = useCreateTaskStore();
  const [settingAnchorEl, setSettingAnchorEl] = useState(null);
  const currentUser = userStore.currentUser;
  const navigate = useNavigate();
  const prevUrl = useRef();

  const isCurrentPageMain = routerStore.currentPage === BASE_ROUTES.main;

  const handleOpenPopupMenu = (event) => {
    setSettingAnchorEl(event.currentTarget);
  };

  const handleClosePopupMenu = () => {
    setSettingAnchorEl(null);
  };

  const handleDropDownKeyDownProcess = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      setSettingAnchorEl(event.currentTarget);
    }
  };

  const hasUrlParamsChanged = (urlParams) => {
    const prevUrlParams = prevUrl.current;

    return (
      !prevUrlParams ||
      Number(urlParams.get(UrlSearch.organization)) !==
        Number(prevUrlParams[UrlSearch.organization]) ||
      Number(urlParams.get(UrlSearch.page)) !== Number(prevUrlParams[UrlSearch.page]) ||
      Number(urlParams.get(UrlSearch.pageRows)) !== Number(prevUrlParams[UrlSearch.pageRows])
    );
  };

  const getMainPageLink = () => {
    if (!isCurrentPageMain) {
      return `${BASE_ROUTES.main}${routerStore.lastMainPageLink}`;
    } else {
      const urlParams = new URLSearchParams(routerStore.getSearchChunkParams());

      if (hasUrlParamsChanged(urlParams)) {
        urlParams.set(UrlSearch.organization, userStore.currentUser?.organizationId);
        urlParams.set(UrlSearch.page, 1);
        urlParams.set(UrlSearch.pageRows, 10);
        return `${BASE_ROUTES.main}?${urlParams.toString()}`;
      }

      return `${BASE_ROUTES.main}?${urlParams.toString()}`;
    }
  };

  const gotoLogPage = (e) => {
    e.preventDefault();
    if (!isCurrentPageMain) {
      if (routerStore.currentPage === BASE_ROUTES.create) createTaskStore.reset(true);
      navigate(`${BASE_ROUTES.main}${routerStore.lastMainPageLink}`);
    } else {
      const urlParams = new URLSearchParams(routerStore.getSearchChunkParams());

      if (hasUrlParamsChanged(urlParams)) {
        urlParams.set(UrlSearch.organization, userStore.currentUser?.organizationId);
        urlParams.set(UrlSearch.page, 1);
        urlParams.set(UrlSearch.pageRows, 10);
        prevUrl.current = Object.fromEntries(urlParams.entries());
        routerStore.setSearchChunkParams(prevUrl.current);
      }
    }
  };

  return (
    <>
      <div className={styles.topNavigationLayout}>
        <div className={styles.topNavigationContainer}>
          <a tabIndex={0} className={styles.link} onClick={gotoLogPage} href={getMainPageLink()}>
            <div
              className={cx(styles.logoIconWrapper, {
                [styles.mobileLogoWrapper]: isMobileDetected,
              })}
              id="topNavigation_logoIcon"
            >
              <img src={userStore.organizationLogo} className={styles.logoIcon} alt="logo" />
            </div>
          </a>
          <div
            className={cx(styles.version, {
              [styles.qaVersion]: Config.APP_VERSION === 'Dev',
              [styles.hide]: Config.isReleased,
            })}
          >
            {Config.APP_VERSION}
          </div>

          <div className={styles.subAppsGroup}>
            <a tabIndex={0} className={styles.link} onClick={gotoLogPage} href={getMainPageLink()}>
              <Typography
                className={cx(styles.subAppTitle, {
                  [styles.activeSubTitle]: routerStore.currentPage === BASE_ROUTES.main,
                })}
                id="topNavigation_taskLog"
              >
                Service Log
              </Typography>
              <div
                className={cx(styles.subAppTitleUnerline, {
                  [styles.hide]: routerStore.currentPage !== BASE_ROUTES.main,
                })}
              />
            </a>
          </div>

          <div className={styles.actionsWrapper}>
            <div className={styles.settingsContainer}>
              <TextLink tabIndex="-1" href={FeedbackLink}>
                <Tooltip tabIndex={0} title="Give Feedback" arrow>
                  <IconButton id="topNavigation_feedbackIconButton">
                    <Icon type="feedback" width={18} height={19} />
                  </IconButton>
                </Tooltip>
              </TextLink>
              <TextLink tabIndex="-1" href={userStore.organizationHelpLink}>
                <Tooltip tabIndex={0} title="Help" arrow>
                  <IconButton id="topNavigation_helpIconButton">
                    <HelpOutlineIcon color={`${theme.palette.neutrals.greyMed}`} />
                  </IconButton>
                </Tooltip>
              </TextLink>
              <div
                tabIndex={0}
                onKeyDown={handleDropDownKeyDownProcess}
                className={styles.settingsGroup}
                onClick={handleOpenPopupMenu}
                id="topNavigation_settingsGroup"
              >
                <Avatar
                  name={currentUser.name}
                  variant={currentUser.type}
                  className={styles.avatar}
                />
                <Typography className={styles.nameLabel} variant="subtitle1">
                  {currentUser.first}
                </Typography>
                {Boolean(settingAnchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SettingsPopopver anchorEl={settingAnchorEl} handleClose={handleClosePopupMenu} />
    </>
  );
};

export default withStore(observer(TopNavigation));
