import { makeObservable } from 'mobx';

export class NotificationsStore {
  enqueueSnackbar = null;

  constructor() {
    makeObservable(this, {});
  }

  dispose() {
    // TBD
  }

  showError(message) {
    this.enqueueSnackbar?.(message, { variant: 'error', autoHideDuration: 5000 });
  }

  showSuccess(message) {
    this.enqueueSnackbar?.(message, { variant: 'success', autoHideDuration: 5000 });
  }

  showWarning(message) {
    this.enqueueSnackbar?.(message, { variant: 'warning', autoHideDuration: 5000 });
  }

  showInfo(message) {
    this.enqueueSnackbar?.(message, { variant: 'info', autoHideDuration: 5000 });
  }

  reInitEnqueueSnackbar(fn) {
    this.enqueueSnackbar = fn;
  }
}
