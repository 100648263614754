import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextLink, Typography } from '@/components';
import { AutomationStatusIcons } from '@/types';

const useStyles = makeStyles(({ palette }) => ({
  automationTimeLog: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    padding: '6px 20px',
    borderRadius: '30px',
    color: palette.secondary.darkBlue,
    backgroundColor: palette.options.hover,
    cursor: 'default',
    width: 'fit-content',
  },
  statusIcon: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: ({ statusColor }) => statusColor,
  },
  tooltipContainer: {
    padding: '8px 13px 8px 8px !important',
    borderRadius: '12px !important',
    background: 'white !important',
    border: '1px solid #DDD',
    boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.10)',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    color: palette.secondary.darkBlue,
  },
  icon: {
    width: '20px !important',
    height: '20px !important',
    color: ({ statusColor }) => `${statusColor} !important`,
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  name: {
    fontWeight: 700,
    color: palette.neutrals.grey,
  },
  description: {
    color: palette.neutrals.greyBlueDark,
  },
}));

const AutomationTooltip = ({ automation }) => {
  const classes = useStyles();
  const { name, automationTime } = automation;

  return (
    <div className={classes.columnWrapper}>
      <Typography variant="subtitle2" className={classes.name}>
        {name}
      </Typography>
      <Typography variant="subtitle2" className={classes.description}>
        {automationTime}
      </Typography>
    </div>
  );
};

const StatusIcon = ({ type, statusColor }) => {
  const Icon = AutomationStatusIcons?.[type];
  const classes = useStyles({ statusColor });

  return Icon ? (
    <Icon className={classes.icon} />
  ) : (
    <div className={classes.statusIcon} style={{ backgroundColor: statusColor }} />
  );
};

export const AutomationLog = ({ automation, isAdminUser }) => {
  const classes = useStyles();
  if (!automation) return null;
  const { name, color, buildId } = automation;

  return (
    <Tooltip
      title={<AutomationTooltip automation={automation} />}
      classes={{ tooltip: classes.tooltipContainer }}
      arrow
    >
      <div className={classes.rowWrapper}>
        <TextLink
          href={
            isAdminUser &&
            buildId &&
            `https://dev.azure.com/WorkflowLabs/Helpdesk/_build/results?buildId=${buildId}&view=results`
          }
          className={classes.linkIcon}
        >
          <StatusIcon type={name} statusColor={color} />
        </TextLink>
      </div>
    </Tooltip>
  );
};
