import { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import { withStore } from '@/services/store';
import { BASE_ROUTES, UrlSearch } from '@/types';
import { Accordion, AccordionDetails, AccordionSummary, SearchField } from '@/components';
import { SideCollapseIcon } from '@/assets/icons';
import { useMainStore } from '../main/stores/useMainStore';
import SideMenuItem from './SideMenuItem';
import SideIconItem from './SideIconItem';
import { useDetailStore } from '../detail/stores/useDetailStore';

import { useStyles } from './styles';

const PAGE_NAME = 'SideMenu';

const SideMenu = ({ isDetailPage = false, organizationId = null }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { accountStore, taskLogStore, filterStore, routerStore } = useMainStore();
  const { workListStore, detailStore } = useDetailStore();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isDetailPageLoaded = useRef(false);
  const selectedOrgId = accountStore.selectedAccount?.id;
  const isLoading =
    accountStore.isLoading ||
    (isDetailPage && (workListStore.isLoading || detailStore.isLoading)) ||
    (!isDetailPage && (taskLogStore.isLoading || filterStore.isLoading));

  const containerRef = useRef();

  useEffect(() => {
    if (
      !isLoading &&
      selectedOrgId &&
      accountStore.filteredAccounts.length > 0 &&
      containerRef.current
    ) {
      let top = 0;
      const findPosition = (accounts) => {
        return accounts.some((account) => {
          top += 44;
          if (account.id === selectedOrgId) {
            return true;
          }
          let result = false;
          if (account.children.length > 0 && account.isExpanded) {
            result = findPosition(account.children);
          }
          return result;
        });
      };
      findPosition(accountStore.filteredAccounts);
      if (Math.abs(containerRef.current.scrollTop - top) > containerRef.current.clientHeight - 60) {
        containerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedOrgId, accountStore.filteredAccounts, isLoading]);

  useEffect(() => {
    if (!isDetailPageLoaded.current && isDetailPage && organizationId) {
      accountStore.onSelectAccount(organizationId);
      accountStore.updateExpandedAccounts(
        accountStore.allAccounts,
        accountStore.selectedAccount?.path,
      );
      isDetailPageLoaded.current = true;
    } else {
      isDetailPageLoaded.current = false;
    }
  }, [isDetailPage, organizationId, accountStore.selectedAccount]);

  const handleSelectAccount = (id) => {
    if (isDetailPage) {
      navigate(
        `${BASE_ROUTES.main}${
          routerStore.lastMainPageLink.includes(UrlSearch.organization)
            ? routerStore.lastMainPageLink.replace(/(organization=).*?(&)/, '$1' + id + '$2')
            : `?organization=${id}`
        }`,
      );
    } else {
      accountStore.onSelectAccount(id);
    }
  };

  const renderSubAccountItem = (account, layer) => {
    if (isCollapsed) {
      return (
        <Fragment key={account.id}>
          <SideIconItem
            isSelected={accountStore.selectedAccount?.id === account.id}
            name={account.name}
            onSelect={(e) => {
              e.stopPropagation();
              handleSelectAccount(account.id);
            }}
          />
          {account.children.length > 0 &&
            account.isExpanded &&
            account.children.map((subAccount) => renderSubAccountItem(subAccount, layer + 1))}
        </Fragment>
      );
    }

    return (
      <Accordion
        key={account.id}
        classes={{
          accordionRoot: styles.accordionRoot,
          accordionExpanded: styles.accordionExpanded,
        }}
        id={`${PAGE_NAME}_accounts_accordion`}
        expanded={!!account.isExpanded}
      >
        <AccordionSummary
          id={`${PAGE_NAME}_accounts_accordion_content`}
          containerClassName={cx(styles.accordionSummary, {
            [styles.accordionSelectedSummary]: selectedOrgId === account.id,
          })}
          contentClassName={styles.accordionSummaryContent}
        >
          <SideMenuItem
            isChild={!accountStore.search && account.children.length > 0}
            isExpanded={!!account.isExpanded}
            isSelected={selectedOrgId === account.id}
            label={account.name}
            count={account.count}
            onSelect={(e) => {
              e.stopPropagation();
              handleSelectAccount(account.id);
            }}
            onToggleExpand={(e) => {
              e.stopPropagation();
              accountStore.onToggleExpand(account.id);
            }}
          />
        </AccordionSummary>
        {account.children.length > 0 && (
          <AccordionDetails className={cx(styles.accordionDetails)}>
            {account.children.map((subAccount) => renderSubAccountItem(subAccount, layer + 1))}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  return (
    <div className={styles.sideContainer}>
      <div className={cx(styles.sideContent, { [styles.collapseWidth]: isCollapsed })}>
        <div
          id={`${PAGE_NAME}_organization_group`}
          className={cx(styles.topItem, { [styles.collapsedGap]: isCollapsed })}
        >
          <div className={styles.collapseContainer}>
            <SideCollapseIcon
              className={cx(styles.collapseIcon, { [styles.collapsed]: isCollapsed })}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          </div>
        </div>
        {accountStore.allAccounts.length > 1 && !isCollapsed && (
          <SearchField
            size="small"
            id="accounts-search"
            placeholder="Search"
            value={accountStore.search}
            classes={{ root: styles.searchRoot }}
            onChange={(e) => accountStore.setSearch(e.target.value)}
          />
        )}
        <div
          id={`${PAGE_NAME}_sideMenuItem_group`}
          className={cx(styles.body, { [styles.collapsedGap]: isCollapsed })}
          ref={containerRef}
        >
          {accountStore.allAccounts.length > 0 && isCollapsed && (
            <SideIconItem
              name={
                accountStore.allAccounts.length > 1
                  ? accountStore.organization?.name
                  : accountStore.allAccounts[0].name
              }
              isSelected={accountStore.isParent}
              onSelect={() => handleSelectAccount(accountStore.organization?.id)}
            />
          )}
          {!accountStore.search && accountStore.allAccounts.length > 0 && !isCollapsed && (
            <SideMenuItem
              isAllAccount
              isCollapsed={isCollapsed}
              isSelected={accountStore.isParent}
              label={
                accountStore.allAccounts.length > 1
                  ? accountStore.organization?.name
                  : accountStore.allAccounts[0].name
              }
              onSelect={() => handleSelectAccount(accountStore.organization?.id)}
            />
          )}
          {accountStore.filteredAccounts.map((account) => renderSubAccountItem(account, 0))}
        </div>
      </div>
    </div>
  );
};

export default withStore(observer(SideMenu));
