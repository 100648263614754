import { makeStyles } from '@mui/styles';
import { Typography } from '@/components';

export const useStyles = makeStyles({
  highlight: {
    fontWeight: 700,
  },
});

const MatchedLabel = ({ name, isBold, match }) => {
  const styles = useStyles();
  if (match) {
    return (
      <span id="MatchedLabel">
        {name.slice(0, match[0])}
        <span className={styles.highlight}>{name.slice(match[0], match[1])}</span>
        {name.slice(match[1])}
      </span>
    );
  }

  return (
    <Typography id="MatchedLabel" variant="body1" style={{ fontWeight: isBold ? 600 : 400 }}>
      {name}
    </Typography>
  );
};

export default MatchedLabel;
