import { Stack } from '@mui/material';
import { TextField } from '@/components';

const PAGE_NAME = 'GoogleAds';

export const GoogleAds = ({ username, onUsernameChange, showError, showWarning, disabled }) => {
  const getValidator = (username) => !!username;
  const handleChangeUsername = (e) => {
    const newName = e.target.value;
    onUsernameChange(newName, getValidator(newName));
  };

  return (
    <Stack spacing={2}>
      <TextField
        label="User Name*"
        value={username ?? ''}
        error={showError && !username}
        warning={showWarning && !username}
        helperText={showError && !username ? 'Please provide your name.' : null}
        onChange={handleChangeUsername}
        id={`${PAGE_NAME}_userName`}
        size="small"
        disabled={disabled}
        inactive={disabled}
      />
    </Stack>
  );
};
