import { action, makeObservable, observable, reaction, runInAction } from 'mobx';

import { getToken, showSuccessNotification } from '@/utils';
import { attachmentApi, organizationApi, platformApi } from '@/api';
import { userStore } from '@/services/store/UserStore';
import { omit } from 'lodash';
import { setError } from '@/utils/errors';

export class OrganizationStore {
  organizationInfo = null;
  isReceiveAPIKey = false;
  isCopied = false;
  isLoading = false;
  eventTypes = [];
  selectedOrgId = null;

  constructor() {
    makeObservable(this, {
      //  Organization Settings
      selectedOrgId: observable,
      setSelectedOrgId: action,

      organizationInfo: observable,

      //  Developer Settings
      isReceiveAPIKey: observable,
      setIsReceiveAPIKey: action,
      isCopied: observable,
      copyAPIKey: action,

      isLoading: observable,
      setIsLoading: action,

      eventTypes: observable,
    });

    this.disposeReloadReaction = reaction(
      () => [this.selectedOrgId],
      () => this.fetchOrganizationInfo(),
      { fireImmediately: true },
    );
  }

  setIsReceiveAPIKey(value) {
    this.isReceiveAPIKey = value;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setSelectedOrgId(value) {
    this.selectedOrgId = value;
  }

  async copyAPIKey() {
    await navigator.clipboard.writeText(getToken());
    this.isCopied = true;
    showSuccessNotification('Key copied to clipboard');
  }

  async getLogoURL(logoFile, isLogoChanged) {
    if (isLogoChanged) {
      const imageLink = await attachmentApi.uploadAttachmentBlob(logoFile);
      return imageLink;
    }
    return this.organizationInfo.profile.branding?.logoUrl;
  }

  async saveOrganizationInfo(
    selectedOrgId,
    orgName,
    orgInfo,
    orgEventSubscriptions,
    logins,
    isCaptcha,
    isCreating,
    organization,
  ) {
    this.isLoading = true;
    let res = false;
    try {
      const logoUrl = await this.getLogoURL(orgInfo.logoUrl, orgInfo.isLogoChanged);
      const eventSubscriptions = orgEventSubscriptions.reduce(
        (subscriptions, { event, link }) => ({
          ...subscriptions,
          [event]: link,
        }),
        {},
      );
      const profile = {
        ...this.organizationInfo.profile,
        logins,
        branding: {
          alias: orgInfo.alias,
          logoUrl,
          eMail: orgInfo.alias,
          supportUrl: orgInfo.helpUrl,
        },
        eventSubscriptions,
        settings: {
          isCaptcha,
        },
      };
      if (isCreating) {
        await organizationApi.createOrganization({
          organization: orgName,
          parentId: this.selectedOrgId,
          isActive: true,
          path: organization.path.join('/'),
          profile: omit(profile, 'id'),
        });
        res = true;
      } else {
        await organizationApi.updateOrganization(selectedOrgId, {
          organization: orgName,
          profile,
        });
        res = true;
        await this.fetchOrganizationInfo(selectedOrgId);
      }
      userStore.setRefresh();
    } catch (err) {
      res = false;
      setError(err, false, `${isCreating ? 'Create' : 'Update'} organization failed`);
    }
    this.isLoading = false;
    return res;
  }

  async fetchOrganizationInfo() {
    if (this.selectedOrgId) {
      this.isLoading = true;

      this.fetchEventTypes();

      this.organizationInfo = await organizationApi.getOrganization(this.selectedOrgId);

      if (userStore.organizationId === this.selectedOrgId) {
        userStore.updateLogoHelpLink(
          this.organizationInfo.profile?.branding?.logoUrl,
          this.organizationInfo.profile?.branding?.helpUrl,
        );
      }

      this.isLoading = false;
    }
  }

  async fetchEventTypes() {
    try {
      const eventTypes = await platformApi.getEventTypes();
      runInAction(() => {
        this.eventTypes = eventTypes ?? [];
      });
    } catch (err) {
      setError(err);
    }
  }

  dispose() {
    this.disposeReloadReaction.dispose();
  }
}
