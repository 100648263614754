import PropTypes from 'prop-types';
import { Link as MuiLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
});

const Link = ({ children, ...props }) => {
  const styles = useStyles();
  return (
    <MuiLink {...props} className={styles.link}>
      {children}
    </MuiLink>
  );
};

export const ConditionalLink = ({ children, to, condition }) =>
  !!condition && to ? <Link to={to}>{children}</Link> : <>{children}</>;

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Link;
