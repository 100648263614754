import React from 'react';
import { userApi } from '@/api';
import { userStore } from '@/services/store';
import { ErrorBoundaryPage } from './ErrorBoundaryPage';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ error: error.toString(), errorInfo: errorInfo?.componentStack });
    const message = `Front-end Error:\nUser Name: ${
      userStore.currentUser?.name
    }\n${error.toString()}\r\n\r\n${errorInfo.componentStack}`;

    userApi.sendErrorMessageToSlack(message);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorBoundaryPage preview={`${this.state.error}\r\n${this.state.errorInfo}`} />;
    }

    return this.props.children;
  }
}
