import PropTypes from 'prop-types';
import MuiTooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import cx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: `${theme.palette.neutrals.grey} !important`,
    borderRadius: '4px !important',
    padding: '4px 8px !important',
    fontFamily: 'Open Sans !important',
    fontWeight: '400  !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    color: 'white !important',
  },
  arrow: {
    color: `${theme.palette.neutrals.grey} !important`,
  },
}));

const Tooltip = ({ children, title, placement, arrow, className, ...props }) => {
  const styles = useStyles();

  return (
    <MuiTooltip
      title={title}
      id="ToolTip"
      placement={placement}
      arrow={arrow}
      classes={{ tooltip: cx(styles.tooltip, className), arrow: styles.arrow }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  arrow: PropTypes.bool,
};

Tooltip.defaultProps = {
  placement: 'bottom',
  arrow: false,
  title: '',
};

export default Tooltip;
