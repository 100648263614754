import PropTypes from 'prop-types';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 20,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 76,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      background: 'rgba(16, 20, 30, 0.15)',
      borderRadius: 76,
      border: '6px solid rgba(58, 28, 157, 0.0)',
    },
  },
}));

const AccordionDetails = ({ children, className, props }) => {
  const classes = useStyles();
  return (
    <MuiAccordionDetails
      id="AccordionDetails_MuiAccordionDetails"
      classes={{ root: classes.root }}
      className={className}
      {...props}
    >
      {children}
    </MuiAccordionDetails>
  );
};

AccordionDetails.propTypes = {
  children: PropTypes.node,
};

AccordionDetails.defaultProps = {
  children: null,
};

export default AccordionDetails;
