import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@/components';
import NoLogItemsImage from '@/assets/images/NoRequest.png';
import styles from './EmptyLogView.module.css';
import CreateTaskModal from '@/pages/detail/components/DetailCreateTask/CreateTaskModal';
import { useCallback, useState } from 'react';

const PAGE_NAME = 'EmptyLogView';

export const EmptyLogView = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleCloseCreateModal = useCallback(() => {
    setOpenCreateModal(false);
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    setOpenCreateModal(true);
  }, []);

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.noLogItemsWrapper}>
      <img src={NoLogItemsImage} className={styles.noLogItemsImage} alt="empty state" />
      <Typography variant="h5" className={styles.noLogItemsLabel}>
        There aren’t any requests for this account yet. Create one to get started.
      </Typography>
      <Button
        variant="gradient"
        size="xlarge"
        startIcon={<AddIcon />}
        id={`${PAGE_NAME}_createButton`}
        onClick={handleOpenCreateModal}
      >
        Create Request
      </Button>
      <CreateTaskModal open={openCreateModal} handleClose={handleCloseCreateModal} />
    </div>
  );
};
