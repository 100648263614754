import PropTypes from 'prop-types';
import cx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@/components';

const useStyles = makeStyles((theme) => ({
  navItemContainer: {
    color: theme.palette.secondary.contrast,
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 400,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '8px',
  },
  allItemHover: {
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      color: theme.palette.secondary.contrast,
    },
  },
  childItemWithMore: {
    flex: 1,
    padding: '10px 10px 10px 0px',
  },
  childItem: {
    flex: 1,
    padding: '10px',
    '&:hover': {
      color: theme.palette.secondary.contrast,
    },
  },
  itemSelected: {
    background: `${theme.palette.secondary.lightBlue} !important`,
    color: `${theme.palette.secondary.darkBlue} !important`,
    fontWeight: 700,
  },
  labelWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  label: {
    maxWidth: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
  },
  moreIcon: {
    transform: 'rotate(-90deg)',
    color: '#fff',
  },
  selectedMoreIcon: {
    transform: 'rotate(-90deg)',
    color: theme.palette.secondary.darkBlue,
  },
}));

const SideMenuItem = ({
  isChild,
  isExpanded,
  isAllAccount,
  isSelected,
  label,
  onSelect,
  onToggleExpand,
}) => {
  const styles = useStyles();

  return (
    <Tooltip title={label} placement="right" arrow>
      <div
        className={cx(styles.navItemContainer, {
          [styles.allItemHover]: isAllAccount,
          [styles.childItemWithMore]: !isAllAccount && isChild,
          [styles.childItem]: !isAllAccount && !isChild,
          [styles.itemSelected]: isSelected,
        })}
        style={{ paddingLeft: isChild ? 3 : 10 }}
        onClick={onSelect}
        id="sideNavigation_sideMenuItem"
      >
        {isChild && (
          <ExpandMoreIcon
            className={isSelected ? styles.selectedMoreIcon : styles.moreIcon}
            style={{ transform: isExpanded && 'rotate(0deg)' }}
            onClick={onToggleExpand}
          />
        )}
        <div className={styles.labelWrapper}>
          <span className={styles.label}>{label}</span>
        </div>
      </div>
    </Tooltip>
  );
};

SideMenuItem.propTypes = {
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAllAccount: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func,
};

SideMenuItem.defaultProps = {
  isChild: false,
  isExpanded: false,
  isAllAccount: false,
  isSelected: false,
  onToggleExpand: undefined,
};

export default SideMenuItem;
