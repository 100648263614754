import HelpdeskColorLightIcon from '@/assets/logo/helpdesk-color-light.svg';

export const SETTINGS_MENU = {
  myProfilePage: 'profile',
  organizationPage: 'organizations',
  developerPage: 'developerSettings',
  membersPage: 'members',
};

export const MENU_LIST = [
  { label: 'My profile', value: SETTINGS_MENU.myProfilePage },
  { label: 'Organizations', value: SETTINGS_MENU.organizationPage, isAdminPage: true },
  // { label: 'Developer Settings', value: SETTINGS_MENU.developerPage },
  { label: 'Members', value: SETTINGS_MENU.membersPage, isAdminPage: true },
];

export const DEFAULT_BRANDING_INFO = {
  organizationId: null,
  logoUrl: HelpdeskColorLightIcon,
  alias: 'notifications@workflowlabs.net',
  helpUrl: 'https://www.notion.so/Welcome-to-Workflow-Labs-1fa7a55313094f23b4553afbf2d512c5',
};

export const DEFAULT_ACCOUNT_INFO = {
  first: '',
  last: '',
  email: '',
  isActive: true,
  isAdmin: false,
  isAssignable: true,
  isContributor: true,
};

export const PAGE_MODE = {
  none: 0,
  editing: 1,
  creating: 2,
};

export const DEFAULT_ORGANIZATION = {
  name: '',
  profile: {
    branding: { isLogoChanged: true },
    logins: [],
    eventSubscriptions: [],
    settings: {},
  },
};
