import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton, Stack } from '@mui/material';
import cx from 'clsx';

import { withStore, userStore } from '@/services/store';
import {
  Button,
  Typography,
  TextField,
  LoadingIndicator,
  OrgAccessDropdown,
  ImageUploader,
  Icon,
  Dropdown,
  Tooltip,
  Checkbox,
} from '@/components';
import { AnchorOrigin } from '@/types';
import { useSettingsStore } from '../stores/useSettingsStore';
import { isValidWebLink } from '@/utils';
import { PLATFORMS, getPlatformLoginForm } from '@/utils/staticForm';
import { DEFAULT_ORGANIZATION, PAGE_MODE } from '../constants';

import styles from './OrganizationSettings.module.css';

const PAGE_NAME = 'OrganizationSettings';
const DEFAULT_WIDTH = 280;

const OrganizationSettings = () => {
  const { organizationStore, membersStore } = useSettingsStore();
  const [orgName, setOrgName] = useState('');
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [pageMode, setPageMode] = useState(PAGE_MODE.none);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [organizationProfile, setOrganizationProfile] = useState(null);
  const [logins, setLogins] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(PLATFORMS.none);
  const [orgEventSubscriptions, setOrgEventSubscriptions] = useState(null);
  const isOrganizationEditing = pageMode !== PAGE_MODE.none;

  const platformTypeOption = useMemo(
    () =>
      userStore.platformTypes
        .filter(({ id }) => !logins.some(({ platform }) => id === platform))
        .map(({ id, name }) => ({
          id,
          value: id,
          label: name,
        })),
    [userStore.platformTypes, logins],
  );

  const handleAddLogin = (platformId) => {
    const newPlatformsData = [...logins, { platform: platformId, configuration: {} }];
    setSelectedPlatform(PLATFORMS.none);
    setLogins(newPlatformsData);
  };

  const handleRemoveLogin = (platformId) => {
    const newPlatformsData = logins.filter(
      (platformLogin) => platformLogin.platform !== platformId,
    );
    setLogins(newPlatformsData);
  };

  const handleChangeLogin = (platformId, value, isValid) => {
    const newPlatformsData = logins.map((platformLogin) =>
      platformLogin.platform === platformId
        ? { ...platformLogin, isValid, configuration: value }
        : platformLogin,
    );
    setLogins(newPlatformsData);
  };

  const handleChangeUsername = (platformId, name, isValid) => {
    const newPlatformsData = logins.map((platformLogin) =>
      platformLogin.platform === platformId
        ? { ...platformLogin, isValid, Username: name }
        : platformLogin,
    );
    setLogins(newPlatformsData);
  };

  const initializeOrganization = (orgInfo) => {
    if (orgInfo) {
      const { name, profile } = orgInfo;
      setOrgName(name);
      setIsCaptcha(!!profile?.settings?.isCaptcha);
      setOrganizationProfile(profile?.branding);
      setLogins(profile?.logins ?? []);
      setOrgEventSubscriptions(
        Object.entries(profile?.eventSubscriptions ?? {}).map(([event, link]) => ({
          event,
          link,
        })),
      );
    }
  };

  useEffect(() => {
    organizationStore.setSelectedOrgId(userStore.organizationId);
  }, [userStore.organizationId]);

  useEffect(() => {
    if (pageMode !== PAGE_MODE.creating) initializeOrganization(organizationStore.organizationInfo);
  }, [organizationStore.organizationInfo, pageMode]);

  const orgEventTypeOptions = useMemo(
    () =>
      orgEventSubscriptions?.map(({ event }, index) =>
        organizationStore.eventTypes.filter(
          ({ value }) =>
            value === event ||
            !orgEventSubscriptions.some(
              ({ event: eventName }, eventIndex) => eventIndex !== index && eventName === value,
            ),
        ),
      ),
    [organizationStore.eventTypes, orgEventSubscriptions],
  );

  const handleAddOrgClick = () => {
    setPageMode(PAGE_MODE.creating);
    initializeOrganization(DEFAULT_ORGANIZATION);
  };

  const handleSaveOrganization = async () => {
    setIsSaveButtonClicked(true);
    if (
      orgName &&
      isValidWebLink(organizationProfile.helpUrl) &&
      organizationProfile.logoUrl &&
      logins.every(
        (platformLogin) => platformLogin.isValid || !Object.keys(platformLogin).includes('isValid'),
      ) &&
      !orgEventSubscriptions?.some(({ event, link }) => !event || !isValidWebLink(link))
    ) {
      const result = await organizationStore.saveOrganizationInfo(
        organizationStore.selectedOrgId,
        orgName,
        organizationProfile,
        orgEventSubscriptions,
        logins,
        isCaptcha,
        pageMode === PAGE_MODE.creating,
        membersStore.getAccountFromId(membersStore.allAccounts, organizationStore.selectedOrgId),
      );
      if (result) {
        handleChangeOrganization({ isLogoChanged: false });
        setIsSaveButtonClicked(false);
        setPageMode(PAGE_MODE.none);
      }
    }
  };

  const handleChangeOrganization = (param) => {
    setOrganizationProfile({
      ...organizationProfile,
      ...param,
    });
  };

  const handleChangeEventSubscriptions = (changedIndex, param) => {
    setOrgEventSubscriptions((prev) =>
      prev.map((eventSubscription, index) =>
        index === changedIndex ? { ...eventSubscription, ...param } : eventSubscription,
      ),
    );
  };

  const handleDropFile = (files) => {
    handleChangeOrganization({ logoUrl: files[0], isLogoChanged: true });
  };

  const handleFileInputChange = async (event) => {
    event.preventDefault();
    handleChangeOrganization({ logoUrl: event.target.files[0], isLogoChanged: true });
  };

  if (!organizationProfile || organizationStore.isLoading) return <LoadingIndicator fullScreen />;
  return (
    <div className={styles.accountPageContainer}>
      <div className={styles.organizationSettings}>
        <div id={`${PAGE_NAME}_headerContainer`} className={styles.infoGroupContainer}>
          <Typography variant="h5">Organization Settings</Typography>
          <div id={`${PAGE_NAME}_organizationContainer`} className={styles.organizationHeader}>
            <div className={styles.organizationList}>
              <OrgAccessDropdown
                label={`${pageMode === PAGE_MODE.creating ? 'Parent ' : ''}Organization*`}
                anchorPosition={AnchorOrigin.bottom}
                value={organizationStore.selectedOrgId}
                error={isSaveButtonClicked && !userStore.organizationId}
                width={466}
                onChange={(accountId) => organizationStore.setSelectedOrgId(accountId)}
              />
            </div>
            {!isOrganizationEditing && (
              <div className={styles.buttonWrapper}>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={handleAddOrgClick}
                  id={`${PAGE_NAME}_editOrganizationButton`}
                  className={styles.button}
                >
                  New
                </Button>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => setPageMode(PAGE_MODE.editing)}
                  id={`${PAGE_NAME}_editOrganizationButton`}
                  className={styles.button}
                >
                  Edit
                </Button>
              </div>
            )}
          </div>
          <div className={styles.organizationHeader}>
            <Typography variant="body1">Configure settings per organization.</Typography>
          </div>
          <TextField
            label="Name*"
            value={orgName}
            error={isSaveButtonClicked && !orgName}
            onChange={(e) => setOrgName(e.target.value)}
            style={{ width: 466 }}
            inactive={!isOrganizationEditing}
            disabled={!isOrganizationEditing}
          />

          <Checkbox
            label="Is Captcha"
            checked={isCaptcha}
            direction="row"
            disabled={!isOrganizationEditing}
            onChange={(e) => setIsCaptcha(e.target.checked)}
          />
        </div>

        <div className={styles.divideLine} />

        <div id={`${PAGE_NAME}_logoInformationContainer`} className={styles.infoGroupContainer}>
          <Typography variant="h6">Workspace Logo*</Typography>
          <Typography variant="body1">
            Customize the logo that appears in the top navigation. Recommended dimensions: 145px x
            30px.
          </Typography>
          <ImageUploader
            file={
              organizationProfile.isLogoChanged || !organizationProfile.logoUrl
                ? organizationProfile.logoUrl
                : { preview: organizationProfile.logoUrl }
            }
            handleDropFile={handleDropFile}
            handleFileInputChange={handleFileInputChange}
            contentClassName={styles.logoContainer}
            dropZoneClassName={cx(styles.logoArea, {
              [styles.hasFile]: !!organizationProfile.logoUrl,
              [styles.editingBackground]: isOrganizationEditing,
            })}
            error={
              isSaveButtonClicked &&
              !organizationProfile.logoUrl &&
              'Please upload a logo in order to proceed.'
            }
            previewClassName={styles.logoImage}
            disabled={!isOrganizationEditing}
            isClearMode
            clearIcon={
              isOrganizationEditing && (
                <IconButton
                  classes={{ root: styles.removeIcon }}
                  onClick={() => handleChangeOrganization({ logoUrl: null, isLogoChanged: true })}
                >
                  <Icon type="cancel" />
                </IconButton>
              )
            }
          />
        </div>

        <div className={styles.divideLine} />

        <div className={styles.infoGroupContainer}>
          <Typography variant="h6">Event Subscriptions</Typography>
          {!isOrganizationEditing && !orgEventSubscriptions?.length && (
            <Typography variant="body1">Edit the event subscriptions.</Typography>
          )}
          {orgEventSubscriptions &&
            orgEventSubscriptions.map(({ event, link }, index) => (
              <div key={`${event}_${index}`} className={styles.eventSubscriptionWrapper}>
                <Dropdown
                  label="Event*"
                  placeholder="Select Event"
                  id={`${PAGE_NAME}_events`}
                  value={event}
                  error={isSaveButtonClicked && !event}
                  options={orgEventTypeOptions[index]}
                  onChange={(e) => handleChangeEventSubscriptions(index, { event: e.target.value })}
                  style={{ width: DEFAULT_WIDTH }}
                  disabled={!isOrganizationEditing}
                />
                <TextField
                  label="Event Hyperlink*"
                  value={link}
                  error={isSaveButtonClicked && !isValidWebLink(link)}
                  onChange={(e) => handleChangeEventSubscriptions(index, { link: e.target.value })}
                  style={{ width: DEFAULT_WIDTH }}
                  inactive={!isOrganizationEditing}
                  disabled={!isOrganizationEditing}
                />
                {isOrganizationEditing && (
                  <Button
                    size="large"
                    variant="secondary"
                    onClick={() =>
                      setOrgEventSubscriptions((prev) =>
                        prev.filter((_, eventIndex) => eventIndex !== index),
                      )
                    }
                    id={`${PAGE_NAME}_editOrganizationButton`}
                    className={styles.button}
                  >
                    Delete
                  </Button>
                )}
              </div>
            ))}
          {isOrganizationEditing &&
            orgEventSubscriptions?.length !== organizationStore.eventTypes?.length && (
              <Button
                size="large"
                variant="secondary"
                onClick={() => setOrgEventSubscriptions((prev) => [...prev, {}])}
                id={`${PAGE_NAME}_editOrganizationButton`}
                className={styles.button}
              >
                Add
              </Button>
            )}
        </div>

        <div className={styles.divideLine} />

        <div className={styles.infoGroupContainer}>
          <Typography variant="h6">Support Documentation Link </Typography>
          <Typography variant="body1">
            Edit the support documentation link that appears in the top navigation.
          </Typography>
          <TextField
            label="Support Hyperlink*"
            value={organizationProfile.helpUrl}
            error={isSaveButtonClicked && !isValidWebLink(organizationProfile.helpUrl)}
            style={{ width: 694, marginTop: '30px' }}
            onChange={(e) => handleChangeOrganization({ helpUrl: e.target.value })}
            inactive={!isOrganizationEditing}
            disabled={!isOrganizationEditing}
          />
        </div>

        <div className={styles.divideLine} />

        <div className={styles.infoGroupContainer}>
          <Typography variant="h6">Platforms</Typography>
          {logins.map(({ Username, platform, configuration }) => {
            const platformInfo = getPlatformLoginForm(platform);
            const PlatformLogin = platformInfo.form;
            return (
              <div key={platform} className={styles.wrapper}>
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle1">{platformInfo.name}</Typography>
                  {isOrganizationEditing && (
                    <Tooltip title="Remove Platform" arrow>
                      <IconButton
                        classes={{ root: styles.deleteButton }}
                        onClick={() => handleRemoveLogin(platform)}
                      >
                        <Icon type="close" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
                <PlatformLogin
                  username={Username}
                  showError={isSaveButtonClicked}
                  disabled={!isOrganizationEditing}
                  defaultValue={configuration}
                  onUsernameChange={(name, isValid) => {
                    handleChangeUsername(platform, name, isValid);
                  }}
                  onChange={(value, isValid) => {
                    handleChangeLogin(platform, value, isValid);
                  }}
                />
              </div>
            );
          })}
          {isOrganizationEditing && !!platformTypeOption.length && (
            <Stack spacing={2} direction="row" alignItems="end">
              <Dropdown
                label="Select Platform"
                id={`${PAGE_NAME}_platformType`}
                value={selectedPlatform}
                options={platformTypeOption}
                onChange={(e) => setSelectedPlatform(e.target.value)}
                size="small"
                sx={{ width: 270 }}
              />
              <Button
                id={`${PAGE_NAME}_add_login`}
                variant="secondary"
                classes={{ root: styles.secondaryButton }}
                disabled={!selectedPlatform}
                onClick={() => handleAddLogin(selectedPlatform)}
              >
                Add
              </Button>
            </Stack>
          )}
        </div>

        <div className={styles.divideLine} />

        <div className={styles.infoGroupContainer}>
          <Typography variant="h6">Email Notifications</Typography>
          <Typography variant="body1">
            To change the email and name that the notifications appear to be sent from, please
            contact your account administrator.
          </Typography>
        </div>

        {isOrganizationEditing && (
          <div className={styles.buttonWrapper}>
            <Button
              size="large"
              variant="primary"
              onClick={handleSaveOrganization}
              id={`${PAGE_NAME}_saveButton`}
            >
              Save
            </Button>
            <Button
              size="large"
              variant="tertiary"
              onClick={() => {
                initializeOrganization(organizationStore.organizationInfo);
                setPageMode(PAGE_MODE.none);
                setIsSaveButtonClicked(false);
              }}
              id={`${PAGE_NAME}_cancelButton`}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStore(observer(OrganizationSettings));
