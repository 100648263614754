import { observer } from 'mobx-react-lite';

import { withStore } from '@/services/store';
import { CreateTaskPage } from '@/pages';

import styles from './DetailCreateTask.module.css';
import { Modal } from '@/components';

const DetailCreateTask = ({ open, onCreate, onClose }) => {
  return (
    <Modal
      containerClassName={styles.modalContainer}
      contentClassName={styles.modalContent}
      open={open}
      onClose={onClose}
    >
      <CreateTaskPage onCreate={async (ticketId) => onCreate(ticketId)} onClose={onClose} />
    </Modal>
  );
};

export default withStore(observer(DetailCreateTask));
