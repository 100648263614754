import { MenuItem as MuiMenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import cx from 'clsx';

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.neutrals.greyMed,
    opacity: 1,
    cursor: 'default'
  },
  tooltipTrigger: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}));

const MenuItem = ({ DisabledTooltipProps, disabled, ...MenuItemProps }) => {
  const styles = useStyles();

  const handleDisabledItemClick = (e) => {
    e.stopPropagation();
  };

  if (disabled) {
    return (
      <MuiMenuItem
        {...MenuItemProps}
        className={cx(styles.disabled, MenuItemProps.className)}
        tabIndex={-1}
        button={false}
      >
        <>
          {MenuItemProps.children}
          <Tooltip title='Disabled' placement='right' {...DisabledTooltipProps}>
            <div
              onClick={handleDisabledItemClick}
              className={styles.tooltipTrigger}
            />
          </Tooltip>
        </>
      </MuiMenuItem>
    );
  }

  return <MuiMenuItem {...MenuItemProps} />;
};

MenuItem.propTypes = {
  disabled: PropTypes.bool,
  DisabledTooltipProps: PropTypes.shape({})
};

MenuItem.defaultProps = {
  disabled: false,
  DisabledTooltipProps: {}
};

export default MenuItem;
