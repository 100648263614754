import { omit } from 'lodash';
import { makeObservable, action, observable, runInAction, computed } from 'mobx';
import moment from 'moment';

import { attachmentApi, createApi, ticketApi } from '@/api';
import {
  showSuccessNotification,
  showErrorNotification,
  formatUTCDateTime,
  formatLocalDateTime,
  isValidQuantity,
} from '@/utils';
import { PriorityOptions, NotificationText } from '@/types';
import { ATTACHMENTS_FILED, WORKFLOW_CONFIGS } from '@/services/constants';
import { setError } from '@/utils/errors';

export class VariationStore {
  title = '';
  description = '';
  priority = PriorityOptions[0].value;
  taskOwner = '';
  assignTo = '';
  estimatedTime = null;
  scheduleTime = null;
  bullets = [];
  asinQuantity = '';
  attachedFiles = [];

  constructor(mainStore) {
    makeObservable(this, {
      title: observable,
      setTitle: action,

      description: observable,
      setDescription: action,

      setIsLoading: action,

      priority: observable,
      setPriority: action,

      taskOwner: observable,
      setTaskOwner: action,

      assignTo: observable,
      setAssignTo: action,

      asinQuantity: observable,
      setASINQuantity: action,

      estimatedTime: observable,
      setEstimatedTime: action,

      scheduleTime: observable,
      setScheduleTime: action,

      bullets: observable,
      changeFile: action,
      deleteFile: action,

      attachedFiles: observable,
      setAttachedFiles: action,

      descriptionPlaceholder: computed,
      quantityLabel: computed,
      quantityPlaceholder: computed,
      titleLabel: computed,
      titlePlaceholder: computed,

      clearData: action,
    });

    this.mainStore = mainStore;
  }

  clearData = () => {
    this.title = '';
    this.description = '';
    this.priority = PriorityOptions[0].value;
    this.setASINQuantity(1);
    this.bullets = [];
    this.attachedFiles = [];
    this.scheduleTime = null;
  };

  setIsLoading(value) {
    this.mainStore.setIsLoading(value);
  }

  get descriptionPlaceholder() {
    return 'Add any additional information you would like to share with us.';
  }

  get quantityLabel() {
    return 'Quantity*';
  }

  get quantityPlaceholder() {
    return 'Input Quantity';
  }

  get titleLabel() {
    if (this.mainStore.isQuestion) return 'Question*';
    return 'Title*';
  }

  get titlePlaceholder() {
    if (this.mainStore.isQuestion) return 'Describe your question';
    return 'Title';
  }

  // NOTE: Bullet
  changeFile = (idx, file) => {
    this.bullets[idx].src = file;
  };

  setAttachedFiles(files) {
    this.attachedFiles = files;
  }

  deleteFile = (idx) => {
    this.bullets[idx].src = null;
  };

  setTitle = (value) => {
    this.title = value;
  };

  // NOTE: Description
  setDescription = (value) => {
    this.description = value;
  };

  setPriority = (value) => {
    this.priority = value;
  };

  setTaskOwner = (value) => {
    if (this.mainStore.ownerOptions.find((owner) => owner.value === value)) this.taskOwner = value;
    else this.taskOwner = this.mainStore.unassigned;
  };

  setAssignTo = (value) => {
    this.assignTo = value;
  };

  setASINQuantity = async (value) => {
    this.asinQuantity = value === '' ? '' : Number(value);
    const baseDays = this.mainStore.workflowInfo?.baseDays;
    if (isValidQuantity(value) && baseDays) {
      const estimatedDays = (1 + Math.log10(value)) * baseDays;
      const estimatedDate = moment().add(estimatedDays, 'd');
      this.setEstimatedTime(estimatedDate);
    }
  };

  setEstimatedTime = (value) => {
    this.estimatedTime = formatLocalDateTime(value);
  };

  setScheduleTime = (value) => {
    if (value < moment()) value = moment();
    this.scheduleTime = formatLocalDateTime(value);
  };

  // NOTE: Submit
  async submitTicket(workflow, workflowConfigData, templateFile) {
    let result = null;
    try {
      const configAttachments = WORKFLOW_CONFIGS[workflow]?.attachments;
      let config = workflowConfigData;
      this.setIsLoading(true);
      if (configAttachments?.length) {
        if (templateFile) {
          const attachedNameFields = await Promise.all(
            configAttachments.map(async (attachedNameField) => {
              const file = templateFile[attachedNameField];
              const filename = await attachmentApi.uploadAttachment(
                file,
                `${attachedNameField}_${file?.name}`,
              );
              return { [attachedNameField]: filename };
            }),
          );

          config = attachedNameFields.reduce(
            (acc, field) => ({ ...acc, ...field }),
            workflowConfigData,
          );
        } else {
          config = workflowConfigData;
        }
      }
      const payloadData = omit(
        {
          organizationId: this.mainStore.selectedAccountId,
          parentId: this.mainStore.ticketId,
          title: this.title,
          description: this.description,
          priority: Number(this.priority),
          quantity: Number(this.asinQuantity),
          ownedBy: Number(this.taskOwner),
          assignedTo: Number(this.assignTo),
          estimatedCompletion: formatUTCDateTime(this.estimatedTime ?? new Date()),
          workflow,
          config,
        },
        [!this.mainStore.ticketId && 'parentId'],
      );
      const ticketId = await createApi.createTicket(payloadData);
      if (this.attachedFiles.length > 0) {
        const formData = new FormData();
        formData.append('comment', `[TicketId=${ticketId}]`);
        this.attachedFiles.forEach((file) => {
          formData.append(ATTACHMENTS_FILED, file);
        });
        await ticketApi.addComment(ticketId, formData);
      }

      runInAction(() => {
        showSuccessNotification(NotificationText.createRequestSuccess);
        result = ticketId;
      });
    } catch (err) {
      setError(err, false, NotificationText.createdError);
      showErrorNotification(NotificationText.createdError);
      result = null;
    }
    this.setIsLoading(false);
    return result;
  }

  dispose() {
    this.mainStore.dispose();
  }
}
