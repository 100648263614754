import PropTypes from 'prop-types';
import cx from 'clsx';
import MuiSwitch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  switchRoot: {
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  switchLabelRoot: {
    marginLeft: '0px !important',
  },
  disabledColor: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  errorColor: {
    color: `${theme.palette.ui.error} !important`,
  },
  checkedColor: {
    color: `${theme.palette.primary.purple} !important`,
    '&+.MuiSwitch-track': {
      backgroundColor: `${theme.palette.primary.purple} !important`,
    },
  },
  trackColor: {
    backgroundColor: `${theme.palette.secondary.darkBlue} !important`,
  },
  label: {
    fontFamily: 'Open Sans !important',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
}));

const Switch = ({ disabled, error, checked, label, ...props }) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          {...props}
          id="MuiSwitch"
          classes={{
            root: cx(styles.switchRoot, {
              [styles.errorColor]: error && !disabled,
              [styles.disabledColor]: disabled,
            }),
            checked: styles.checkedColor,
            track: styles.trackColor,
          }}
          checked={checked}
          disabled={disabled}
        />
      }
      label={label}
      classes={{
        root: styles.switchLabelRoot,
        label: cx(styles.label, {
          [styles.disabledColor]: disabled,
          [styles.errorColor]: error,
        }),
      }}
    />
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.any,
};

Switch.defaultProps = {
  disabled: false,
  error: false,
  checked: false,
  label: null,
};

export default Switch;
