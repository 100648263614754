import { observer } from 'mobx-react-lite';
import AceEditor from 'react-ace';
import 'brace/mode/json'; // Import the JSON mode
import 'brace/theme/monokai'; // Import the Monokai theme
import cx from 'clsx';

import { withStore } from '@/services/store';
import { useCreateTaskStore } from '@/pages/create/stores/useCreateTaskStore';
import { Typography } from '@/components';

import styles from './DetailWorkflowContent.module.css';

const PAGE_NAME = 'DetailWorkflowContent';

const DetailWorkflowContent = ({ open, orgId, workflowType, content, onRefresh, onClose }) => {
  const createTaskStore = useCreateTaskStore();
  const { workflowStore } = createTaskStore;

  const handleClose = () => workflowStore.setIsEditing(false);

  const handleSave = async (config) => {
    await onRefresh({ config });
    handleClose();
  };

  return (
    <div
      className={styles.workflowConfigPopover}
      style={{ visibility: open ? 'visible' : 'hidden' }}
    >
      <div className={styles.workflowConfigBackDrop} onClick={onClose} />
      <div
        id={`${PAGE_NAME}_container`}
        className={cx(styles.workflowConfigContainer, { [styles.hidden]: !open })}
      >
        <div className={styles.taskContent}>
          <div className={styles.header}>
            <Typography variant="h5">Workflow Configuration</Typography>
          </div>
          {!workflowStore.isEditing && (
            <AceEditor
              mode="json"
              theme="monokai"
              readOnly
              name="json-editor"
              value={JSON.stringify(content, null, 2)}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={false}
              showGutter
              highlightActiveLine
              fontSize={14}
              height="calc(100% - 76px)"
              width="100%"
              style={{ borderRadius: '12px' }}
              enableBasicAutocompletion={false}
              enableLiveAutocompletion={false}
              enableSnippets={false}
              tabSize={2}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withStore(observer(DetailWorkflowContent));
