import PropTypes from 'prop-types';
import WorkflowColorLightIcon from '@/assets/mark//workflow-color-light.svg';
import WorkflowColorDarkIcon from '@/assets/mark//workflow-color-dark.svg';
import WorkflowBlackLightIcon from '@/assets/mark//workflow-black-light.svg';
import WorkflowBlackDarkIcon from '@/assets/mark//workflow-black-dark.svg';
import HelpdeskColorLightIcon from '@/assets/mark//helpdesk-color-light.svg';
import HelpdeskColorDarkIcon from '@/assets/mark//helpdesk-color-dark.svg';
import HelpdeskBlackLightIcon from '@/assets/mark//helpdesk-black-light.svg';
import HelpdeskBlackDarkIcon from '@/assets/mark//helpdesk-black-dark.svg';

const DEFAULT_WIDTH = 128;
const DEFAULT_HEIGHT = 128;

function MarkIcon({ type, iconType, isDarkTheme, width, height }) {
  let imgWidth = width;
  let imgHeight = height;
  let icon;

  if (type === 'workflow') {
    if (iconType === 'colorful' && !isDarkTheme) {
      icon = WorkflowColorLightIcon;
    } else if (iconType === 'colorful' && isDarkTheme) {
      icon = WorkflowColorDarkIcon;
    } else if (iconType === 'simple' && !isDarkTheme) {
      icon = WorkflowBlackLightIcon;
    } else if (iconType === 'simple' && isDarkTheme) {
      icon = WorkflowBlackDarkIcon;
    } else {
      icon = WorkflowColorLightIcon;
    }
  } else if (type === 'helpdesk') {
    if (iconType === 'colorful' && !isDarkTheme) {
      icon = HelpdeskColorLightIcon;
    } else if (iconType === 'colorful' && isDarkTheme) {
      icon = HelpdeskColorDarkIcon;
    } else if (iconType === 'simple' && !isDarkTheme) {
      icon = HelpdeskBlackLightIcon;
    } else if (iconType === 'simple' && isDarkTheme) {
      icon = HelpdeskBlackDarkIcon;
    } else {
      icon = HelpdeskColorLightIcon;
    }
  }
  if (!imgWidth) imgWidth = DEFAULT_WIDTH;
  if (!imgHeight) imgHeight = DEFAULT_HEIGHT;

  return <img src={icon} width={imgWidth} height={imgHeight} alt='mark' />;
}

MarkIcon.propTypes = {
  type: PropTypes.oneOf(['workflow', 'helpdesk']),
  width: PropTypes.number,
  height: PropTypes.number,
  iconType: PropTypes.oneOf(['simple', 'colorful']),
  isDarkTheme: PropTypes.bool
};

MarkIcon.defaultProps = {
  type: 'workflow',
  width: null,
  height: null,
  iconType: 'colorful',
  isDarkTheme: false
};

export default MarkIcon;
