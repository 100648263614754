import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import cx from 'clsx';

import { Spacing, SubmitEdit, Typography } from '@/components';
import HourglassIcon from '@/assets/icons/Hourglass.svg';
import { useDetailStore } from '../../../stores/useDetailStore';
import { InformationEditPanel } from '../sharedComponent/InformationEditPanel';
import { InformationPanel } from '../sharedComponent/InformationPanel';
import { CommentItem } from '../sharedComponent/CommentItem/CommentItem';
import { ObjectType } from '@/types';
import { useCreateTaskStore } from '@/pages/create/stores/useCreateTaskStore';
import { convertLocalDateTime } from '@/utils';

import styles from './CaseContent.module.css';
import { orderBy } from 'lodash';

const PAGE_NAME = 'CaseContent';

export const CaseContent = observer(({ ticketId }) => {
  const { detailStore, workListStore } = useDetailStore();
  const createTaskStore = useCreateTaskStore();
  const caseMessages = useMemo(() => {
    const region =
      (createTaskStore.organizationInfo?.profile?.logins ?? []).find(
        ({ platform }) => detailStore.workflowConfig?.PlatformId === platform,
      )?.configuration?.Marketplace || moment.tz.guess();
    const messages = (detailStore.caseMessages ?? []).map((message) => {
      return {
        ...message,
        lastUpdated: convertLocalDateTime(message.lastUpdated, region),
      };
    });
    return orderBy(messages, 'lastUpdated', 'desc');
  }, [createTaskStore.organizationInfo, detailStore.caseMessages, detailStore.workflowConfig]);
  const [data, setData] = useState({});

  const isValidContent = detailStore.title && detailStore.caseMessages.length > 0;

  useEffect(() => {
    setData({
      title: detailStore.title,
      priority: detailStore.priority,
      estimatedCompletion: detailStore.estimatedCompletion,
      remainingWork: detailStore.remainingWork,
      description: detailStore.description,
      attachments: detailStore.attachments,
    });
  }, [
    detailStore.attachments,
    detailStore.estimatedCompletion,
    detailStore.description,
    detailStore.priority,
    detailStore.remainingWork,
    detailStore.title,
  ]);
  const saveData = () => {
    workListStore.setIsEditing(false);
    detailStore.updateContent(data, true);
  };

  return (
    <>
      <div className={styles.caseContent} id={`${PAGE_NAME}_div`}>
        {workListStore.isEditing ? (
          <InformationEditPanel
            variant={ObjectType.task}
            data={data}
            cancelEditing={() => {
              setData(workListStore.originalTicket);
              workListStore.setIsEditing(false);
            }}
            updateData={(key, value) => setData((prev) => ({ ...prev, [key]: value }))}
            saveData={saveData}
          />
        ) : (
          <InformationPanel
            variant={ObjectType.task}
            data={data}
            configFiles={detailStore.workflowConfigFiles}
            addingFiles={detailStore.addingFiles}
            deletingFiles={detailStore.deletingFiles}
            onSetEditing={() => {
              workListStore.setOriginalTicket(data);
              workListStore.setIsEditing(true);
            }}
          />
        )}
        <Spacing space={40} />
        {isValidContent && (
          <div id={`${PAGE_NAME}_messagesContainer`} className={styles.messageContainer}>
            {caseMessages.map((message, idx) => (
              <CommentItem key={idx} comment={message} />
            ))}
          </div>
        )}
        {!isValidContent && !workListStore.isEditing && (
          <div
            id="CaseContent_importingContainer"
            className={cx(styles.caseContent, styles.importingContent)}
          >
            <div
              className={styles.waitingIcon}
              style={{ backgroundImage: `url(${HourglassIcon})` }}
            />
            <Typography variant="h5" className={styles.statusTitle}>
              We are importing the case, please check back later.
            </Typography>
          </div>
        )}
      </div>
      {false && isValidContent && (
        <div className={styles.actionButtonWrapper}>
          <SubmitEdit
            id={`${PAGE_NAME}_submitEdit`}
            title={detailStore.draftComment.title}
            attachments={detailStore.draftComment.attachments}
            onSubmit={(comment, addedFiles) =>
              detailStore.addComment(ObjectType.case, ticketId, comment, addedFiles)
            }
            placeholder="Add a Comment"
            isBorder
            required="one"
            assignTo={null}
            updateTitle={(title) => detailStore.updateDraftComment(title)}
            updateAttachments={(attachments) => detailStore.updateDraftComment(null, attachments)}
          >
            Send
          </SubmitEdit>
        </div>
      )}
    </>
  );
});
