import { DetailTaskType } from './detail';

export const NotificationText = {
  createRequestSuccess: 'Request successfully created.',
  createTaskSuccess: 'Task successfully created.',
  createCaseSuccess: 'Amazon Case successfully created.',
  createQuestionSuccess: 'Question successfully created.',
  createdError: 'Create Request failed',
  invalidFile: 'Invalid file!',
  cancelRequestError: 'Cancel Request failed.',
  applyPinError: 'Apply Pin failed',
  detailPageFetchError: 'Detail page fetch failed.',
  fetchQuestionError: 'Question fetch failed.',
  cancelQuestionError: 'Cancel Question failed.',
  updateQuestionError: 'Question update failed.',
  updateTaskItemError: 'Task log update failed.',
  updateStatusError: 'Status update failed.',
  updateAssignError: 'Assign update failed.',
  createCaseError: 'Create Case failed.',
  addCommentError: 'Add comment failed.',
  deleteCommentError: 'Delete comment failed.',
  updateCommentError: 'Update comment failed.',
  fetchCommentsError: 'Comments fetch failed',
  addAnswerError: 'Add answer failed.',
  deleteAnswerError: 'Delete answer failed',
  updateAnswerError: 'Update answer failed',
  createQuestionError: 'Create Question failed',
  deleteRequestError: 'Delete request failed',
  deleteTicketError: 'Delete task failed',
  updateProfileNameError: 'Update profile name failed',
  toggleEmailsNotification: 'Toggle Emails Notification failed',
  createUserError: 'Create user failed',
  updateUserError: 'Update user failed',
  removeUserError: 'Remove user failed',
  updateProfileLastLogError: 'Update profile last url failed',
  getWorkflowInfoError: 'Get workflow config failed',
  createTicketPermissionWarning: 'You have no permission to create a ticket.',
  editTicketPermissionWarning: 'You have no permission to edit a ticket.',
  notReachable: 'The server is not reachable.',
};

export const successNotifications = {
  [DetailTaskType.question]: NotificationText.createQuestionSuccess,
  [DetailTaskType.task]: NotificationText.createTaskSuccess,
  [DetailTaskType.case]: NotificationText.createCaseSuccess,
  [DetailTaskType.request]: NotificationText.createRequestSuccess,
};
