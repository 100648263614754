import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, Chip, SearchField, Typography } from '@/components';
import { AmazonStatus } from '@/types';
import { useSettingsStore } from '../../stores/useSettingsStore';
import { FilterPanel } from '../Filter/FilterPanel';
import styles from './Header.module.css';

const PAGE_NAME = 'MemberHeader';

export const Header = observer(({ onOpenInviteModal }) => {
  const { membersStore } = useSettingsStore();
  const [isFilterHidden, setIsFilterHidden] = useState(true);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    membersStore.setSearch(value);
  };

  return (
    <div className={styles.headerContainer}>
      <Typography variant="h5">Member Management</Typography>
      <Typography variant="subtitle2" style={{ marginTop: 8, opacity: '50%' }}>
        Manage and invite new members to your HelpDesk workspace.
      </Typography>
      <div className={styles.filtersRoot}>
        <div className={styles.selectorWrapper}>
          <div className={styles.buttonGroup}>
            <SearchField
              placeholder="Search by name or email"
              value={membersStore.search}
              style={{ width: 270 }}
              classes={{ root: styles.searchRoot }}
              onChange={handleChangeSearch}
              id={`${PAGE_NAME}_searchField`}
            />
            <div>
              <Button
                size="medium"
                variant="secondary"
                onClick={() => setIsFilterHidden((prev) => !prev)}
                id={`${PAGE_NAME}_toggleFilterButton`}
              >
                Filter
                {membersStore.allFilteredCount > 0 && (
                  <Chip
                    variant={AmazonStatus.closed}
                    label={membersStore.allFilteredCount}
                    className={styles.chip}
                  />
                )}
              </Button>
              {membersStore.allFilteredCount > 0 && (
                <Button
                  size="medium"
                  variant="tertiary"
                  onClick={() => membersStore.clearFilter()}
                  id={`${PAGE_NAME}_clearButton`}
                >
                  Clear
                </Button>
              )}
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              variant="gradient"
              size="medium"
              startIcon={<AddIcon />}
              onClick={onOpenInviteModal}
              id={`${PAGE_NAME}_inviteMemberButton`}
            >
              Invite To Helpdesk
            </Button>
          </div>
        </div>
        {!isFilterHidden && <FilterPanel />}
      </div>
    </div>
  );
});
