import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { organizationApi } from '@/api';
import { userStore } from '@/services/store/UserStore';
import { setError } from '@/utils/errors';
import { trim } from 'lodash';

export class AccountStore {
  organization = null;
  search = '';
  allAccounts = [];
  isLoading = true;
  selectedAccount = {};
  arrangedAccounts = null;

  constructor() {
    makeObservable(this, {
      organization: observable,
      fetchAccounts: action,

      allAccounts: observable,
      childAccounts: computed,
      filteredAccounts: computed,

      search: observable,
      setSearch: action,

      selectedAccount: observable,
      arrangedAccounts: observable,
      onSelectAccount: action,
      isParent: computed,
      hasChildren: computed,
      onToggleExpand: action,

      isLoading: observable,
      setIsLoading: action,
    });

    reaction(
      () => [userStore.organizationId, userStore.isRefresh],
      () => this.fetchAccounts(),
      { fireImmediately: true },
    );
  }

  get isParent() {
    return this.selectedAccount?.id === this.organization?.id;
  }

  get hasChildren() {
    return this.selectedAccount?.children?.length > 0;
  }

  getAccountFromId(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) return account;
      if (account.children.length > 0) {
        const result = this.getAccountFromId(account.children, selectedId);
        if (result) return result;
      }
    }
    return null;
  }

  getCurrentAccount() {
    return this.getAccountFromId(this.allAccounts, this.selectedAccount?.id);
  }

  setSearch(value) {
    this.search = trim(value);
  }

  toggleExpandMore(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) {
        accounts[i] = {
          ...accounts[i],
          isExpanded: account.isExpanded ? false : true,
        };
        return;
      }
      if (account.children.length > 0) {
        const result = this.toggleExpandMore(account.children, selectedId);
        if (result) return;
      }
    }
  }

  onToggleExpand(accountId) {
    this.toggleExpandMore(this.allAccounts, accountId);
  }

  // NOTE: Update Left Nav
  updateExpandedAccounts(accounts, paths) {
    if (paths) {
      for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];
        if (paths.includes(account.id)) {
          accounts[i] = { ...accounts[i], isExpanded: true };
        } else {
          accounts[i] = { ...accounts[i], isExpanded: false };
        }
        if (account.children.length > 0) {
          this.updateExpandedAccounts(account.children, paths);
        }
      }
    }
  }

  // NOTE: Select account
  onSelectAccount(accountId) {
    if (!this.allAccounts?.length) return;
    const selectedAccount = this.getAccountFromId(this.allAccounts, accountId);
    this.selectedAccount =
      selectedAccount ?? this.getAccountFromId(this.allAccounts, userStore.organizationId);
  }

  // NOTE: Filter accounts list
  get filteredAccounts() {
    return this.search
      ? this.arrangedAccounts.filter(({ name }) =>
          name?.toLowerCase()?.includes(this.search.toLowerCase()),
        )
      : this.childAccounts;
  }

  get childAccounts() {
    return this.allAccounts?.slice(1) ?? [];
  }

  // NOTE: Get Filter Options
  getSubAccounts(accounts) {
    return accounts.reduce((acc, account) => {
      let subAccounts = [];
      if (account.children.length > 0) {
        subAccounts = this.getSubAccounts(account.children);
      }
      return acc.concat([{ value: { id: account.id, name: account.name } }]).concat(subAccounts);
    }, []);
  }

  // NOTE: Fetch accounts list
  async fetchAccounts() {
    const organizationId = userStore.organizationId;
    if (!organizationId) return;
    this.setIsLoading(true);
    try {
      const {
        parent: organization,
        children: accounts,
        arrangedAccounts,
      } = await organizationApi.getOrganizationFullTree(organizationId);
      runInAction(() => {
        this.organization = { ...organization, children: accounts };
        this.allAccounts = [this.organization].concat(accounts);
        this.arrangedAccounts = arrangedAccounts;
        this.onSelectAccount(this.organization.id);
        userStore.setAppLoaded();
      });
    } catch (err) {
      setError(err, true);
    }
    this.setIsLoading(false);
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  dispose() {
    // TBD
  }
}

export const accountStore = new AccountStore();
