import { makeObservable, computed, action, observable, reaction, runInAction } from 'mobx';
import { orderBy } from 'lodash';
import { organizationApi } from '@/api';
import { rootStore, userStore, WorkflowStore } from '@/services/store';
import { BASE_ROUTES, ObjectTypes, UrlSearch } from '@/types';
import { VariationStore } from './variationStore';
import { makeWorkflowTree } from '@/utils/workflow';
import { mapToUserOption, sortMembers } from '@/utils';
import { setError } from '@/utils/errors';
import { getWorkflowConfigList } from '@/utils/workflow';

export const CaseType = {
  update: 'update',
  create: 'create',
};

export const ContentType = {
  manual: 'manual',
  auto: 'auto',
};

export class CreateTaskStore {
  selectedAccountId = null;

  workflowTreeList = [];
  selectedWorkflowType = null;
  assignOptions = [];
  isLoading = true;
  asin = '';
  ticketId = null;
  organizationInfo = null;
  detailTicketType = ObjectTypes.request;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action,

      selectedAccountId: observable,
      setSelectedAccountId: action,

      organizationInfo: observable,
      orgPlatformList: computed,

      workflowTreeList: observable,
      workflowConfigOptions: computed,
      selectedWorkflowType: observable,
      setSelectedWorkflowType: action,
      workflowInfo: computed,
      questionConfig: computed,
      hasWorkflowForm: computed,
      baseQuantity: computed,
      limitQuantity: computed,

      asin: observable,
      inputAsinCode: action,

      assignOptions: observable,
      ownerOptions: computed,
      unassigned: computed,

      ticketId: observable,
      setTicketId: action,

      detailTicketType: observable,
      setDetailTicketType: action,
    });

    this.routerStore = rootStore.routerStore;
    this.variationStore = new VariationStore(this);
    this.workflowStore = new WorkflowStore(this);

    reaction(
      () => this.selectedAccountId,
      () => this.fetchAssignees(this.selectedAccountId),
      { fireImmediately: true },
    );
  }

  get unassigned() {
    return this.assignOptions.find((user) => user.label === 'Unassigned')?.value;
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  get isDetailCreateFlyoutPage() {
    return this.routerStore.currentPage === BASE_ROUTES.detail;
  }

  setSelectedPage() {
    const params = this.routerStore.getSearchChunkParams();
    params[UrlSearch.workflowType] = this.selectedWorkflowType;
    this.routerStore.setSearchChunkParams(params);
  }

  // NOTE: Page
  gotoMain() {
    this.workflowStore.reset();
  }

  reset() {
    this.workflowStore.reset();
    this.variationStore.clearData();
  }

  // NOTE: ASIN
  inputAsinCode(value) {
    this.asin = value;
  }

  // NOTE: Card
  setSelectedContentType(value) {
    this.selectedMethodType = value;
  }

  // NOTE: Workflow Config Type
  get workflowConfigOptions() {
    const options = userStore.workflowConfigList
      .filter(
        ({ name, visible }) =>
          visible &&
          ((this.detailTicketType === ObjectTypes.question &&
            name.includes(ObjectTypes.question)) ||
            ((!this.isDetailCreateFlyoutPage || this.detailTicketType === ObjectTypes.request) &&
              !name.includes(ObjectTypes.question))),
      )
      .map(({ workflow, displayName }) => {
        return {
          value: workflow,
          label: displayName || workflow,
        };
      });
    return orderBy(options, 'label');
  }

  setSelectedWorkflowType(value) {
    this.selectedWorkflowType = value;
    this.reset();
  }

  get workflowInfo() {
    if (this.isDetailCreateFlyoutPage && this.detailTicketType === ObjectTypes.question)
      return this.questionConfig;
    return userStore.workflowConfigList.find(
      ({ workflow }) => workflow === this.selectedWorkflowType,
    );
  }

  get questionConfig() {
    return userStore.workflowConfigList.find(({ workflow }) =>
      workflow.includes(ObjectTypes.question),
    );
  }

  get hasWorkflowForm() {
    return this.workflowInfo?.hasWorkflowForm;
  }

  get limitQuantity() {
    return this.workflowInfo?.limitQuantity;
  }

  get baseQuantity() {
    return this.workflowInfo?.baseQuantity ?? 1;
  }

  get isQuestion() {
    return this.workflowInfo?.name?.includes(ObjectTypes.question);
  }

  get isWidePage() {
    return this.workflowInfo?.name?.includes('AuditCatalog');
  }

  setDetailTicketType(value) {
    this.detailTicketType = value;
    if (this.detailTicketType === ObjectTypes.question) {
      const questionConfig = userStore.workflowConfigList.find(
        ({ name, visible }) =>
          visible &&
          this.detailTicketType === ObjectTypes.question &&
          name.includes(ObjectTypes.question),
      );

      if (questionConfig) this.setSelectedWorkflowType(questionConfig.workflow);
    } else this.setSelectedWorkflowType(null);
  }

  setSelectedAccountId(accountId) {
    this.setSelectedWorkflowType(null);
    this.selectedAccountId = accountId;
  }

  get orgPlatformList() {
    if (!userStore.platformTypes?.length) return [];
    return (this.organizationInfo?.profile?.logins ?? []).map(
      ({ platform }) => userStore.platformTypes.find(({ id }) => id === platform)?.name ?? platform,
    );
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setTicketId(value) {
    this.ticketId = value;
  }

  toggleExpandMore(workflowList, selectedWorkflow) {
    for (let i = 0; i < workflowList.length; i++) {
      const workflow = workflowList[i];
      if (workflow.workflow === selectedWorkflow) {
        workflowList[i] = {
          ...workflowList[i],
          isExpanded: workflow.isExpanded ? false : true,
        };
        return;
      }
      if (workflow.children.length > 0) {
        const result = this.toggleExpandMore(workflow.children, selectedWorkflow);
        if (result) return;
      }
    }
  }

  onToggleExpandCategory(workflowName) {
    this.toggleExpandMore(this.workflowTreeList, workflowName);
  }

  collapseAllCategories(categories) {
    return categories.map((category) => {
      return {
        ...category,
        isExpanded: false,
        children: category.children.length > 0 ? this.collapseAllCategories(category.children) : [],
      };
    });
  }

  expandSelectedWorkflow(workflow) {
    const selectedWorkflow = this.getWorkflowFromName(this.workflowTreeList, workflow);
    if (!selectedWorkflow) return;
    this.workflowTreeList = this.collapseAllCategories(this.workflowTreeList);

    let categoryList = this.workflowTreeList;
    selectedWorkflow.path.forEach((categoryName) => {
      const index = categoryList.findIndex((item) => item.workflow === categoryName);
      if (index !== -1) {
        categoryList[index] = { ...categoryList[index], isExpanded: true };
        categoryList = categoryList[index].children;
      }
    });
  }

  getWorkflowFromName(workflowList, selectedWorkflow) {
    if (selectedWorkflow) {
      for (let i = 0; i < workflowList.length; i++) {
        const workflow = workflowList[i];
        if (workflow.workflow === selectedWorkflow) return workflow;
        if (workflow.children.length > 0) {
          const result = this.getWorkflowFromName(workflow.children, selectedWorkflow);
          if (result) return result;
        }
      }
    }
    return null;
  }

  async fetchWorkflowConfigList() {
    this.setIsLoading(true);
    try {
      const { workflowConfigList, workflowTreeList } = getWorkflowConfigList();
      runInAction(() => {
        userStore.workflowConfigList = workflowConfigList;
        this.workflowTreeList = workflowTreeList;
      });
    } catch (err) {
      setError(err);
      userStore.workflowConfigList = [];
    }
    this.setIsLoading(false);
  }

  async fetchOrganization() {
    if (!this.selectedAccountId) return;
    this.setIsLoading(true);
    try {
      const organizationInfo = await organizationApi.getOrganization(this.selectedAccountId);
      runInAction(() => {
        this.organizationInfo = organizationInfo;
      });
    } catch (err) {
      setError(err, false, 'Get organization failed');
    }
    this.setIsLoading(false);
  }

  async fetchOrgServices() {
    this.setIsLoading(true);
    try {
      const servicesList = await organizationApi.getOrganizationServices(this.selectedAccountId);
      if (!servicesList?.length) {
        this.workflowTreeList = makeWorkflowTree(userStore.workflowConfigList);
      } else {
        const contributorServicesList = servicesList
          .map((configId) => userStore.workflowConfigList.find(({ id }) => id === configId))
          .filter((config) => config);
        this.workflowTreeList = makeWorkflowTree(contributorServicesList, true);
      }
    } catch (err) {
      setError(err, false, 'Get organization failed');
    }
    this.setIsLoading(false);
  }

  async fetchAssignees(accountId) {
    if (!accountId) return;
    this.setIsLoading(true);
    try {
      const assigns = await organizationApi.getAssigners(accountId);
      runInAction(() => {
        this.assignOptions = sortMembers(assigns).map(mapToUserOption);
      });
    } catch (err) {
      setError(err);
    }
    this.setIsLoading(false);
  }

  dispose() {
    // TBD
  }
}
