import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Avatar } from '@/components';
import { AssignType } from '@/types';
import { getAvatarType } from '@/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '38px',
    marginLeft: '13px',
  },
  organization: {
    border: '1px solid rgba(211, 211, 211, 0.3) !important',
  },
  tooltip: {
    marginLeft: '20px !important',
  },
  selectable: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2) !important',
    },
  },
  selectedOrg: {
    background: `${theme.palette.secondary.lightBlue} !important`,
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
}));

const PAGE_NAME = 'SideIconItem';

const SideIconItem = ({ isSelected, name, onSelect }) => {
  const styles = useStyles();
  const variant = useMemo(() => getAvatarType(name) || AssignType.organization, [name]);

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.container}>
      <Avatar
        isSelected={isSelected}
        name={name}
        id={`${PAGE_NAME}_avatar`}
        variant={variant}
        tooltipText={name}
        tooltipPlacement="right"
        tooltipClassName={styles.tooltip}
        className={cx(styles.organization, {
          [styles.selectedOrg]: isSelected,
          [styles.selectable]: !isSelected && onSelect !== null,
        })}
        onClick={onSelect}
      />
    </div>
  );
};

SideIconItem.propTypes = {
  isSelected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};

SideIconItem.defaultProps = {
  isSelected: false,
  onSelect: null,
};

export default SideIconItem;
