import { last, trim } from 'lodash';
import { theme } from '@/config';
import { userStore } from '@/services/store/UserStore';
import { AmazonStatus, HelpDeskStatus } from '@/types';

export function getStateId(status) {
  return userStore.states.find((item) => item.value === status)?.id;
}

export function getStateFromId(stateId) {
  return userStore.states.find((item) => item.id === Number(stateId))?.value;
}

export function getStatus(statusTxt) {
  const status = statusTxt?.toLowerCase()?.trim();
  switch (status) {
    case 'cancelled':
    case 'canceled':
      return HelpDeskStatus.canceled;
    case 'to do':
      return HelpDeskStatus.todo;
    case 'not started':
      return HelpDeskStatus.notStarted;
    case 'open':
      return HelpDeskStatus.open;
    case 'failed':
      return HelpDeskStatus.failed;
    case 'closed':
      return HelpDeskStatus.closed;
    case 'doing':
      return HelpDeskStatus.doing;
    case 'resolved':
      return HelpDeskStatus.resolved;
    case 'blocked':
      return HelpDeskStatus.blocked;
    case 'in progress':
      return HelpDeskStatus.inProgress;
    case 'attention':
      return HelpDeskStatus.attention;
    case 'needs approval':
      return HelpDeskStatus.needs;
    case 're-opened':
      return AmazonStatus.reOpened;
    case 'pending':
      return AmazonStatus.pending;
    case 'answered':
      return AmazonStatus.answered;
    default:
      return AmazonStatus.unassigned;
  }
}

export function getExternalStatusLabel(status) {
  const statusText = status?.toLowerCase() || '';
  if (statusText.includes('answered')) return AmazonStatus.answered;
  if (statusText.includes('action needed')) return AmazonStatus.pending;
  return status || AmazonStatus.unassigned;
}

export function getStatusColor(status) {
  switch (status) {
    case HelpDeskStatus.notStarted:
      return '#ffce84';
    case HelpDeskStatus.closed:
      return theme.palette.secondary.darkBlue;
    case HelpDeskStatus.attention:
    case HelpDeskStatus.blocked:
      return theme.palette.secondary.salmon;
    case HelpDeskStatus.pending:
    case HelpDeskStatus.todo:
    case HelpDeskStatus.open:
    case HelpDeskStatus.doing:
    case HelpDeskStatus.needs:
      return '#ffce84';
    case HelpDeskStatus.failed:
      return theme.palette.ui.error;
    case HelpDeskStatus.answered:
    case HelpDeskStatus.resolved:
      return '#aae4ad';
    case HelpDeskStatus.canceled:
      return theme.palette.neutrals.lightGrey;
    default:
      return theme.palette.secondary.darkBlue;
  }
}

export function getAmazonCaseStatusColor(externalStatus) {
  const caseStr = externalStatus?.toLowerCase() || '';
  let backgroundColor;
  let color = theme.palette.secondary.darkBlue;
  if (caseStr.includes('need')) {
    backgroundColor = '#ffce84';
  } else if (caseStr.includes('pending')) {
    backgroundColor = '#ffce84';
  } else if (caseStr.includes('open')) {
    backgroundColor = '#ffce84';
  } else if (caseStr.includes('todo')) {
    backgroundColor = '#ffce84';
  } else if (caseStr.includes('answer')) {
    backgroundColor = '#aae4ad';
  } else if (caseStr.includes('resolved')) {
    backgroundColor = '#aae4ad';
  } else if (caseStr.includes('cancel')) {
    backgroundColor = theme.palette.neutrals.lightGrey;
    color = theme.palette.neutrals.grey;
  } else if (caseStr.includes('fail')) {
    backgroundColor = theme.palette.ui.error;
    color = theme.palette.neutrals.white;
  } else if (caseStr.includes('attention')) {
    backgroundColor = theme.palette.secondary.salmon;
  } else if (caseStr.includes('blocked')) {
    backgroundColor = theme.palette.secondary.salmon;
  } else {
    backgroundColor = '#ffce84';
  }

  return { backgroundColor, color };
}

export function getAmazonShortenStatus(statusTxt) {
  return trim(last(statusTxt?.split(':')));
}
