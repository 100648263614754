import { AssignType, Unassigned } from '@/types';
import { userStore } from '@/services/store/UserStore';
import { orderBy } from 'lodash';

export const AssignTypeOptions = [
  AssignType.lightBlue,
  AssignType.lightPurple,
  AssignType.darkBlue,
  AssignType.purple,
  AssignType.teal,
];

export const isCreatedByYou = (assignTo) => {
  return assignTo === userStore.currentUser.id;
};

export function getAssignFromId(userId, userOptions) {
  const matchedUser = userOptions?.find((member) => member.id === userId);
  if (matchedUser) {
    return {
      ...matchedUser,
      value: userId,
    };
  }
  return Unassigned;
}

export function getAssignFromName(name, assigns) {
  const matchedUser = assigns?.find((member) => member.name === name);
  if (matchedUser) {
    return {
      ...matchedUser,
      value: matchedUser.id,
    };
  }
  return Unassigned;
}

export function sortMembers(members) {
  let hasUnassigned = false;
  const filteredMembers = (members || []).filter((user) => {
    if (user.name?.startsWith(Unassigned.name)) hasUnassigned = true;
    else if (user.name) return true;
    return false;
  });
  const sortedMembers = orderBy(filteredMembers, [(user) => user.name.toLowerCase()], 'asc');
  const result = (sortedMembers || []).map((user, index) => {
    let type = AssignTypeOptions[index % AssignTypeOptions.length];
    const name = user.name?.toLowerCase();
    if (name.includes('amazon')) type = AssignType.amazon;
    if (name.includes('helpdesk') || name.includes('workflow')) type = AssignType.helpdesk;
    return {
      ...user,
      type,
    };
  });
  if (hasUnassigned) return result.concat(Unassigned);
  return result;
}

export const mapToUserOption = (user) => ({
  id: user.id,
  value: user.id,
  label: user.name,
  email: user.email,
  type: user.type,
});
