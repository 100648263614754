import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Typography, LoadingIndicator, TreeListDropdown } from '@/components';
import { UrlSearch, BASE_ROUTES, AnchorOrigin, ObjectTypes, NotificationText } from '@/types';
import { withStore, userStore } from '@/services/store';
import GeneralForm from './GeneralForm';
import { useMainStore } from '../main/stores/useMainStore';
import { useCreateTaskStore } from './stores/useCreateTaskStore';
import { useSettingsStore } from '../settings/stores/useSettingsStore';
import { showErrorNotification } from '@/utils';
import { SECOND } from '@/utils/constants';

import styles from './CreateTaskPage.module.css';

const PAGE_NAME = 'CreateTaskPage';

const CreateTaskPage = ({ onCreate, onClose }) => {
  const navigate = useNavigate();
  const createTaskStore = useCreateTaskStore();
  const { membersStore } = useSettingsStore();
  const { routerStore, accountStore } = useMainStore();
  const organizationId = Number(routerStore.getSearchParam(UrlSearch.organization));
  const workflowType = routerStore.getSearchParam(UrlSearch.workflowType);
  const ticketId = Number(routerStore.getSearchParam(UrlSearch.ticket));

  useEffect(() => {
    const currentPage = !ticketId ? BASE_ROUTES.create : BASE_ROUTES.detail;
    createTaskStore.fetchWorkflowConfigList();

    routerStore.setCurrentPage(currentPage);
  }, []);

  useEffect(() => {
    createTaskStore.setTicketId(ticketId);
  }, [ticketId]);

  useEffect(() => {
    membersStore.fetchAllAccounts(userStore.organizationId);
  }, [userStore.organizationId]);

  useEffect(() => {
    if (!createTaskStore.isDetailCreateFlyoutPage) {
      createTaskStore.fetchOrganization();
    }
  }, [createTaskStore.selectedAccountId]);

  useEffect(() => {
    if (
      userStore.currentUser.id &&
      userStore.isNonAdmin &&
      createTaskStore.selectedAccountId &&
      userStore.workflowConfigList?.length
    ) {
      createTaskStore.fetchOrgServices();
    }
  }, [
    userStore.currentUser.id,
    userStore.isNonAdmin,
    createTaskStore.selectedAccountId,
    userStore.workflowConfigList,
  ]);

  useEffect(() => {
    if (organizationId) createTaskStore.setSelectedAccountId(organizationId);
    if (workflowType) {
      if (workflowType === createTaskStore.questionConfig?.workflow)
        createTaskStore.setDetailTicketType(ObjectTypes.question);
      createTaskStore.setSelectedWorkflowType(workflowType);
    }
  }, [organizationId, userStore.workflowConfigList]);

  useEffect(() => {
    createTaskStore.reset(true);
  }, [organizationId, createTaskStore.selectedWorkflowType]);

  const handleGoBack = (refreshPage) => {
    createTaskStore.reset(refreshPage);
    if (
      createTaskStore.isDetailCreateFlyoutPage &&
      workflowType === createTaskStore.questionConfig.workflow
    ) {
      routerStore.removeSearchParams([UrlSearch.workflowType, UrlSearch.organization]);
      onClose();
      createTaskStore.setDetailTicketType(null);
    } else routerStore.removeSearchParam(UrlSearch.workflowType);
  };

  const handleCancel = () => {
    createTaskStore.reset();
    routerStore.removeSearchParams([UrlSearch.workflowType, UrlSearch.organization]);
    createTaskStore.setDetailTicketType(null);
    onClose();
  };

  const isNextButtonEnabled = useMemo(() => {
    if (createTaskStore.isLoading) return false;
    if (!createTaskStore.selectedAccountId) return false;
    return !!createTaskStore.selectedWorkflowType;
  }, [
    createTaskStore.isLoading,
    createTaskStore.selectedAccountId,
    createTaskStore.selectedWorkflowType,
  ]);

  const handleClose = (ticketId) => {
    if (createTaskStore.isDetailCreateFlyoutPage) onCreate(ticketId);
    else if (ticketId) {
      navigate(
        `${BASE_ROUTES.detail}?${UrlSearch.ticket}=${ticketId}${routerStore.getMainParams('&')}`,
      );
    }
  };

  const gotoNext = async () => {
    if (!userStore.currentUser.isContributor)
      showErrorNotification(NotificationText.createTicketPermissionWarning, {
        autoHideDuration: SECOND,
      });
    else createTaskStore.setSelectedPage();
  };

  if (createTaskStore.isLoading || accountStore.isLoading) {
    return (
      <div id={`${PAGE_NAME}_loading`} className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  if (workflowType) {
    return <GeneralForm goBack={handleGoBack} onClose={handleCancel} handleClose={handleClose} />;
  }

  return (
    <div id={`${PAGE_NAME}_information_container`} className={styles.contentContainer}>
      <Typography variant="h4">Create Service</Typography>

      {(!createTaskStore.isDetailCreateFlyoutPage ||
        createTaskStore.detailTicketType !== ObjectTypes.question) && (
        <div className={styles.accountList}>
          <TreeListDropdown
            label="Services"
            anchorPosition={AnchorOrigin.bottom}
            placeholder="Select Service"
            value={createTaskStore.selectedWorkflowType}
            error={!createTaskStore.selectedWorkflowType}
            width={530}
            onChange={(value) => createTaskStore.setSelectedWorkflowType(value)}
          />
        </div>
      )}

      <div id={`${PAGE_NAME}_buttonContainer`} className={styles.buttonGroup}>
        <Button
          size="large"
          variant="primary"
          tooltipText={
            !userStore.currentUser.isContributor
              ? NotificationText.createTicketPermissionWarning
              : ''
          }
          disabled={!isNextButtonEnabled}
          onClick={gotoNext}
          id={`${PAGE_NAME}_nextButton`}
        >
          Next
        </Button>
        <Button size="large" variant="tertiary" onClick={onClose} id={`${PAGE_NAME}_cancelButton`}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default withStore(observer(CreateTaskPage));
