import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemText, Popover } from '@mui/material';
import Typography from '../Typography';
import { useStyles } from './styles';
import Checkbox from '../Checkbox';

const PAGE_NAME = 'MultiStateCheckbox';

const MultiStateCheckbox = ({
  value: values,
  placeholder,
  options,
  onChange,
  defaultValue,
  ...rest
}) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const currentState = useMemo(() => {
    const currentValue = !values?.length ? defaultValue : values[0];
    return options.find((item) => item.value === currentValue);
  }, [values, defaultValue, options]);

  const handleOpen = (target) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => {
    onChange(value);
  };

  const handleDropDownKeyDownProcess = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      handleOpen(event.currentTarget);
    }
  };

  return (
    <>
      <div
        id={rest.id}
        tabIndex={0}
        role="button"
        onKeyDown={handleDropDownKeyDownProcess}
        className={cx(styles.container, {
          [styles.selected]: Boolean(anchorEl),
          [styles.selectedContainer]: values.length > 0,
        })}
        onClick={(event) => {
          handleOpen(event.currentTarget);
        }}
      >
        <Typography
          id={`${PAGE_NAME}_selectedValue`}
          variant="body1"
          style={{ whiteSpace: 'nowrap', minWidth: '158px' }}
        >
          {currentState.label}
        </Typography>
        <div className={cx(styles.moreIcon, { [styles.open]: Boolean(anchorEl) })}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        <div className={styles.popoverContent}>
          <Typography className={styles.title}>{placeholder}</Typography>
          <div className={styles.menuContainer}>
            <MenuItem
              key={currentState.value}
              value={currentState.value}
              classes={{ root: styles.menuItem }}
              id="MuiSelect_MenuItem"
              onClick={(e) => {
                const index = options.findIndex(({ value }) => value === currentState.value) || 0;
                handleChange([options[(index + 1) % options.length].value]);
              }}
            >
              <Checkbox
                checked={currentState.value === options[2].value}
                indeterminate={currentState.value === options[1].value}
                id={`${PAGE_NAME}_checkboxItem`}
                classes={{ formRoot: styles.menuItemCheckbox }}
              />
              <ListItemText>{currentState.label}</ListItemText>
            </MenuItem>
          </div>
        </div>
      </Popover>
    </>
  );
};

MultiStateCheckbox.propTypes = {
  value: PropTypes.arrayOf(),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  placeholderColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

MultiStateCheckbox.defaultProps = {
  value: [],
  placeholder: '',
  options: [],
  placeholderColor: '',
};

export default MultiStateCheckbox;
