import { Button, Icon, Modal, Typography } from '@/components';
import styles from './InviteSuccessModal.module.css';

const PAGE_NAME = 'InviteSuccessModal';

export const InviteSuccessModal = ({ invitedEmail, onClose }) => {
  return (
    <Modal
      open
      onClose={onClose}
      containerClassName={styles.modalContainer}
      contentClassName={styles.successContent}
      id={`${PAGE_NAME}_inviteSuccessModal`}
      titleClassName={styles.modalTitle}
      actions={
        <Button
          size="large"
          variant="primary"
          onClick={onClose}
          id={`${PAGE_NAME}_inviteSuccess_continueButton`}
        >
          Continue
        </Button>
      }
    >
      <Icon type="success" width={120} height={120} />
      <Typography variant="h5" style={{ marginTop: '16px' }}>
        Success
      </Typography>
      <Typography variant="subtitle3" className={styles.description}>
        {invitedEmail
          ? `An invite has been sent to ${invitedEmail}. Please make sure to check the spam folder.`
          : 'Updated Successfully'}
      </Typography>
    </Modal>
  );
};
