import { SvgIcon } from '@mui/material';

export const CopyOutlineIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <path
        d='M9.20002 18.5754C8.58336 18.5754 8.05002 18.3504 7.60002 17.9004C7.15002 17.4504 6.92502 16.9171 6.92502 16.3004V4.42539C6.92502 3.80872 7.15002 3.27539 7.60002 2.82539C8.05002 2.37539 8.58336 2.15039 9.20002 2.15039H18.075C18.6917 2.15039 19.225 2.37539 19.675 2.82539C20.125 3.27539 20.35 3.80872 20.35 4.42539V16.3004C20.35 16.9171 20.125 17.4504 19.675 17.9004C19.225 18.3504 18.6917 18.5754 18.075 18.5754H9.20002ZM9.20002 16.3004H18.075V4.42539H9.20002V16.3004ZM4.92502 22.8504C4.30836 22.8504 3.77502 22.6254 3.32502 22.1754C2.87502 21.7254 2.65002 21.1921 2.65002 20.5754V6.65039H4.92502V20.5754H15.85V22.8504H4.92502ZM9.20002 4.42539V16.3004V4.42539Z'
        fill='#081949'
      />
    </SvgIcon>
  );
};
