import { SvgIcon } from '@mui/material';

export const VisibilityIcon = (props) => {
  return (
    <SvgIcon width="24" height="17" viewBox="0 0 24 17" fill="none">
      <path
        d="M12.0032 12.784C13.292 12.784 14.3864 12.3341 15.2864 11.4344C16.1864 10.5347 16.6364 9.44214 16.6364 8.15679C16.6364 6.87146 16.1853 5.78 15.2831 4.8824C14.381 3.9848 13.2856 3.536 11.9968 3.536C10.708 3.536 9.61364 3.98587 8.71364 4.88561C7.81364 5.78533 7.36364 6.87786 7.36364 8.16321C7.36364 9.44854 7.81471 10.54 8.71686 11.4376C9.61898 12.3352 10.7144 12.784 12.0032 12.784ZM11.9936 11.2064C11.1433 11.2064 10.4227 10.9096 9.83182 10.316C9.24091 9.7224 8.94545 9.0016 8.94545 8.15361C8.94545 7.3056 9.24304 6.58693 9.83823 5.9976C10.4334 5.40827 11.1562 5.1136 12.0064 5.1136C12.8567 5.1136 13.5773 5.4104 14.1682 6.00399C14.7591 6.5976 15.0545 7.3184 15.0545 8.16639C15.0545 9.0144 14.757 9.73307 14.1618 10.3224C13.5666 10.9117 12.8438 11.2064 11.9936 11.2064ZM12 16.32C9.34545 16.32 6.94545 15.5675 4.8 14.0624C2.65455 12.5573 1.05455 10.5899 0 8.16C1.05455 5.73013 2.65455 3.76267 4.8 2.2576C6.94545 0.752533 9.34545 0 12 0C14.6545 0 17.0545 0.752533 19.2 2.2576C21.3455 3.76267 22.9455 5.73013 24 8.16C22.9455 10.5899 21.3455 12.5573 19.2 14.0624C17.0545 15.5675 14.6545 16.32 12 16.32ZM11.9954 14.688C14.1985 14.688 16.2227 14.0941 18.0682 12.9064C19.9136 11.7187 21.3182 10.1365 22.2818 8.16C21.3182 6.18347 19.9152 4.60133 18.0728 3.4136C16.2304 2.22587 14.2077 1.632 12.0046 1.632C9.80154 1.632 7.77727 2.22587 5.93182 3.4136C4.08636 4.60133 2.67273 6.18347 1.69091 8.16C2.67273 10.1365 4.08483 11.7187 5.92721 12.9064C7.76961 14.0941 9.79234 14.688 11.9954 14.688Z"
        fill="#242424"
      />
    </SvgIcon>
  );
};
