import { Fragment, useRef, useState } from 'react';
import { Popover } from '@mui/material';
import { Close } from '@mui/icons-material';
import cx from 'clsx';

import { Button, MultiLineInput, Tooltip, Typography } from '@/components';
import { userApi } from '@/api';
import { FeedbackType } from '@/types';
import { DissatisfiedIcon } from '@/assets/icons/DissatisfiedIcon';
import { SatisfiedIcon } from '@/assets/icons/SatisfiedIcon';
import HappyGif from '@/assets/gif/happy.gif';
import DisappointedGif from '@/assets/gif/disappointed.gif';

import styles from './FeedbackPopover.module.css';

const PAGE_NAME = 'FeedbackPopover';

export const FeedbackPopover = ({ ticketId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rate, setRate] = useState('');
  const [animation, setAnimation] = useState('');
  const feedbackInputRef = useRef(null);
  const handleClose = () => {
    setAnchorEl(null);
    setRate('');
  };
  const handleSendFeedback = () => {
    userApi.sendFeedback(ticketId, rate, feedbackInputRef.current.value);
    handleClose();
  };
  const handleClickFeedback = (event, rating) => {
    const target = event.currentTarget;
    setAnimation(rating);
    setTimeout(() => {
      setAnimation('');
      setAnchorEl(target);
    }, 1000);
    setRate(rating);
  };
  const feedbackIcons = [
    {
      type: FeedbackType.dissatisfied,
      icon: <DissatisfiedIcon />,
      tooltipTitle: 'Task Feedback: Dissatisfied',
      animation: <img src={DisappointedGif} alt="😞" width="24" height="24" />,
      style: { left: 0 },
    },
    {
      type: FeedbackType.satisfied,
      icon: <SatisfiedIcon />,
      tooltipTitle: 'Task Feedback: Satisfied',
      animation: <img src={HappyGif} alt="😀" width="24" height="24" />,
      style: { right: 0 },
    },
  ];

  return (
    <>
      <div className={styles.feedbackIconsContainer}>
        {feedbackIcons.map((icon) => (
          <Fragment key={icon.type}>
            {animation === icon.type && (
              <div style={{ position: 'absolute', ...icon.style }}>{icon.animation}</div>
            )}
            <Tooltip
              title={icon.tooltipTitle}
              className={styles.feedbackTooltip}
              arrow
              placement="top"
            >
              <div
                id={`feedback_${icon.type}`}
                className={cx(styles.feedbackIcon, {
                  [styles.selected]: anchorEl && rate === icon.type,
                })}
                onClick={(e) => handleClickFeedback(e, icon.type)}
              >
                {icon.icon}
              </div>
            </Tooltip>
          </Fragment>
        ))}
      </div>

      <Popover
        anchorEl={anchorEl}
        id={PAGE_NAME}
        open={Boolean(anchorEl) && !animation}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            marginTop: 10,
            padding: '16px',
            borderRadius: 16,
            width: '350px',
            boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <div id={`${PAGE_NAME}_Container`} className={styles.popoverContent}>
          <Typography variant="body1" style={{ color: 'black', width: 'calc(100% - 24px)' }}>
            Would you like to provide additional context?
          </Typography>
          <Tooltip title="Close" placement="bottom" arrow>
            <Close className={styles.closeButton} onClick={handleClose} />
          </Tooltip>
          <div className={styles.actionWrapper}>
            <MultiLineInput
              inputRef={feedbackInputRef}
              placeholder="Provide Feedback"
              isDarkTheme
              maxLength={500}
              id={`${PAGE_NAME}_feedback_content`}
              style={{ flex: 1 }}
            />
            <Button
              size="large"
              id={`${PAGE_NAME}_caseID_cancelButton`}
              onClick={handleSendFeedback}
            >
              Send
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
