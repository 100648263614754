import PropTypes from 'prop-types';
import cx from 'clsx';
import TableCell from '@mui/material/TableCell';
import { Avatar } from '@/components';
import { useStyles } from './sharedStyles';
import { AssignType } from '@/types';

export const AvatarCell = ({ isRequest, value, variant }) => {
  const styles = useStyles();

  return (
    <TableCell
      key="avatar"
      className={cx(styles.bodyCell, styles.assignCell)}
      id="AvatarCell"
    >
      <div style={{ marginLeft: isRequest ? 7 : 23 }}>
        {value ? (
          <Avatar name={value} width={30} variant={variant} tooltipText={value} />
        ) : (
          <Avatar width={30} tooltipText="Unassigned" variant={AssignType.unassigned} />
        )}
      </div>
    </TableCell>
  );
};

AvatarCell.propTypes = {
  value: PropTypes.any,
};

AvatarCell.defaultProps = {
  value: null,
};
