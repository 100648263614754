import { useMemo } from 'react';
import { MENU_LIST } from '../constants';
import SettingsMenuItem from './SettingsMenuItem';
import { useStyles } from './styles';

const PAGE_NAME = 'SettingsMenu';

const SettingsMenu = ({ selectedTabName, setSelectedTabName, isAdminUser }) => {
  const styles = useStyles();
  const menuList = useMemo(
    () => MENU_LIST.filter((menu) => !menu.isAdminPage || isAdminUser),
    [isAdminUser],
  );

  return (
    <div className={styles.sideContainer}>
      <div className={styles.sideContent}>
        <div id={`${PAGE_NAME}_organization_group`} className={styles.topWrapper}>
          <span id={`${PAGE_NAME}_organization_name`} className={styles.title}>
            Settings
          </span>
          <div className={styles.topDivider} />
        </div>
        <div id={`${PAGE_NAME}_group`} className={styles.body}>
          {menuList.map(({ label, value }) => (
            <SettingsMenuItem
              key={value}
              isSelected={selectedTabName === value}
              label={label}
              onSelect={(e) => {
                e.stopPropagation();
                setSelectedTabName(value);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
