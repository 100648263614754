import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal, TextField, OrgAccessDropdown, Checkbox } from '@/components';
import { isValidateEmail } from '@/utils';
import { AnchorOrigin } from '@/types';
import { DEFAULT_ACCOUNT_INFO } from '../../constants';

import styles from './NewMemberModal.module.css';

const PAGE_NAME = 'NewMemberModal';

export const NewMemberModal = observer(({ editingMember, defaultOrgId, saveUser, onClose }) => {
  const [required, setRequired] = useState(false);
  const [memberInfo, setMemberInfo] = useState({
    profile: {},
    ...(editingMember ?? DEFAULT_ACCOUNT_INFO),
    organizationId: defaultOrgId ?? editingMember.organizationId,
  });
  const {
    organizationId,
    first: firstName,
    last: lastName,
    email,
    isAdmin,
    isAssignable,
    isContributor,
  } = memberInfo;
  const isEditingModal = Boolean(editingMember);

  const handleChangeMemberInfo = (fieldValue) => {
    setMemberInfo((prev) => ({ ...prev, ...fieldValue }));
  };

  const handleClickInviteButton = () => {
    setRequired(true);
    if (firstName && lastName && email && organizationId && isValidateEmail(email)) {
      saveUser(memberInfo);
    }
  };

  return (
    <Modal
      open
      title={isEditingModal ? 'Update Member' : 'Invite New Member to HelpDesk'}
      id={`${PAGE_NAME}_inviteModal`}
      isCloseIconHidden
      containerClassName={styles.inviteModalContainer}
      contentClassName={styles.column}
      titleClassName={styles.modalTitle}
      onClose={onClose}
      actions={
        <>
          <Button
            size="large"
            onClick={handleClickInviteButton}
            id={`${PAGE_NAME}_inviteMemberButton`}
          >
            {isEditingModal ? 'Save' : 'Invite To Helpdesk'}
          </Button>
          <Button
            size="large"
            variant="tertiary"
            onClick={onClose}
            id={`${PAGE_NAME}_cancelInviteButton`}
          >
            Cancel
          </Button>
        </>
      }
    >
      <div className={styles.row}>
        <TextField
          key="First Name"
          placeholder="First Name"
          value={firstName}
          error={required && !firstName}
          className={styles.inputField}
          onChange={(e) => handleChangeMemberInfo({ first: e.target.value })}
          id={`${PAGE_NAME}_newMemeber_firstName`}
        />
        <TextField
          key="Last Name"
          placeholder="Last Name"
          value={lastName}
          error={required && !lastName}
          className={styles.inputField}
          onChange={(e) => handleChangeMemberInfo({ last: e.target.value })}
          id={`${PAGE_NAME}_newMemeber_lasstName`}
        />
      </div>
      <TextField
        key="Work Email"
        placeholder="Work Email"
        value={email}
        error={required && !isValidateEmail(email)}
        className={styles.inputField}
        onChange={(e) => handleChangeMemberInfo({ email: e.target.value })}
        id={`${PAGE_NAME}_newMemeber_workEmail`}
      />
      <OrgAccessDropdown
        value={organizationId}
        anchorPosition={AnchorOrigin.top}
        error={required && !organizationId}
        onChange={(value) => handleChangeMemberInfo({ organizationId: value })}
        style={{ width: '100%' }}
      />
      <div className={styles.row}>
        <Checkbox
          label="Is Assignable"
          checked={isAssignable}
          direction="row"
          onChange={(e) => handleChangeMemberInfo({ isAssignable: e.target.checked })}
        />
        <Checkbox
          label="Is Admin"
          checked={isAdmin}
          direction="row"
          onChange={(e) => handleChangeMemberInfo({ isAdmin: e.target.checked })}
        />
        <Checkbox
          label="Is Contributor"
          checked={isContributor}
          direction="row"
          onChange={(e) => handleChangeMemberInfo({ isContributor: e.target.checked })}
        />
      </div>
    </Modal>
  );
});
