import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  Typography,
  LoadingIndicator,
  TablePagination,
  Pagination,
  Chip,
  MoreButton,
  EmptyFilterView,
  Avatar,
} from '@/components';
import { useSettingsStore } from '../../stores/useSettingsStore';
import { MemberSearchKeys } from '@/types';

import styles from './MemberTable.module.css';

const PAGE_NAME = 'MemberTable';

const HEADER_COLUMNS = [
  {
    key: 'name',
    label: 'Name',
    align: 'left',
    sortKey: 'name',
    width: '34%',
  },
  {
    key: 'organization',
    label: 'Org. Access',
    align: 'left',
    sortKey: 'organization',
    width: '28%',
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
    sortKey: 'status',
    width: '30%',
  },
  {
    key: 'action',
    label: '',
    align: 'right',
    sortKey: null,
    width: '8%',
  },
];

export const MemberTable = observer(
  ({ setEditingMember, setRemovingMember, setReactivateMember }) => {
    const { membersStore } = useSettingsStore();

    return (
      <div className={styles.memberTable}>
        {membersStore.isLoading ? (
          <LoadingIndicator />
        ) : (
          <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
            <TableContainer className={styles.tableContainer}>
              <Table stickyHeader size="small">
                <TableHead id={`${PAGE_NAME}_table_head`}>
                  <TableRow>
                    {HEADER_COLUMNS.map(({ key, label, align, sortKey, width }, idx) => (
                      <TableCell
                        key={key}
                        align={align}
                        className={cx(styles.headCell, {
                          [styles.firstHeadCell]: idx === 0,
                          [styles.lastHeadCell]: idx === HEADER_COLUMNS.length - 1,
                        })}
                        style={{ width }}
                      >
                        {sortKey ? (
                          <TableSortLabel
                            active={membersStore.isActiveSort(sortKey)}
                            direction={membersStore.getSortDirection(sortKey)}
                            IconComponent={ArrowDropDownIcon}
                            classes={{ icon: styles.sortIcon }}
                            onClick={() => membersStore.toggleSort(sortKey)}
                          >
                            <Typography className={styles.headerLabel}>{label}</Typography>
                          </TableSortLabel>
                        ) : (
                          <Typography className={styles.headerLabel}>{label}</Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody id={`${PAGE_NAME}_table_body`}>
                  {membersStore.filteredCurrentPageMembers.map((member) => {
                    const {
                      id,
                      email,
                      organizationId,
                      name,
                      status,
                      isActive,
                      match,
                      matchedField,
                    } = member;
                    const organizationName = membersStore.getAccountFromId(
                      membersStore.allAccounts,
                      organizationId,
                    )?.name;
                    return (
                      <TableRow key={id} className={styles.row} id={`members_table_row`}>
                        <TableCell className={styles.bodyCell}>
                          <div className={styles.nameWrapper}>
                            <Avatar name={name} variant={member.type} width={30} />
                            <div className={styles.emailWrapper}>
                              <Typography
                                variant="subtitle2"
                                match={matchedField === MemberSearchKeys[0] && match}
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="body2"
                                match={matchedField === MemberSearchKeys[1] && match}
                                style={{ color: '#7B7B7B' }}
                              >
                                {email}
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={styles.bodyCell}>
                          <Typography variant="body2">{organizationName}</Typography>
                        </TableCell>

                        <TableCell className={cx(styles.bodyCell, styles.statusCell)}>
                          {status.map((label, index) => (
                            <Chip key={label} variant={label} />
                          ))}
                        </TableCell>
                        <TableCell className={styles.bodyCell} id={`members_table_moreButton`}>
                          {isActive ? (
                            <MoreButton
                              cancelText="Remove"
                              classes={{ menuItem: styles.menuItem }}
                              onUpdate={() => setEditingMember(member)}
                              onDelete={() => setRemovingMember(member)}
                            />
                          ) : (
                            <MoreButton
                              cancelText="Reactivate"
                              classes={{ menuItem: styles.menuItem }}
                              onUpdate={() => setEditingMember(member)}
                              onDelete={() => setReactivateMember(member)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {membersStore.allFilteredMembers.length === 0 &&
            (membersStore.search || membersStore.allFilteredCount > 0) ? (
              <EmptyFilterView
                onClick={() => {
                  membersStore.clearFilter();
                }}
                id="memberPage_noFilteredLog"
              />
            ) : (
              <div className={styles.pagination}>
                <TablePagination
                  label="View Per Page"
                  id={`${PAGE_NAME}_paginationDropdown`}
                  value={membersStore.pageRows}
                  options={[10, 20, 50]}
                  onChangeRowsPerPage={(v) => membersStore.setPageRows(v)}
                />
                <Pagination
                  count={membersStore.paginationCounts}
                  page={membersStore.currentPage}
                  onChange={(e, v) => membersStore.setPageNumber(v)}
                  id={`${PAGE_NAME}_pagination`}
                />
              </div>
            )}
          </Paper>
        )}
      </div>
    );
  },
);
