import { makeObservable } from 'mobx';
import { rootStore } from '@/services/store/RootStore';
import { MembersStore } from './MembersStore';
import { OrganizationStore } from './OrganizationStore';

export class SettingsStore {
  constructor() {
    makeObservable(this, {});

    this.membersStore = new MembersStore(rootStore.routerStore);
    this.organizationStore = new OrganizationStore();
    this.routerStore = rootStore.routerStore;
  }

  dispose() {
    this.membersStore.dispose();
  }
}
