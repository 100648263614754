import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { cloneDeep, isEqual, orderBy, trim } from 'lodash';
import { AssignType, ColumnType, RequestTypes } from '@/types';
import { userStore } from '@/services/store/UserStore';
import { FilterType } from '@/utils/constants';
import { filterApi } from '@/api';
import { setError } from '@/utils/errors';

export const InitFilter = {
  [FilterType.account]: {
    key: ColumnType.accountName,
    label: 'Account',
    values: [],
    selectedValues: [],
    hasSelectAllClear: true,
  },
  [FilterType.status]: {
    key: ColumnType.status,
    label: 'Status',
    values: [],
    selectedValues: [],
    hasSelectAllClear: true,
  },
  [FilterType.service]: {
    key: ColumnType.intentType,
    label: 'Service',
    values: [],
    selectedValues: [],
    hasSelectAllClear: true,
  },
  [FilterType.type]: {
    key: ColumnType.objectType,
    label: 'Root/Children',
    values: [
      { value: RequestTypes.all, label: 'All' },
      { value: RequestTypes.request, label: 'Root Requests' },
      { value: RequestTypes.child, label: 'Child Tasks' },
    ],
    selectedValues: [],
  },
  [FilterType.owner]: {
    key: ColumnType.ownedByName,
    label: 'Owner',
    values: [],
    selectedValues: [],
    hasSelectAllClear: true,
  },
  [FilterType.assign]: {
    key: ColumnType.assignedName,
    label: 'Assignee',
    values: [],
    selectedValues: [],
    hasSelectAllClear: true,
  },
  [FilterType.viewBy]: {
    key: ColumnType.requestedByDate,
    label: 'View by',
    values: [{ value: 'ecd', label: 'Estimated Completion Date' }],
    selectedValues: [],
  },
  [FilterType.createdAfter]: {
    key: ColumnType.createdAfter,
    label: 'Created After',
    values: [],
    selectedValues: [],
  },
};

export class FilterStore {
  filters = cloneDeep(InitFilter);
  search = '';
  filteredSearch = '';
  isFilterPanelOpen = false;
  isLoading = false;

  constructor(accountStore) {
    makeObservable(this, {
      setFilters: action,
      filters: observable,
      filteredResult: computed,
      allFilteredCount: computed,
      search: observable,
      setSearch: action,
      filteredSearch: observable,

      updateSelectedValues: action,
      clearFilter: action,

      isFilterPanelOpen: observable,
      onToggleFilterPanel: action,

      isEstimatedVisible: computed,

      myRequestsSelected: computed,
      myTasksSelected: computed,
      applyMyRequestFilter: action,
      applyMyTaskFilter: action,
      getDefaultStatusSelected: computed,
      applyDefaultStatusFilter: action,
      filterSelected: computed,

      filtersQuery: computed,

      fetchFilters: action,

      isLoading: observable,
      setIsLoading: action,
    });

    this.accountStore = accountStore;
  }

  get filtersQuery() {
    const query = Object.keys(this.filteredResult).reduce((res, filterKey) => {
      const value = this.filteredResult[filterKey]?.selectedValues;
      if (value.length === 0) return res;
      return { ...res, [filterKey]: value };
    }, {});
    return query;
  }

  getMyStatus() {
    return this.filters?.[FilterType.status]?.values
      .filter((item) => ['open', 'blocked', 'needs approval'].includes(item.label.toLowerCase()))
      .map((item) => item.value);
  }

  get getDefaultStatusSelected() {
    const defaultStatus = this.filters?.[FilterType.status]?.values
      .filter((item) =>
        ['open', 'blocked', 'resolved', 'needs approval'].includes(item.label.toLowerCase()),
      )
      .map((item) => item.value);
    return Object.keys(this.filters).every((key) => {
      if (key === FilterType.status) {
        return isEqual(this.filters[key].selectedValues, defaultStatus);
      } else if (key === FilterType.type) {
        return isEqual(
          this.filters[key].selectedValues,
          this.filters[FilterType.type].values
            .filter((item) => item.value === RequestTypes.request)
            .map((item) => item.value),
        );
      } else {
        return this.filters[key].selectedValues?.length === 0;
      }
    });
  }

  get filterSelected() {
    return this.isFilterPanelOpen || this.getDefaultStatusSelected;
  }

  applyDefaultStatusFilter() {
    if (
      Object.keys(this.filters).every((key) => {
        return this.filters[key].selectedValues?.length === 0;
      })
    ) {
      const defaultStatus = this.filters?.[FilterType.status]?.values
        .filter((item) =>
          ['open', 'blocked', 'resolved', 'needs approval'].includes(item.label.toLowerCase()),
        )
        .map((item) => item.value);
      this.updateSelectedValues(FilterType.status, defaultStatus);
      this.updateSelectedValues(
        FilterType.type,
        this.filters[FilterType.type].values
          .filter((item) => item.value === RequestTypes.request)
          .map((item) => item.value),
      );
    }
  }

  get myRequestsSelected() {
    return Object.keys(this.filters).every((key) => {
      if (key === FilterType.owner) {
        return isEqual(this.filters[key].selectedValues, [userStore.currentUser.id]);
      } else if (key === FilterType.status) {
        return isEqual(this.filters[key].selectedValues, this.getMyStatus());
      } else if (key === FilterType.type) {
        return isEqual(
          this.filters[key].selectedValues,
          this.filters[FilterType.type].values
            .filter((item) => item.value === RequestTypes.request)
            .map((item) => item.value),
        );
      } else {
        return this.filters[key].selectedValues.length === 0;
      }
    });
  }

  get myTasksSelected() {
    return Object.keys(this.filters).every((key) => {
      if (key === FilterType.assign) {
        return isEqual(this.filters[key].selectedValues, [userStore.currentUser.id]);
      } else if (key === FilterType.status) {
        return isEqual(this.filters[key].selectedValues, this.getMyStatus());
      } else if (key === FilterType.type) {
        return isEqual(
          this.filters[key].selectedValues,
          this.filters[FilterType.type].values
            .filter((item) => item.value === RequestTypes.child)
            .map((item) => item.value),
        );
      } else {
        return this.filters[key].selectedValues.length === 0;
      }
    });
  }

  applyMyRequestFilter() {
    if (!this.myRequestsSelected) {
      this.clearFilter();
      this.updateSelectedValues(FilterType.owner, [userStore.currentUser.id]);
      this.updateSelectedValues(FilterType.status, this.getMyStatus());
      this.updateSelectedValues(
        FilterType.type,
        this.filters[FilterType.type].values
          .filter((item) => item.value === RequestTypes.request)
          .map((item) => item.value),
      );
    }
  }

  applyMyTaskFilter() {
    if (!this.myTasksSelected) {
      this.clearFilter();
      this.updateSelectedValues(FilterType.assign, [userStore.currentUser.id]);
      this.updateSelectedValues(FilterType.status, this.getMyStatus());
      this.updateSelectedValues(
        FilterType.type,
        this.filters[FilterType.type].values
          .filter((item) => item.value === RequestTypes.child)
          .map((item) => item.value),
      );
    }
  }

  get isEstimatedVisible() {
    return this.filteredResult[FilterType.viewBy]?.selectedValues.length > 0;
  }

  getOwnerName(assignId) {
    const findItem = this.filteredResult[FilterType.assign].values.find(
      (item) => item.value === assignId,
    );
    return assignId ? findItem?.label ?? 'None' : AssignType.unassigned;
  }

  setSearch(value) {
    this.search = trim(value);
  }

  setFilters(filterSets) {
    this.filters[FilterType.account].values = filterSets.organizations;
    this.filters[FilterType.status].values = userStore.states?.map((status) => ({
      ...status,
      value: status.id,
    }));
    // Assign
    this.filters[FilterType.assign].values = filterSets.assigned.map(
      (user) => userStore.assignOptions.find((assign) => assign.value === user.value) || user,
    );
    // Owner
    this.filters[FilterType.owner].values = filterSets.assigned.map(
      (user) => userStore.ownerOptions.find((owner) => owner.value === user.value) || user,
    );
    // Service
    this.filters[FilterType.service].values = filterSets.services;
  }

  get filteredResult() {
    const filteredFilters = Object.keys(this.filters).reduce((result, key) => {
      if (key === FilterType.account) {
        if (!this.accountStore.hasChildren) return result;
        return {
          ...result,
          [key]: {
            ...this.filters[key],
            values: this.filters[key].values.filter(({ path }) =>
              path?.includes(this.accountStore.selectedAccount?.id),
            ),
          },
        };
      }
      return { ...result, [key]: this.filters[key] };
    }, {});

    const result = Object.keys(filteredFilters).reduce((result, filterKey) => {
      const values =
        filterKey === FilterType.assign ||
        filterKey === FilterType.owner ||
        filterKey === FilterType.service
          ? filteredFilters[filterKey].values
          : orderBy(filteredFilters[filterKey].values, (item) => item.label?.toLowerCase(), 'asc');

      return {
        ...result,
        [filterKey]: { ...filteredFilters[filterKey], values },
      };
    }, {});

    return result;
  }

  async clearFilter() {
    const newFilters = Object.keys(this.filters).reduce(
      (result, key) => ({
        ...result,
        [key]: { ...this.filters[key], selectedValues: [] },
      }),
      {},
    );

    this.filters = newFilters;
    this.applyDefaultStatusFilter();
    this.search = '';
  }

  updateAllSelectedValues(value) {
    this.filters[FilterType.account].selectedValues = value[FilterType.account];
    this.filters[FilterType.status].selectedValues = value[FilterType.status];
    this.filters[FilterType.service].selectedValues = value[FilterType.service];
    this.filters[FilterType.type].selectedValues = value[FilterType.type];
    this.filters[FilterType.owner].selectedValues = value[FilterType.owner];
    this.filters[FilterType.assign].selectedValues = value[FilterType.assign];
    this.filters[FilterType.viewBy].selectedValues = value[FilterType.viewBy];
  }

  updateSelectedValues(key, value) {
    this.filters[key].selectedValues = value;
  }

  getSelectedCount(type) {
    return this.filteredResult[type]?.selectedValues.length ?? 0;
  }

  get allFilteredCount() {
    const count = Object.keys(this.filteredResult).reduce(
      (acc, key) => acc + this.getSelectedCount(key),
      0,
    );
    return count;
  }

  onToggleFilterPanel() {
    this.isFilterPanelOpen = !this.isFilterPanelOpen;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  // NOTE: Fetch filters
  async fetchFilters(organizationId) {
    if (!organizationId) return;
    this.setIsLoading(true);
    try {
      const res = await filterApi.getFilters(organizationId);
      runInAction(() => {
        this.setFilters(res);
        if (!userStore.currentUser.filters?.former?.path) this.applyDefaultStatusFilter();
        this.updateAccountsFilter();
      });
    } catch (err) {
      setError(err, false, 'Get filters failed');
    }
    this.setIsLoading(false);
  }

  updateAccountsFilter() {
    this.filters[FilterType.account].values = this.filters[FilterType.account].values?.map(
      (account) => {
        return {
          ...account,
          path: this.accountStore.arrangedAccounts.find(({ id }) => id === account.value)?.path,
        };
      },
    );
  }

  dispose() {
    // TBD
  }
}
