import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { withStore, userStore } from '@/services/store';
import { LoadingIndicator } from '@/components';
import { useSettingsStore } from '../stores/useSettingsStore';
import { Header } from './Header';
import { MemberTable } from './MemberTable';
import { NewMemberModal } from './NewMemberModal';
import { InviteSuccessModal } from './InviteSuccessModal';
import { DeleteMemberModal } from './DeleteMemberModal';
import { ReactivateMemberModal } from './ReactivateMemberModal';
import { NotificationText } from '@/types';

import styles from './Members.module.css';

const PAGE_NAME = 'MemberPage';

const Members = () => {
  const { membersStore } = useSettingsStore();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [removingMember, setRemovingMember] = useState(null);
  const [invitedEmail, setInvitedEmail] = useState('');
  const [editingMember, setEditingMember] = useState(null);
  const [reactivateMember, setReactivateMember] = useState(null);
  const [reassignMemberId, setReassignMemberId] = useState(null);

  const reassignOptions = useMemo(() => {
    return userStore.assignOptions.filter((option) => option.value !== removingMember?.id);
  }, [removingMember]);

  const saveUser = async (user) => {
    const result = await membersStore.createSaveUser(
      user,
      editingMember ? NotificationText.updateUserError : NotificationText.createUserError,
    );
    if (result) {
      setInvitedEmail(editingMember ? '' : user.email);
      setEditingMember(null);
      setIsInviteModalOpen(false);
      setIsSuccessOpen(true);
    }
  };

  const removeUser = async () => {
    const result = await membersStore.removeUser(
      removingMember.id,
      removingMember.email,
      removingMember.helpItemTags.length,
      reassignMemberId,
    );
    if (result) setRemovingMember(null);
  };

  const reactivateUser = async () => {
    setReactivateMember(null);
  };

  // if (membersStore.isLoading) return <LoadingIndicator />;

  return (
    <div className={styles.membersContainer}>
      {!membersStore.isLoading && (
        <div id={`${PAGE_NAME}_settingsContainer`} className={styles.memberSettings}>
          <Header onOpenInviteModal={() => setIsInviteModalOpen(true)} />
          <MemberTable
            setEditingMember={setEditingMember}
            setRemovingMember={setRemovingMember}
            setReactivateMember={setReactivateMember}
          />
        </div>
      )}
      {membersStore.isLoading && <LoadingIndicator />}
      {isInviteModalOpen && (
        <NewMemberModal
          defaultOrgId={membersStore.organization.id}
          saveUser={saveUser}
          onClose={() => setIsInviteModalOpen(false)}
        />
      )}
      {editingMember && (
        <NewMemberModal
          defaultAccount={membersStore.organization.id}
          editingMember={editingMember}
          saveUser={saveUser}
          onClose={() => setEditingMember(null)}
        />
      )}
      {isSuccessOpen && (
        <InviteSuccessModal invitedEmail={invitedEmail} onClose={() => setIsSuccessOpen(false)} />
      )}
      {removingMember && (
        <DeleteMemberModal
          userOptions={reassignOptions}
          member={removingMember}
          value={reassignMemberId}
          taskCount={removingMember.helpItemTags.length}
          onReassignMembers={(value) => setReassignMemberId(value)}
          onDelete={removeUser}
          onClose={() => setRemovingMember(null)}
        />
      )}
      {reactivateMember && (
        <ReactivateMemberModal
          member={reactivateMember}
          onUpdate={reactivateUser}
          onClose={() => setReactivateMember(null)}
        />
      )}
    </div>
  );
};

export default withStore(observer(Members));
