import { SvgIcon } from '@mui/material';

export const DoneIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" style={{ paddingTop: 12, paddingLeft: 14 }}>
      <path
        d="M12.7825 0.686124C13.029 0.45143 13.3571 0.321681 13.6974 0.324256C14.0378 0.326832 14.3638 0.46153 14.6068 0.699928C14.8497 0.938325 14.9905 1.26178 14.9995 1.60203C15.0085 1.94228 14.885 2.27272 14.655 2.52362L7.67249 11.2561C7.55243 11.3854 7.40752 11.4892 7.24642 11.5613C7.08533 11.6333 6.91137 11.6721 6.73493 11.6754C6.5585 11.6786 6.38322 11.6463 6.21957 11.5803C6.05592 11.5143 5.90726 11.4159 5.78249 11.2911L1.15199 6.66062C1.02304 6.54046 0.919612 6.39556 0.847876 6.23457C0.77614 6.07357 0.737567 5.89977 0.734457 5.72354C0.731348 5.54731 0.763766 5.37226 0.829778 5.20883C0.89579 5.0454 0.994043 4.89694 1.11868 4.77231C1.24331 4.64767 1.39177 4.54942 1.5552 4.48341C1.71863 4.4174 1.89368 4.38498 2.06991 4.38809C2.24614 4.3912 2.41994 4.42977 2.58093 4.50151C2.74193 4.57324 2.88683 4.67667 3.00699 4.80562L6.67149 8.46837L12.7492 0.724624C12.7602 0.711151 12.7719 0.698297 12.7842 0.686124H12.7825Z"
        fill="#5D36EF"
      />
    </SvgIcon>
  );
};
