import { userStore } from '@/services/store';

export class APIError extends Error {
  constructor(status, message, url, payload, preview) {
    super(message); // Call the Error constructor
    this.status = status;
    this.name = this.constructor.name; // Set the error name to the class name
    this.url = url;
    this.payload = payload;
    this.preview = preview;
  }
}

export function setError(error, isReload, warningTitle = '') {
  if (userStore.apiCriticalIssue?.isCritical) return;

  if (error instanceof APIError) {
    userStore.setApiCriticalIssue({
      status: error.status,
      url: error.url,
      payload: error.payload,
      preview: error.preview,
      statusText: `${error.message}${warningTitle ? `\r\n${warningTitle}` : ''}`,
      isCritical: !warningTitle,
      isReload,
    });
  } else {
    userStore.setApiCriticalIssue({
      status: error.status,
      url: window.location.href,
      preview: error.stack,
      statusText: `${error.message}${warningTitle ? `\r\n${warningTitle}` : ''}`,
      isCritical: !warningTitle,
      isReload,
    });
  }
}
