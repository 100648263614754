import { SvgIcon } from '@mui/material';

export const WFLWhiteIcon = (props) => {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.24344 10.5307L2.24229 10.5319C1.72015 11.0579 1.72015 11.9108 2.24229 12.4369L5.99411 16.2168C6.51624 16.7429 7.3628 16.7429 7.88494 16.2168L7.88608 16.2157C8.40822 15.6896 8.40822 14.8367 7.88608 14.3107L4.13426 10.5307C3.61212 10.0047 2.76557 10.0047 2.24344 10.5307Z"
        fill="white"
      />
      <path
        d="M9.74411 10.5293L5.99229 14.3092C5.47015 14.8353 5.47015 15.6882 5.99229 16.2142L5.99344 16.2154C6.51557 16.7414 7.36213 16.7414 7.88426 16.2154L11.6361 12.4354C12.1582 11.9094 12.1582 11.0565 11.6361 10.5304L11.6349 10.5293C11.1128 10.0032 10.2662 10.0032 9.74411 10.5293Z"
        fill="white"
      />
      <path
        d="M9.7532 10.5229L9.75205 10.5241C9.22992 11.0501 9.22992 11.903 9.75206 12.4291L13.5119 16.2171C14.034 16.7432 14.8806 16.7432 15.4027 16.2171L15.4039 16.216C15.926 15.6899 15.926 14.837 15.4039 14.311L11.644 10.5229C11.1219 9.99686 10.2753 9.99685 9.7532 10.5229Z"
        fill="white"
      />
      <path
        d="M21.7415 7.92631L21.7403 7.92516C21.2182 7.39911 20.3716 7.39911 19.8495 7.92516L13.5116 14.3106C12.9894 14.8367 12.9894 15.6896 13.5116 16.2156L13.5127 16.2168C14.0349 16.7428 14.8814 16.7428 15.4035 16.2168L21.7415 9.83132C22.2636 9.30527 22.2636 8.45237 21.7415 7.92631Z"
        fill="white"
      />
    </SvgIcon>
  );
};
