const HideDownloadChromeBannerKey = 'hide_chrome_banner';
const RedirectURLAfterLogin = 'redirect_url_after_login';
const TOKEN_KEY = 'help-desk-token';

export const saveToken = (accessToken) => {
  localStorage.setItem(TOKEN_KEY, accessToken);
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token;
};

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const hideChromeBanner = () => {
  localStorage.setItem(HideDownloadChromeBannerKey, true);
};

export const isHideChromeBanner = () => {
  return localStorage.getItem(HideDownloadChromeBannerKey);
};

export const saveRedirectURLAfterLogin = (url) => {
  localStorage.setItem(RedirectURLAfterLogin, url);
};

export const getRedirectURLAfterLogin = () => {
  return localStorage.getItem(RedirectURLAfterLogin);
};

export const removeRedirectURLAfterLogin = () => {
  return localStorage.removeItem(RedirectURLAfterLogin);
};
