export const GTM_ID = 'GTM-WS6G4NG';

let dataLayer = (window.dataLayer = window.dataLayer || []);

export function gtmConfigure(userId, organizationId) {
  dataLayer.push({ user_id: userId });
  dataLayer.push({ org_id: organizationId });
}

export function gtmDomReady() {
  dataLayer.push({ event: 'domReady' });
}

export function gtmLoadPageView() {
  dataLayer.push({ event: 'pageview' });
  dataLayer.push({ event: 'myrequests' });
  dataLayer.push({ event: 'mytasks' });
}

export function addGtmEvent(eventName) {
  dataLayer.push({ event: eventName });
}
