import { observer } from 'mobx-react-lite';
import { useRef, useState, useEffect } from 'react';
import { get } from 'lodash';
import { MultiSelect, Checkbox, Typography } from '@/components';
import { useSettingsStore } from '../../stores/useSettingsStore';

import styles from './FilterPanel.module.css';

const SCROLL_STATE = {
  none: 0,
  left: 1,
  middle: 2,
  right: 3,
};

const PAGE_NAME = 'Members_FilterPanel';

export const FilterPanel = observer(({ anchorEl, onClose }) => {
  const { membersStore } = useSettingsStore();
  const [scrollState, setScrollState] = useState(SCROLL_STATE.none);
  const filterContainerRef = useRef(null);

  useEffect(() => {
    if (filterContainerRef.current?.scrollWidth > filterContainerRef.current?.clientWidth) {
      setScrollState(SCROLL_STATE.left);
    }
  }, [filterContainerRef.current?.scrollWidth]);

  const renderValue = (selected, placeholder) => {
    if (selected.length === 0 && placeholder) {
      return <Typography variant="body1">{placeholder}</Typography>;
    }
    if (selected.length > 1) {
      return `${selected[0]} +${selected.length - 1} more`;
    }
    return selected[0];
  };

  const handleScroll = (e) => {
    if (!e?.target) return;
    const scrollLeftValue = get(e, ['target', 'scrollLeft']);
    const clientWidthValue = get(e, ['target', 'clientWidth'], 0);
    const scrollWidthValue = get(e, ['target', 'scrollWidth'], 0);

    if (scrollLeftValue === 0) setScrollState(SCROLL_STATE.left);
    else if (scrollLeftValue > 0 && scrollLeftValue + clientWidthValue < scrollWidthValue)
      setScrollState(SCROLL_STATE.middle);
    else if (scrollLeftValue + clientWidthValue >= scrollWidthValue)
      setScrollState(SCROLL_STATE.right);
  };

  return (
    <div
      className={styles.filtersGroup}
      onScroll={handleScroll}
      ref={filterContainerRef}
      id={`${PAGE_NAME}_container`}
    >
      {(scrollState === SCROLL_STATE.middle || scrollState === SCROLL_STATE.right) && (
        <div id={`${PAGE_NAME}_leftShadow`} className={styles.leftShadow} />
      )}
      {Object.keys(membersStore.filteredResult).map((filterKey) => {
        const label = membersStore.filteredResult[filterKey].label;
        const value = membersStore.filteredResult[filterKey]?.selectedValues;
        let options;
        options = membersStore.filteredResult[filterKey].values.map(({ value }) => ({
          value,
          label: value,
        }));

        return (
          <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_filterDropdown`}>
            <MultiSelect
              value={value}
              key={filterKey}
              placeholder={label}
              options={options}
              style={{ width: '220px' }}
              id={`${PAGE_NAME}_paginationDropdown`}
              renderValue={renderValue}
              onChange={(value) => membersStore.updateFilterValues(filterKey, value)}
              MenuItemComponent={({ label, checked }) => {
                return <Checkbox label={label} checked={checked} id={`${PAGE_NAME}_checkbox`} />;
              }}
            />
          </div>
        );
      })}
      {(scrollState === SCROLL_STATE.middle || scrollState === SCROLL_STATE.left) && (
        <div id={`${PAGE_NAME}_leftShadow`} className={styles.rightShadow} />
      )}
    </div>
  );
});
