const notificationToasts = () => window[Symbol.for('notificationToasts')];

const notifyToConsole = (...args) => {
  // eslint-disable-next-line no-console
};

const getHandler =
  (handlerName) =>
  (...args) => {
    const handler = notificationToasts()?.[handlerName];
    return handler ? handler(...args) : notifyToConsole(...args);
  };

export const createNotification = getHandler('createNotification');
export const removeNotification = getHandler('removeNotification');
export const showSuccessNotification = getHandler('showSuccessNotification');
export const showErrorNotification = getHandler('showErrorNotification');
export const showGeneralNotification = getHandler('showGeneralNotification');
