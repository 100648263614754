import { SvgIcon } from '@mui/material';

export const CopyIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24'>
      <path
        d='M9.2 17.0689C8.58333 17.0689 8.05 16.8627 7.6 16.4502C7.15 16.0377 6.925 15.5488 6.925 14.9835V4.09811C6.925 3.53283 7.15 3.04395 7.6 2.63145C8.05 2.21895 8.58333 2.0127 9.2 2.0127H18.075C18.6917 2.0127 19.225 2.21895 19.675 2.63145C20.125 3.04395 20.35 3.53283 20.35 4.09811V14.9835C20.35 15.5488 20.125 16.0377 19.675 16.4502C19.225 16.8627 18.6917 17.0689 18.075 17.0689H9.2ZM4.925 20.9877C4.30833 20.9877 3.775 20.7814 3.325 20.3689C2.875 19.9564 2.65 19.4676 2.65 18.9023V6.1377H4.925V18.9023H15.85V20.9877H4.925Z'
        fill='#081949'
      />
    </SvgIcon>
  );
};
