import { rootStore } from '@/services/store/RootStore';
import { ObjectType, UrlSearch } from '@/types';
import { makeObservable, computed, action } from 'mobx';
import { DetailContentStore } from './DetailContentStore';
import { WorkListStore } from './WorkListStore';

export class DetailRootStore {
  constructor() {
    makeObservable(this, {
      isLoading: computed,
      workItemType: computed,
      startRefreshTask: action,
      stopRefreshTask: action,
      activeTicketId: computed,
    });

    this.routerStore = rootStore.routerStore;
    this.workListStore = new WorkListStore();
    this.detailStore = new DetailContentStore(this.workListStore);
  }

  get workItemType() {
    return this.workListStore.workActivityStore?.workItemType || ObjectType.none;
  }

  get activeTicketId() {
    return Number(this.routerStore.getSearchParam(UrlSearch.ticket));
  }

  fetchDetailPage() {
    if (this.activeTicketId) {
      this.workListStore.fetchRequestDetail(this.activeTicketId, false);
      this.detailStore.fetchDetailContent(this.activeTicketId, false);
    }
  }

  async startRefreshTask() {
    if (!this.refreshTaskIntervalId) {
      this.refreshTaskIntervalId = setInterval(() => this.fetchDetailPage(), 60000);
    }
  }

  stopRefreshTask() {
    if (this.refreshTaskIntervalId) {
      clearInterval(this.refreshTaskIntervalId);
      this.refreshTaskIntervalId = null;
    }
  }

  get isLoading() {
    return this.workListStore.isLoading;
  }

  dispose() {}
}
