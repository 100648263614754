import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { noop } from 'lodash';
import Button from '../Button';
import Typography from '../Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Box, AppBar, IconButton, Toolbar } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  container: {
    background: `${theme.palette.primary.darkPurple} !important`,
    boxShadow: 'inset 5px 5px 250px rgba(255, 255, 255, 0.05)',
  },
  descriptionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginLeft: '8px !important',
  },
  close: {},
}));
const PAGE_NAME = 'Banner';
function Banner({ description, linkedURL, okText, onClose }) {
  const classes = useStyles();

  return (
    <Box sx={{ justifyContent: 'center' }}>
      <AppBar position="static" className={classes.container}>
        <Toolbar>
          <div className={classes.descriptionContainer}>
            <Typography
              id={`${PAGE_NAME}_notification`}
              variant="subtitle1"
              style={{ color: '#FFFFFF' }}
            >
              {description}
            </Typography>
            <Button
              id={`${PAGE_NAME}_ok_button`}
              size="small"
              variant="secondary"
              href={linkedURL}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.button}
            >
              {okText}
            </Button>
          </div>
          <IconButton
            id={`${PAGE_NAME}_close_iconButton`}
            color="inherit"
            onClick={() => onClose(false)}
            className={classes.close}
            sx={{ justifyContent: 'right' }}
          >
            <CloseIcon style={{ color: '#FFFFFF' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

Banner.propTypes = {
  description: PropTypes.string.isRequired,
  linkedURL: PropTypes.string.isRequired,
  okText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Banner.defaultProps = {
  description:
    'HelpDesk is currently optimized for Chrome. Dowload Chrome for the best experience.',
  linkedURL: 'https://www.google.com/chrome/downloads/',
  okText: 'Download Chrome',
  onClose: noop,
};

export default Banner;
