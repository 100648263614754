import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { downloadFile } from '@/utils';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  attachedFiles: {
    padding: '4px 8px',
  },
  attachment: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  fileName: {
    fontWeight: 600,
    fontSize: '16px',
    color: theme.palette.primary.purple,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '240px',
  },
  deleteFileIcon: {
    fontWeight: 700,
    color: theme.palette.primary.purple,
  },
  deleteFile: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
    width: 65,
    height: 64,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '13px',
  },
  uploadingFile: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadingLabel: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.secondary.darkBlue,
    whiteSpace: 'nowrap',
  },
  hoveredFile: {
    position: 'absolute',
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    width: 64,
    height: 64,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadIcon: {
    color: '#fff',
  },
}));

const PAGE_NAME = 'AttachmentArea';
const MAX_WORDS = 25;

export function AttachmentArea({
  files,
  configFiles,
  addingFiles,
  deletingFiles,
  onDeleteFile,
  containerClassName,
}) {
  const styles = useStyles();
  const allFiles = (files || []).concat(configFiles || []);

  const firstWords = (name) => {
    if (name.length >= MAX_WORDS) return name.substring(0, MAX_WORDS / 2);
    return name;
  };
  const secondWords = (name) => {
    if (name.length >= MAX_WORDS) return `...${name.substring(name.length + 3 - MAX_WORDS / 2)}`;
    return null;
  };

  const handleDownloadFile = (file) => {
    if (!file.id) {
      window.open(file.preview, '_blank');
    } else {
      downloadFile(file);
    }
  };

  return (
    <div container className={cx(styles.attachedFiles, containerClassName)}>
      {allFiles.map((file) => (
        <div id={`${PAGE_NAME}_AttachedFiles_fileInfo`} className={styles.attachment}>
          {onDeleteFile && (
            <div
              className={styles.deleteFileIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteFile(file);
              }}
            >
              [-]
            </div>
          )}
          <span className={styles.fileName} onClick={() => handleDownloadFile(file)}>
            {file.name}
          </span>
          {deletingFiles?.find((item) => item.id === file.id) && (
            <div className={styles.deleteFile}>Deleting..</div>
          )}
        </div>
      ))}

      {addingFiles?.map((addingFile, index) => (
        <div id={`${PAGE_NAME}_addedFile`} className={styles.uploadingFile}>
          <LoadingIndicator width={60} />
          <span className={styles.uploadingLabel}>{firstWords(addingFile.name)}</span>
          {secondWords(addingFile.name) && (
            <span className={styles.uploadingLabel}>{secondWords(addingFile.name)}</span>
          )}
        </div>
      ))}
    </div>
  );
}

AttachmentArea.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  configFiles: PropTypes.arrayOf(PropTypes.shape({})),
  addingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  deletingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteFile: PropTypes.func,
};

AttachmentArea.defaultProps = {
  files: [],
  configFiles: [],
  addingFiles: [],
  deletingFiles: [],
  onDeleteFile: undefined,
};
