import { omit, orderBy } from 'lodash';
import { Config } from '@/config';
import { parseUser } from './parseUtils';
import * as fetchWithAuth from './fetchWithAuth';
import { DEFAULT_BRANDING_INFO } from '@/pages/settings/constants';
import { FetchOptions } from './constants';

export const getOrganizationUsers = async (organizationId) => {
  if (!organizationId) return [];

  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${organizationId}/users`)
    .then((resp) => resp.items.map(parseUser));
};

function parseOrganization(obj, parentPath = []) {
  const path = obj.path
    .split('/')
    .filter((id) => id)
    .map(Number)
    .filter((id) => !parentPath.includes(id));
  const branding = obj.profile?.branding;

  return {
    id: obj.id,
    name: obj.organization,
    parentPath,
    path,
    createdOn: obj.createdOn,
    isActive: obj.isActive,
    children: [],
    pathByName: obj.pathByName,
    profile: {
      ...obj.profile,
      services: obj.profile?.services ?? [],
      branding: {
        alias: branding?.alias ?? '',
        logoUrl: branding?.logoUrl || DEFAULT_BRANDING_INFO.logoUrl,
        mailFrom: branding?.eMail || DEFAULT_BRANDING_INFO.alias,
        helpUrl: branding?.supportUrl || DEFAULT_BRANDING_INFO.helpUrl,
      },
    },
  };
}

export const getOrganization = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${orgId}`)
    .then((resp) => omit(parseOrganization(resp), 'children'));
};

export const getOrganizationServices = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${orgId}/services`)
    .then((resp) => resp ?? []);
};

export const createOrganization = async (orgInfo) => {
  return fetchWithAuth.post(`${Config.NEW_API}/Organizations`, orgInfo).then((resp) => resp);
};

export const updateOrganization = async (orgId, orgInfo) => {
  return fetchWithAuth
    .put(`${Config.NEW_API}/Organizations/${orgId}`, orgInfo, FetchOptions.status)
    .then((resp) => resp);
};

const sortAccounts = (accounts) => {
  const sortedAccounts = orderBy(accounts, 'name', 'asc');
  return sortedAccounts.map((account) => ({
    ...account,
    children: account.children.length > 0 ? sortAccounts(account.children) : [],
  }));
};

const makeChildrenTree = (accounts) => {
  const result = { children: [] };
  const inactivatedIds = [];
  accounts.forEach((account) => {
    let currentAccount = result;
    account.path.every((orgId, index) => {
      const nextAccount = currentAccount.children.find((item) => item.id === orgId);
      if (!nextAccount?.id) {
        const newItem = accounts.find((item) => item.id === orgId);
        if (!newItem?.id) {
          inactivatedIds.push(...account.path.slice(index));
          return false;
        } else currentAccount.children.push(newItem);
      } else {
        currentAccount = nextAccount;
      }
      return true;
    });
  });
  const children = sortAccounts(result.children[0].children);
  return { children, inactivatedIds };
};

export const getOrganizationFullTree = async (orgId) => {
  return fetchWithAuth.get(`${Config.NEW_API}/organizations/${orgId}/tree`).then((resp) => {
    const path = resp.items[0]?.path
      .split('/')
      .filter((id) => id)
      .map(Number);
    const parentPath = path.filter((id) => id !== resp.items[0].id);
    const arrangedAccounts = resp.items.map((item) => parseOrganization(item, parentPath));
    const orgsTree = makeChildrenTree(arrangedAccounts);
    return {
      parent: parseOrganization(resp.items[0], parentPath),
      children: orgsTree.children,
      arrangedAccounts: arrangedAccounts.filter(({ id }) => !orgsTree.inactivatedIds.includes(id)),
    };
  });
};

export const getAssigners = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${orgId}/assignables`)
    .then((resp) => resp.items.map(parseUser));
};
