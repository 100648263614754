import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover, TextField } from '@mui/material';
import Typography from '../Typography';
import { useStyles } from './styles';
import Button from '../Button';

const PAGE_NAME = 'MultiSelect';

const MultiSelect = ({
  size,
  value: values,
  options,
  isDarkTheme,
  isSearchBar,
  hasSelectAllClear,
  placeholder,
  MenuProps,
  renderValue,
  MenuItemComponent,
  onChange,
  ...rest
}) => {
  const styles = useStyles();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const valueText = useMemo(() => {
    let firstLabel = options.find((item) => item.value === values[0])?.label ?? '';
    firstLabel = `${firstLabel.slice(0, 15)}${firstLabel.length > 15 ? '...' : ''}`;
    let valueLabel;
    if (values.length === 0 && placeholder) {
      valueLabel = placeholder;
    } else if (values.length > 1) {
      valueLabel = `${firstLabel} +${values.length - 1} more`;
    } else {
      valueLabel = firstLabel;
    }
    return valueLabel;
  }, [values, placeholder, options]);

  const filteredOptions = useMemo(() => {
    const result = [];
    options.forEach((option) => {
      if (search) {
        const value = option.label;
        const startIndex = value?.toLowerCase().indexOf(search.toLowerCase().trim());
        if (startIndex !== -1) {
          result.push({ ...option, match: [startIndex, startIndex + search.length] });
        }
      } else {
        result.push({ ...option, match: [0, 0] });
      }
    });
    return result;
  }, [options, search]);

  const selectedFilteredOptions = useMemo(
    () =>
      filteredOptions.reduce((acc, { value }) => {
        if (values.includes(value)) {
          acc.push(value);
        }
        return acc;
      }, []),
    [filteredOptions, values],
  );

  const handleOpen = (target) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearch('');
  };

  const handleChange = (value) => {
    onChange(value);
  };

  const handleDropDownKeyDownProcess = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      handleOpen(event.currentTarget);
    }
  };

  return (
    <>
      <div
        id={rest.id}
        tabIndex={0}
        role="button"
        onKeyDown={handleDropDownKeyDownProcess}
        className={cx(styles.container, {
          [styles.selected]: Boolean(anchorEl),
          [styles.selectedContainer]: values.length > 0,
        })}
        onClick={(event) => {
          handleOpen(event.currentTarget);
        }}
      >
        <Typography
          id={`${PAGE_NAME}_selectedValue`}
          variant="body1"
          style={{ whiteSpace: 'nowrap', minWidth: '158px' }}
        >
          {valueText}
        </Typography>
        <div className={cx(styles.moreIcon, { [styles.open]: Boolean(anchorEl) })}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
      >
        <div className={styles.popoverContent}>
          <Typography className={styles.title}>{placeholder}</Typography>
          {isSearchBar && (
            <TextField
              value={search}
              placeholder="Search"
              autoFocus
              classes={{ root: styles.searchRoot }}
              InputProps={{
                classes: {
                  root: styles.inputRoot,
                  input: styles.inputInput,
                  notchedOutline: styles.inputNotchedOutline,
                  focused: styles.inputFocused,
                },
              }}
              onChange={(e) => {
                e.stopPropagation();
                setSearch(e.target.value);
              }}
            />
          )}
          {hasSelectAllClear && filteredOptions.length > 0 && (
            <div className={styles.buttonContainer}>
              <Button
                id="btn-selectall-options"
                size="small"
                variant="tertiary"
                classes={{ root: styles.button }}
                onClick={() => handleChange(filteredOptions.map(({ value }) => value))}
              >
                Select All
              </Button>
              <Button
                id="btn-clear-options"
                size="small"
                variant="tertiary"
                disabled={!selectedFilteredOptions.length}
                classes={{ root: styles.button }}
                onClick={() =>
                  handleChange(values.filter((o) => !selectedFilteredOptions.includes(o)))
                }
              >
                Clear
              </Button>
            </div>
          )}
          <div className={styles.menuContainer}>
            {filteredOptions.map(({ value, label, match }) => (
              <MenuItem
                key={value}
                value={value}
                classes={{ root: styles.menuItem }}
                id="MuiSelect_MenuItem"
                onClick={(e) => {
                  const checked = values.includes(value);
                  handleChange(!checked ? values.concat(value) : values.filter((o) => o !== value));
                }}
              >
                <MenuItemComponent label={label} checked={values.includes(value)} match={match} />
              </MenuItem>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

MultiSelect.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.arrayOf(PropTypes.shape({})),
  isSearchBar: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  placeholderColor: PropTypes.string,
  hasSelectAllClear: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

MultiSelect.defaultProps = {
  size: 'small',
  value: [],
  isSearchBar: false,
  isDarkTheme: false,
  placeholder: '',
  options: [],
  placeholderColor: '',
  hasSelectAllClear: false,
};

export default MultiSelect;
