import PropTypes from 'prop-types';
import cx from 'clsx';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '../Tooltip';

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {
    height: '24px',
    color: `${theme.palette.secondary.darkBlue} !important`,
    padding: '0px !important',
    paddingRight: '8px !important',
  },
  checkboxLabelRoot: {
    marginLeft: '0px !important',
  },
  disabledColor: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  errorColor: {
    color: `${theme.palette.ui.error} !important`,
  },
  checkedColor: {
    color: `${theme.palette.primary.purple} !important`,
  },
  label: {
    fontFamily: 'Open Sans !important',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  tooltip: {
    maxWidth: '170px !important',
  },
  tooltipIcon: {
    paddingLeft: 8,
    fontSize: '13px !important',
  },
}));

const Checkbox = ({
  disabled,
  error,
  checked,
  label,
  tooltipText,
  indeterminate,
  classes,
  ...props
}) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          classes={{
            root: cx(
              styles.checkboxRoot,
              {
                [styles.checkedColor]: (checked || indeterminate) && !disabled && !error,
                [styles.errorColor]: error && !disabled,
                [styles.disabledColor]: disabled,
              },
              classes?.root,
            ),
          }}
          {...props}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
        />
      }
      label={
        <Tooltip placement="right" arrow title={tooltipText} className={styles.tooltip}>
          <div>
            {label}
            {tooltipText && <InfoIcon className={styles.tooltipIcon} />}
          </div>
        </Tooltip>
      }
      classes={{
        root: cx(styles.checkboxLabelRoot, classes?.formRoot),
        label: cx(styles.label, {
          [styles.disabledColor]: disabled,
          [styles.errorColor]: error,
        }),
      }}
    />
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.any,
  indeterminate: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  error: false,
  checked: false,
  label: null,
  indeterminate: false,
};

export default Checkbox;
