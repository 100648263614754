import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { withStore, userStore } from '@/services/store';
import { UrlSearch, BASE_ROUTES, ObjectType } from '@/types';
import { LoadingIndicator } from '@/components';
import { useMainStore } from '../main/stores/useMainStore';
import { useDetailStore } from './stores/useDetailStore';
import { useCreateTaskStore } from '../create/stores/useCreateTaskStore';
import SideMenu from '../SideMenu';
import { DetailList } from './components/DetailList';
import { DetailContent } from './components/DetailContent';
import DetailCreateTask from './components/DetailCreateTask';
import { CustomDragLayer } from './components/DetailList/CustomDragLayer';
import DetailWorkflowContent from './components/DetailContent/DetailWorkflowContent';
import { ticketApi } from '@/api';

import styles from './TaskDetailPage.module.css';

const TaskDetailPage = () => {
  const navigate = useNavigate();
  const { accountStore } = useMainStore();
  const detailRootStore = useDetailStore();
  const createTaskStore = useCreateTaskStore();
  const { routerStore, workListStore, detailStore } = detailRootStore;
  const [activeHelpItem, setActiveHelpItem] = useState(null);
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false);
  const [isConfigSettingsOpen, setIsConfigSettingsOpen] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(true);
  const requestLoaded = useRef(false);
  const prevRefreshState = useRef(false);
  const [scrollTicketId, setScrollTicketId] = useState(null);
  const isScrollTo = useRef(false);

  useEffect(() => {
    routerStore.setCurrentPage(BASE_ROUTES.detail);
    return () => {
      workListStore.setIsEditing(false);
    };
  }, []);
  useEffect(() => {
    const organizationId = routerStore.getSearchParam(UrlSearch.organization);
    createTaskStore.setSelectedAccountId(workListStore.organizationId);
    createTaskStore.fetchOrganization();
    if (organizationId) setIsCreateTaskOpen(true);
  }, [workListStore.organizationId]);

  useEffect(() => {
    if (detailRootStore.activeTicketId && requestLoaded.current && !isScrollTo.current) {
      isScrollTo.current = true;
      setScrollTicketId(detailRootStore.activeTicketId);
    }
  }, [detailRootStore.activeTicketId, requestLoaded.current]);

  useEffect(() => {
    detailStore.setAccounts(accountStore.arrangedAccounts);
  }, [accountStore.arrangedAccounts]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!requestLoaded.current || refreshToggle !== prevRefreshState.current) {
          await workListStore.fetchRequestDetail(detailRootStore.activeTicketId);
          await detailStore.fetchAssigns(workListStore.organizationId);
          requestLoaded.current = true;
        }
      } catch (err) {
        console.error(err);
        return;
      }

      const ticket = workListStore.ticketList?.find(
        (item) => item.ticketId === detailRootStore.activeTicketId,
      );
      if (ticket) {
        setActiveHelpItem({
          isRequest: ticket.objectType === ObjectType.request,
          id: ticket.ticketId,
          type: ticket.objectType,
          organizationId: ticket.organizationId,
        });
        if (detailRootStore.activeTicketId !== ticket.ticketId) {
          routerStore.setSearchParam(UrlSearch.ticket, ticket.ticketId);
        }
      }
      prevRefreshState.current = refreshToggle;
    };

    if (userStore.appLoaded && detailRootStore.activeTicketId) {
      getData();
    }
  }, [userStore.appLoaded, refreshToggle, detailRootStore.activeTicketId]);

  const onActiveTicket = useCallback(
    (ticket) => {
      if (detailRootStore.activeTicketId !== ticket.id) {
        routerStore.setSearchParam(UrlSearch.ticket, ticket.id);
        workListStore.setIsEditing(false);
      }
    },
    [detailRootStore.activeTicketId],
  );

  const onMoveTicket = useCallback((dragId, toParentId) => {
    workListStore.fetchMoveTicket(dragId, toParentId);
  }, []);

  const handleRefresh = () => {
    if (activeHelpItem.isRequest) {
      navigate(`${BASE_ROUTES.main}${routerStore.getMainParams('?')}`);
    } else {
      setRefreshToggle((prev) => !prev);
    }
  };

  return (
    <div id="TaskDetail_container" className={styles.taskDetail}>
      {<SideMenu isDetailPage organizationId={activeHelpItem?.organizationId} />}
      {!activeHelpItem || !workListStore.ticketList || workListStore.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className={styles.detailContent}>
            <DndProvider backend={HTML5Backend}>
              <DetailList
                data={workListStore.filteredTicketList}
                ticketId={activeHelpItem.id}
                scrollTicketId={scrollTicketId}
                isDragMode={workListStore.isEditing}
                onActiveTicket={onActiveTicket}
                onMoveTicket={onMoveTicket}
              />
              <CustomDragLayer dataList={workListStore.filteredTicketList} />
            </DndProvider>
            <DetailContent
              isRequest={activeHelpItem.isRequest}
              ticketId={activeHelpItem.id}
              openCreateTask={(questionWorkflow) => {
                setIsCreateTaskOpen(true);
                const params = routerStore.getSearchChunkParams();
                params[UrlSearch.organization] = workListStore.organizationId;
                if (questionWorkflow) params[UrlSearch.workflowType] = questionWorkflow;
                routerStore.setSearchChunkParams(params);
              }}
              setIsConfigSettingsOpen={setIsConfigSettingsOpen}
              onRefresh={handleRefresh}
            />
            <DetailCreateTask
              open={isCreateTaskOpen}
              onClose={() => setIsCreateTaskOpen(false)}
              onCreate={async (ticketId) => {
                if (ticketId) {
                  setIsCreateTaskOpen(false);
                  isScrollTo.current = false;
                  await workListStore.fetchRequestDetail(detailRootStore.activeTicketId, false);
                  createTaskStore.setDetailTicketType(null);
                  const params = { [UrlSearch.ticket]: ticketId };
                  routerStore.setSearchChunkParams(params);
                }
              }}
            />
            {detailStore.isValidWorkflow && (
              <DetailWorkflowContent
                open={isConfigSettingsOpen}
                orgId={workListStore.organizationId}
                workflowType={detailStore.serviceType}
                content={detailStore.workflowConfig}
                onRefresh={async (savingData) => {
                  await ticketApi.updateTicket(activeHelpItem.id, savingData);
                  await detailStore.fetchDetailContent(activeHelpItem.id, false);
                }}
                onClose={() => {
                  setIsConfigSettingsOpen(false);
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default withStore(observer(TaskDetailPage));
