import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { taskLogApi } from '@/api';
import { BASE_ROUTES, ColumnType, UrlSearch } from '@/types';
import { userStore } from '@/services/store/UserStore';
import { setError } from '@/utils/errors';
import { getCurrentLocalDateTime, getFiltersQuery } from '@/utils';

export class TaskLogStore {
  users = null;
  parsedLogs = [];
  pageCount = 1;
  isLoading = true;
  timeout = null;

  constructor(accountStore, tableStore, filterStore) {
    makeObservable(this, {
      users: observable,
      parsedLogs: observable,
      pageCount: observable,
      assignOptions: computed,

      isLoading: observable,
      setIsLoading: action,
    });

    this.accountStore = accountStore;
    this.tableStore = tableStore;
    this.filterStore = filterStore;

    this.disposeResetPagination = reaction(
      () => [this.accountStore.selectedAccount?.id],
      () => this.resetPagination(),
      { fireImmediately: true },
    );
  }

  get assignOptions() {
    return userStore.assignOptions;
  }

  // NOTE: Get assign info
  getPicture(userId) {
    return this.users.find((user) => user.id === userId)?.picture;
  }

  // NOTE: Reset pagination settings
  resetPagination() {
    this.tableStore.setPageNumber(1);
  }

  updateAdvancedLogs(logs) {
    this.parsedLogs = logs.map((log) => {
      const assignObject = userStore.getAssignFromName(log.assignedName);
      const owner = userStore.getOwnerFromName(log.ownedByName);
      const account = this.accountStore.getAccountFromId(
        this.accountStore.allAccounts,
        log.accountId,
      );
      const commenter = log.commentedByName ? userStore.getOwnerFromName(log.commentedByName) : {};

      return {
        ...log,
        [ColumnType.isPinned]: userStore.currentUser.pinnedTickets.includes(log.ticketId),
        [ColumnType.ownedByName]: owner.label,
        [ColumnType.ownerType]: owner.type,
        [ColumnType.assignedName]: assignObject.label,
        [ColumnType.assignType]: assignObject.type,
        [ColumnType.accountName]: account?.name,
        [ColumnType.commenterName]: commenter?.label,
        [ColumnType.commenterType]: commenter?.type,
      };
    });
  }

  // NOTE: Fetch Log list
  async fetchTaskLog(accountId, query, sort) {
    if (!accountId) return;
    this.setIsLoading(true);
    this.parsedLogs = [];
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const res = await taskLogApi.getTaskLogs(accountId, query, sort);

      runInAction(() => {
        this.pageCount = Math.ceil(res.pageCount / this.tableStore.rowsPerPage);
        if (this.tableStore.pageNumber > this.pageCount) {
          this.tableStore.setPageNumber(1);
        }
        this.filterStore.filteredSearch = this.filterStore.search;
        this.updateAdvancedLogs(res.logs);
      });
      urlParams.delete(UrlSearch.token);
      userStore.setUrlParams(`${BASE_ROUTES.main}?${urlParams}`);
    } catch (err) {
      setError(err, true);
    }
    this.setIsLoading(false);
  }

  async exportToCSV() {
    const sort = { key: this.tableStore.sortKey, dir: this.tableStore.sortDirection };
    const query = getFiltersQuery(
      this.tableStore.pageNumber,
      this.tableStore.rowsPerPage,
      this.filterStore.filteredResult,
      this.filterStore.search,
    );

    try {
      const downloadLink = await taskLogApi.downloadLogsCSV(
        this.accountStore.selectedAccount.id,
        query,
        sort,
      );
      if (downloadLink) {
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = `TicketLogs-${getCurrentLocalDateTime('YYYY-MM-DD-HH-mm')}.csv`;
        a.click();
      }
    } catch (err) {
      setError(err, false, 'Ticket logs CSV download failed');
    }
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
}
