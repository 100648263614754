import { attachmentApi } from '@/api';
import { setError } from './errors';

export const getFileType = (name = '') => {
  const imgExts = [
    '.tif',
    '.pjp',
    '.xbm',
    '.jxl',
    '.svgz',
    '.jpg',
    '.jpeg',
    '.ico',
    '.tiff',
    '.gif',
    '.svg',
    '.jfif',
    '.webp',
    '.png',
    '.bmp',
    '.pjpeg',
    '.avif',
  ];
  if (imgExts.some((ext) => name.includes(ext))) return 'image';
  if (name.includes('csv')) return 'csv';
  return 'unknown';
};

export const getFileSizeError = (files) => {
  const MAX_FILE_SIZE = 20 * 1024 * 1024;
  return (files || []).some((file) => file.size > MAX_FILE_SIZE);
};

export const downloadFile = async (file) => {
  if (!file.id) return;
  try {
    let responseURL;
    if (file.hasWorkflowContent) responseURL = await attachmentApi.downloadAttachment(file.name);
    else responseURL = await attachmentApi.getAttachment(file.id, file.name);
    var a = document.createElement('a');
    a.href = responseURL;
    a.download = file.name;
    a.click();
  } catch (err) {
    setError(err, false, 'File download failed');
  }
};
