import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';

import Toast from './components/Toast';

const Notifications = ({ notifications, removeNotification }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open
      ClickAwayListenerProps={{ onClickAway: (e) => e.stopPropagation() }}
    >
      <div>
        {notifications.map((toast) => (
          <Toast key={toast.id} onDismissClick={removeNotification} toast={toast} />
        ))}
      </div>
    </Snackbar>
  );
};

Notifications.propTypes = {
  removeNotification: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      alertLevel: PropTypes.oneOf(['info', 'critical']),
      autoHideDuration: PropTypes.number,
      content: PropTypes.node.isRequired,
      hideIcon: PropTypes.bool,
      icon: PropTypes.node,
      onClick: PropTypes.func,
      title: PropTypes.string,
      variant: PropTypes.oneOf(['success', 'error', 'general']),
    }),
  ).isRequired,
};

export default Notifications;
