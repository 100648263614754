import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  DateTimePicker,
  Dropdown,
  MultiLineInput,
  TextField,
  Typography,
  UploadAttachment,
} from '@/components';
import { formatLocalDateTime, getFileSizeError } from '@/utils';
import { ObjectType, PriorityOptions } from '@/types';

import styles from './InformationEditPanel.module.css';

const DEFAULT_WIDTH = 225;
const PAGE_NAME = 'InformationEditPanel';

export const InformationEditPanel = observer(
  ({ variant = ObjectType.request, data, cancelEditing, updateData, saveData }) => {
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);

    const handleSelectFile = (files) => {
      updateData('attachments', files);
    };

    const handleSaveClicked = async () => {
      setSaveButtonClicked(true);

      if (
        ((variant === ObjectType.question && data.title) ||
          (data.title &&
            (variant === ObjectType.task || data.remainingWork) &&
            data.estimatedCompletion)) &&
        !getFileSizeError(data.attachments)
      ) {
        setSaveButtonClicked(false);
        saveData();
      }
    };

    return (
      <div
        id={`${PAGE_NAME}_div`}
        className={styles.informationWrapper}
        style={{ marginTop: variant === ObjectType.question ? 0 : 20 }}
      >
        <div className={styles.titleWrapper}>
          <Typography id={`${PAGE_NAME}_title`} variant="h6">
            Edit information
          </Typography>
          <div id={`${PAGE_NAME}_buttonGroup_container`} className={styles.buttonGroup}>
            <Button
              id={`${PAGE_NAME}_save_button`}
              size="large"
              variant="primary"
              onClick={handleSaveClicked}
            >
              Save
            </Button>
            <Button
              id={`${PAGE_NAME}_cancel_button`}
              size="large"
              variant="tertiary"
              onClick={cancelEditing}
            >
              Cancel
            </Button>
          </div>
        </div>
        <MultiLineInput
          id={`${PAGE_NAME}_title_multiLineInput`}
          label="Title*"
          error={saveButtonClicked && !data.title}
          placeholder="Provide a title of the task"
          defaultValue={data.title}
          isHelper
          maxLength={160}
          onChange={(value) => updateData('title', value)}
        />
        {variant !== ObjectType.question && (
          <>
            <div className={styles.editingPriorityWrapper}>
              <Dropdown
                isSmallMargin
                label="Priority*"
                id={`${PAGE_NAME}_priority_dropdown`}
                placeholder="Select Priority"
                value={data.priority}
                options={PriorityOptions}
                style={{ width: DEFAULT_WIDTH, minWidth: DEFAULT_WIDTH }}
                onChange={(e) => updateData('priority', Number(e.target.value))}
              />
              <DateTimePicker
                isDate
                id={`${PAGE_NAME}_ECD_dateTimePicker`}
                isSmallWidth
                label={<span>Estimated Completion Date*</span>}
                helperText="Select Date"
                value={data.estimatedCompletion}
                error={saveButtonClicked && !data.estimatedCompletion}
                onChange={(value) =>
                  updateData('estimatedCompletion', formatLocalDateTime(value, 'MM/DD/YYYY'))
                }
                style={{ maxWidth: DEFAULT_WIDTH }}
              />
              {variant === ObjectType.request && (
                <TextField
                  type="number"
                  id={`${PAGE_NAME}_quantity_textfield`}
                  label="Quantity*"
                  placeholder="Input Quantity"
                  error={saveButtonClicked && !data.remainingWork}
                  value={data.remainingWork}
                  onChange={(e) => updateData('remainingWork', Number(e.target.value))}
                  style={{ width: DEFAULT_WIDTH }}
                />
              )}
            </div>
          </>
        )}
        <MultiLineInput
          id={`${PAGE_NAME}_description_multiLineInput`}
          label="Description"
          placeholder="Provide a description of the task"
          value={data.description}
          onChange={(value) => updateData('description', value)}
        />
        <div id={`${PAGE_NAME}_uploadAttachment_container`}>
          <Typography variant="subtitle2" className={styles.uploadTitle}>
            Upload an Attachment
          </Typography>
          <UploadAttachment
            contentClassName={styles.uploader}
            dropZoneClassName={styles.dropZone}
            files={data.attachments}
            error={getFileSizeError(data.attachments)}
            onUpdateFile={handleSelectFile}
          />
        </div>
      </div>
    );
  },
);
