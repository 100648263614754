import { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Button, Typography } from '@/components';
import { NotificationText, ObjectType, getPriorityLabel } from '@/types';
import { formatLocalDateTime, getHyperText, showErrorNotification } from '@/utils';
import { AttachmentArea } from '@/components/UploadAttachment';
import { userStore } from '@/services/store';
import { SECOND } from '@/utils/constants';

import styles from './InformationPanel.module.css';

const MAX_DESCRIPTION_LENGTH = 1300;
const PAGE_NAME = 'InformationPanel';

const ReadMore = ({ children }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const hyperText = useMemo(() => {
    const text = children || '';
    return getHyperText(isReadMore ? text.slice(0, MAX_DESCRIPTION_LENGTH) : text);
  }, [isReadMore, children]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Typography variant="body1" multiline>
        <div dangerouslySetInnerHTML={{ __html: hyperText }} />
      </Typography>
      {children?.length > MAX_DESCRIPTION_LENGTH && (
        <div
          id={`${PAGE_NAME}_description_readMoreButton`}
          className={styles.viewMoreWrapper}
          onClick={toggleReadMore}
        >
          <span className={styles.viewMoreLabel}>{isReadMore ? 'View more' : 'View less'}</span>
          <ExpandMoreIcon className={cx(styles.moreIcon, { [styles.lessIcon]: !isReadMore })} />
        </div>
      )}
    </>
  );
};

export const InformationPanel = observer(
  ({
    variant = ObjectType.request,
    data,
    configFiles,
    addingFiles,
    deletingFiles,
    onSetEditing,
  }) => {
    const handleEdit = (e) => {
      if (!userStore.currentUser.isContributor)
        showErrorNotification(NotificationText.editTicketPermissionWarning, {
          autoHideDuration: SECOND,
        });
      else onSetEditing(e);
    };

    return (
      <>
        <div
          id={`${PAGE_NAME}_div`}
          className={styles.headerWrapper}
          style={{ marginTop: variant === ObjectType.question ? 0 : 24 }}
        >
          {variant !== ObjectType.question ? (
            <div className={styles.priorityWrapper}>
              <div className={styles.priorityGroup}>
                <Typography className={styles.priorityLabel}>PRIORITY:</Typography>
                <Typography id={`${PAGE_NAME}_priority`} className={styles.priorityValue}>
                  {getPriorityLabel(data.priority)}
                </Typography>
              </div>
              <div className={styles.priorityGroup}>
                <Typography className={styles.priorityLabel}>ESTIMATED COMPLETION DATE:</Typography>
                <Typography id={`${PAGE_NAME}_dateTime`} className={styles.priorityValue}>
                  {formatLocalDateTime(data.estimatedCompletion, 'MM/DD/YYYY') ?? 'None'}
                </Typography>
              </div>
              {variant === ObjectType.request && (
                <div className={styles.priorityGroup}>
                  <Typography className={styles.priorityLabel}>QUANTITY:</Typography>
                  <Typography id={`${PAGE_NAME}_quantity`} className={styles.priorityValue}>
                    {data.remainingWork ?? 'None'}
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            <div
              className={styles.descriptionWrapper}
              style={{ marginTop: variant === ObjectType.question ? 0 : 20 }}
            >
              {data.description ? (
                <>
                  <Typography className={styles.priorityLabel}>DESCRIPTION:</Typography>
                  <ReadMore>{data.description}</ReadMore>
                </>
              ) : null}
            </div>
          )}
          <Button
            id={`${PAGE_NAME}_edit_button`}
            variant="secondary"
            onClick={handleEdit}
            tooltipText={
              !userStore.currentUser.isContributor
                ? NotificationText.editTicketPermissionWarning
                : ''
            }
          >
            Edit
          </Button>
        </div>

        {variant !== ObjectType.question && data.description && (
          <div className={styles.descriptionWrapper}>
            <Typography className={styles.priorityLabel}>DESCRIPTION:</Typography>
            <ReadMore>{data.description}</ReadMore>
          </div>
        )}

        {(data.attachments?.length > 0 || configFiles?.length > 0 || addingFiles?.length > 0) && (
          <div style={{ marginTop: variant === ObjectType.question ? 0 : 40 }}>
            <Typography variant="subtitle2">Attachment(s)</Typography>

            <AttachmentArea
              files={data.attachments}
              configFiles={configFiles}
              addingFiles={addingFiles}
              deletingFiles={deletingFiles}
            />
          </div>
        )}
      </>
    );
  },
);
