import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  navItemContainer: {
    color: theme.palette.secondary.darkBlue,
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 400,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 10px 10px 20px',
    borderRadius: '8px',
    width: 'calc(100% - 30px)',
    '&:hover': {
      background: theme.palette.neutrals.grayBlue,
    },
  },
  itemSelected: {
    background: theme.palette.options.hover,
    fontWeight: 700,
    '&:hover': {
      background: theme.palette.options.hover,
    },
  },
  label: {
    maxWidth: '10rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
  },
}));

const SettingsMenuItem = ({ isSelected, label, onSelect }) => {
  const styles = useStyles();

  return (
    <div
      className={cx(styles.navItemContainer, {
        [styles.itemSelected]: isSelected,
      })}
      onClick={onSelect}
      id="sideNavigation_SettingsMenuItem"
    >
      <span className={styles.label}>{label}</span>
    </div>
  );
};

SettingsMenuItem.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

SettingsMenuItem.defaultProps = {
  isSelected: false,
};

export default SettingsMenuItem;
