import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    padding: '5px 0px 10px 10px',
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '24px',
    marginBottom: '20px',
    gap: '24px',
    overflow: 'auto hidden',
  },
  title: {
    textTransform: 'capitalize !important',
  },
  tableBlock: {
    minHeight: 1,
    flex: 1,
  },
  tableContainer: {
    width: '100%',
    height: 'calc(100% - 16px)',
    overflow: 'auto ',
    position: 'relative',
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: '#B8B9BB !important',
      },
    },
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 100,
      marginTop: 52,
      marginBottom: 52,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      borderRadius: 100,
      background: 'transparent !important',
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
  },
  headCell: {
    height: '52px',
    padding: '0px 16px 0px 0px !important',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid rgba(211, 211, 211, 0.4) !important',
  },
  headCellPin: {
    minWidth: '56px',
    width: '56px',
    padding: '0px !important',
    borderTopLeftRadius: '10px',
  },
  headFirstCell: {
    borderRadius: '10px 0 0',
    paddingLeft: '24px !important',
  },
  headCellLast: {
    borderRadius: '0 10px 0 0',
    width: '1%',
    paddingRight: '24px !important',
  },
  sortIcon: {
    width: '24px',
    height: '24px',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '12px',
    paddingRight: '20px',
    paddingLeft: '20px'
  },
  versionLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '4px',
    marginRight: '24px',
    color: theme.palette.neutrals.greyMed,
    fontSize: '14px',
    fontWeight: 400,
  },
}));
