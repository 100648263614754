import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ObjectType } from '@/types';
import { Spacing, SubmitEdit } from '@/components';
import { useDetailStore } from '../../../stores/useDetailStore';
import { InformationPanel } from '../sharedComponent/InformationPanel';
import { InformationEditPanel } from '../sharedComponent/InformationEditPanel';
import { CommentContent } from '../sharedComponent/CommentContent';
import styles from './RequestContent.module.css';

const PAGE_NAME = 'RequestContent';

export const RequestContent = observer(({ ticketId }) => {
  const { detailStore, workListStore } = useDetailStore();
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      title: detailStore.title,
      priority: detailStore.priority,
      estimatedCompletion: detailStore.estimatedCompletion,
      remainingWork: detailStore.remainingWork,
      description: detailStore.description,
      attachments: detailStore.attachments,
    });
  }, [
    detailStore.attachments,
    detailStore.estimatedCompletion,
    detailStore.description,
    detailStore.priority,
    detailStore.remainingWork,
    detailStore.title,
  ]);

  const saveData = () => {
    workListStore.setIsEditing(false);
    detailStore.updateContent(data, true);
  };

  return (
    <>
      <div id={`${PAGE_NAME}_container`} className={styles.content}>
        {workListStore.isEditing ? (
          <InformationEditPanel
            variant={ObjectType.request}
            data={data}
            cancelEditing={() => {
              setData(workListStore.originalTicket);
              workListStore.setIsEditing(false);
            }}
            updateData={(key, value) => setData((prev) => ({ ...prev, [key]: value }))}
            saveData={saveData}
          />
        ) : (
          <InformationPanel
            variant={ObjectType.request}
            data={data}
            configFiles={detailStore.workflowConfigFiles}
            addingFiles={detailStore.addingFiles}
            deletingFiles={detailStore.deletingFiles}
            onSetEditing={() => {
              workListStore.setOriginalTicket(data);
              workListStore.setIsEditing(true);
            }}
          />
        )}
        <Spacing space={40} />
        <CommentContent
          comments={detailStore.discussions}
          updateComment={(id, title, originalFiles, addingFiles, removingFiles) => {
            detailStore.updateComment(true, id, title, addingFiles, removingFiles);
          }}
          deleteComment={(id, files) => {
            detailStore.deleteComment(id, files, true);
          }}
        />
      </div>

      <div className={styles.actionButtonWrapper}>
        <SubmitEdit
          id={`${PAGE_NAME}_submitEdit`}
          title={detailStore.draftComment.title}
          attachments={detailStore.draftComment.attachments}
          onSubmit={(comment, addedFiles) =>
            detailStore.addComment(ObjectType.request, ticketId, comment, addedFiles)
          }
          placeholder="Add a Comment"
          isBorder
          required="one"
          assignTo={null}
          updateTitle={(title) => detailStore.updateDraftComment(title)}
          updateAttachments={(attachments) => detailStore.updateDraftComment(null, attachments)}
        >
          Send
        </SubmitEdit>
      </div>
    </>
  );
});
