import { SvgIcon } from '@mui/material';

export const WorkflowIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <rect width="36" height="36" rx="18" fill="#081949" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 24.682C8 23.9289 8.67825 23.3184 9.51491 23.3184H26.4851C27.3218 23.3184 28 23.9289 28 24.682C28 25.4351 27.3218 26.0456 26.4851 26.0456H9.51491C8.67825 26.0456 8 25.4351 8 24.682Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 13.4449C16.1552 13.4449 14.3835 14.1406 13.0753 15.3826C11.7667 16.625 11.0294 18.3124 11.0294 20.0743C11.0294 20.8622 10.3512 21.5009 9.51468 21.5009C8.67814 21.5009 8 20.8622 8 20.0743C8 17.5631 9.05064 15.1524 10.9246 13.3732C12.7991 11.5936 15.3439 10.5918 18 10.5918C20.6561 10.5918 23.2009 11.5936 25.0753 13.3732C26.9493 15.1524 28 17.5631 28 20.0743C28 20.8622 27.3219 21.5009 26.4853 21.5009C25.6488 21.5009 24.9706 20.8622 24.9706 20.0743C24.9706 18.3124 24.2333 16.625 22.9247 15.3826C21.6165 14.1406 19.8448 13.4449 18 13.4449Z"
        fill="#2FD6EE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4541 8.31871C13.4541 7.5656 14.1047 6.95508 14.9074 6.95508H21.0918C21.8944 6.95508 22.545 7.5656 22.545 8.31871C22.545 9.07183 21.8944 9.68235 21.0918 9.68235H14.9074C14.1047 9.68235 13.4541 9.07183 13.4541 8.31871Z"
        fill="#FF917B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0001 6.95508C19.0042 6.95508 19.8182 7.5918 19.8182 8.37724V11.8966C19.8182 12.682 19.0042 13.3187 18.0001 13.3187C16.9959 13.3187 16.1819 12.682 16.1819 11.8966V8.37724C16.1819 7.5918 16.9959 6.95508 18.0001 6.95508Z"
        fill="#FF917B"
      />
    </SvgIcon>
  );
};
