import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { Button, Typography } from '@/components';
import styles from './EmptyFilterView.module.css';

const PAGE_NAME = 'EmptyFilterView';

const EmptyFilterView = ({ noFilterResult, onClick }) => {
  return (
    <div id={`${PAGE_NAME}_container`} className={styles.noMatchingWrapper}>
      <SearchIcon size="large" className={styles.noMatchingIcon} />
      <Typography variant="h6">{`${
        noFilterResult ? 'No Matching Criteria' : 'No Matching Search Results'
      }`}</Typography>
      <Typography variant="body1" className={styles.noMatchingLabel}>
        {`${
          noFilterResult
            ? 'There are no results that match the filters that have been applied.\n Please try another filter combination.'
            : 'There are no search results that match that text.\n Please try searching another term.'
        }`}
      </Typography>
      <Button size="large" id={`${PAGE_NAME}_clearAllFilterButton`} onClick={onClick}>
        {noFilterResult ? 'Clear All Filters' : 'Clear Search Bar'}
      </Button>
    </div>
  );
};
EmptyFilterView.propTypes = {
  noFilterResult: PropTypes.bool,
};

EmptyFilterView.defaultProps = {
  noFilterResult: true,
};
export default EmptyFilterView;
