import { AccessTimeFilled, Cancel, CheckCircle, Error, NotInterested } from '@mui/icons-material';
import { RunningIcon } from '@/assets/icons';
import { theme } from '@/config';

export const AmazonStatus = {
  attention: 'Needs Your Attention',
  reOpened: 'Re-Opened',
  pending: 'Pending Amazon Action',
  unassigned: 'Unknown',
  answered: 'Answered',
  closed: 'Closed',
};

export const HelpDeskStatus = {
  notStarted: 'Not Started',
  open: 'Open',
  inProgress: 'In Progress',
  attention: 'Needs Attention',
  blocked: 'Blocked',
  failed: 'Failed',
  resolved: 'Resolved',
  canceled: 'Canceled',
  closed: 'Closed',
  todo: 'To Do',
  doing: 'Doing',
  needs: 'Needs Approval',
};

export const IntentTypes = {
  wflTask: 'WFL Task',
  generalRequest: 'General Request',
  createNewItem: 'Create New Items',
  analyzeProductData: 'Analyze Product Data',
  case: 'Case',
  validateData: 'Validate Data',
  updateData: 'Update Data',
  getData: 'Get Data',
  validateVariation: 'Validate Variation',
  createVariation: 'Create Variation',
  amazonCase: 'Amazon Case',
};

export const ObjectTypes = {
  request: 'Request',
  task: 'Task',
  case: 'Case',
  question: 'AskQuestion',
};

export const AutomationStatusColors = {
  Queued: '#1379D2',
  Running: '#1379D2',
  Succeeded: '#56A364',
  Failed: theme.palette.ui.error,
  Canceled: theme.palette.neutrals.greyMed,
  Warning: theme.palette.secondary.salmon,
  none: theme.palette.neutrals.lightGrey,
};

export const AutomationStatusIcons = {
  Queued: AccessTimeFilled,
  Running: RunningIcon,
  Succeeded: CheckCircle,
  Failed: Cancel,
  Canceled: NotInterested,
  Warning: Error,
};

export const DetailPageTicketTypes = [
  { value: ObjectTypes.request, label: 'Service' },
  { value: ObjectTypes.question, label: 'Question' },
];

export const RequestTypes = {
  child: 'false',
  request: 'true',
  all: 'all',
};

export const AmazonStatusOptions = [
  {
    value: AmazonStatus.attention,
    label: AmazonStatus.attention,
    color: theme.palette.secondary.salmon,
  },
  {
    value: AmazonStatus.reOpened,
    label: AmazonStatus.reOpened,
    color: theme.palette.secondary.salmon,
  },
  {
    value: AmazonStatus.pending,
    label: AmazonStatus.pending,
    color: '#FFCE84',
  },
  { value: AmazonStatus.answered, label: AmazonStatus.answered, color: '#aae4ad' },
  {
    value: AmazonStatus.unassigned,
    label: AmazonStatus.unassigned,
    color: theme.palette.secondary.lightPurple,
  },
  {
    value: AmazonStatus.closed,
    label: AmazonStatus.closed,
    color: theme.palette.secondary.darkBlue,
  },
];

export const HelpDeskStatusOptions = [
  {
    value: HelpDeskStatus.todo,
    label: HelpDeskStatus.todo,
    color: '#FFCE84',
    isHidden: true,
  },
  {
    value: HelpDeskStatus.open,
    label: HelpDeskStatus.open,
    color: '#FFCE84',
    isHidden: true,
  },
  {
    value: HelpDeskStatus.notStarted,
    label: HelpDeskStatus.notStarted,
    color: '#FFCE84',
  },
  {
    value: HelpDeskStatus.inProgress,
    label: HelpDeskStatus.inProgress,
    color: '#FFCE84',
  },
  {
    value: HelpDeskStatus.attention,
    label: HelpDeskStatus.attention,
    color: theme.palette.secondary.salmon,
    isHidden: false,
  },
  {
    value: HelpDeskStatus.blocked,
    label: HelpDeskStatus.blocked,
    color: theme.palette.secondary.salmon,
    isHidden: false,
  },
  {
    value: HelpDeskStatus.failed,
    label: HelpDeskStatus.failed,
    color: theme.palette.ui.error,
    isHidden: true,
  },
  { value: HelpDeskStatus.resolved, label: HelpDeskStatus.resolved, color: '#aae4ad' },
  {
    value: HelpDeskStatus.canceled,
    label: HelpDeskStatus.canceled,
    color: theme.palette.neutrals.lightGrey,
    isHidden: true,
  },
  {
    value: HelpDeskStatus.closed,
    label: HelpDeskStatus.closed,
    color: theme.palette.secondary.darkBlue,
    isHidden: true,
  },
];

export const MemberStatus = {
  active: 'Active',
  invited: 'Invited',
  inactive: 'Inactive',
  contributor: 'Contributor',
  admin: 'Admin',
  assignable: 'Assignable',
};

export const MemberStatusList = [
  { key: 'isAssignable', label: MemberStatus.assignable },
  { key: 'isAdmin', label: MemberStatus.admin },
  { key: 'isContributor', label: MemberStatus.contributor },
];

export const MemberSearchKeys = ['name', 'email'];
