import PropTypes from 'prop-types';
import { isMobileDetected } from '@/utils/mobileDetect';

import WorkflowColorLightIcon from '@/assets/logo/workflow-color-light.svg';
import WorkflowColorDarkIcon from '@/assets/logo/workflow-color-dark.svg';
import WorkflowBlackLightIcon from '@/assets/logo/workflow-black-light.svg';
import WorkflowBlackDarkIcon from '@/assets/logo/workflow-black-dark.svg';
import HelpdeskColorLightIcon from '@/assets/logo/helpdesk-color-light.svg';
import HelpdeskColorDarkIcon from '@/assets/logo/helpdesk-color-dark.svg';
import HelpdeskBlackLightIcon from '@/assets/logo/helpdesk-black-light.svg';
import HelpdeskBlackDarkIcon from '@/assets/logo/helpdesk-black-dark.svg';

const WORKFLOW_WIDTH = 468;
const WORKFLOW_HEIGHT = 56;
const HELPDESK_WIDTH = 277;
const HELPDESK_HEIGHT = 56;

function LogoIcon({ type, iconType, isDarkTheme, width, height }) {
  let imgWidth = width;
  let imgHeight = height;
  let icon;

  if (type === 'workflow') {
    if (iconType === 'colorful' && !isDarkTheme) {
      icon = WorkflowColorLightIcon;
    } else if (iconType === 'colorful' && isDarkTheme) {
      icon = WorkflowColorDarkIcon;
    } else if (iconType === 'simple' && !isDarkTheme) {
      icon = WorkflowBlackLightIcon;
    } else if (iconType === 'simple' && isDarkTheme) {
      icon = WorkflowBlackDarkIcon;
    } else {
      icon = WorkflowColorLightIcon;
    }
    if (!imgWidth) imgWidth = WORKFLOW_WIDTH;
    if (!imgHeight) imgHeight = WORKFLOW_HEIGHT;
  } else if (type === 'helpdesk') {
    if (iconType === 'colorful' && !isDarkTheme) {
      icon = HelpdeskColorLightIcon;
    } else if (iconType === 'colorful' && isDarkTheme) {
      icon = HelpdeskColorDarkIcon;
    } else if (iconType === 'simple' && !isDarkTheme) {
      icon = HelpdeskBlackLightIcon;
    } else if (iconType === 'simple' && isDarkTheme) {
      icon = HelpdeskBlackDarkIcon;
    } else {
      icon = HelpdeskColorLightIcon;
    }
    if (!imgWidth) imgWidth = HELPDESK_WIDTH;
    if (!imgHeight) imgHeight = HELPDESK_HEIGHT;
  }

  return (
    <img
      src={icon}
      width={isMobileDetected ? '100%' : imgWidth}
      height={imgHeight}
      alt='logo'
    />
  );
}

LogoIcon.propTypes = {
  type: PropTypes.oneOf(['workflow', 'helpdesk']),
  width: PropTypes.number,
  height: PropTypes.number,
  iconType: PropTypes.oneOf(['simple', 'colorful']),
  isDarkTheme: PropTypes.bool
};

LogoIcon.defaultProps = {
  type: 'workflow',
  width: null,
  height: null,
  iconType: 'colorful',
  isDarkTheme: false
};

export default LogoIcon;
