import { trim } from 'lodash';
import { Config } from '@/config';
import { getStatus, convertLocalDateTime, getObjectType, getLogsEndpointURL } from '@/utils';
import * as fetchWithAuth from './fetchWithAuth';
import { FetchOptions } from './constants';

function parseTaskLogFromResponse(obj) {
  const title = obj.title;

  return {
    id: obj.id,
    row: obj.row,
    automation: obj.automation,
    workflow: obj.workflow,
    ticketId: obj.id,
    organization: obj.organization,
    accountId: obj.organizationId,
    isPinned: obj.isPinned,
    priority: obj.priority,
    objectType: getObjectType(obj),
    intentType: obj.service || obj.workflow,
    status: obj.status,
    state: getStatus(obj.state),
    title,
    description: title,
    assignedId: Number(obj.assignedTo),
    assignedName: trim(obj.assignedToName || ''),
    ownedByName: trim(obj.ownedByName || obj.createdByName || ''),
    commentedBy: Number(obj.commentedBy),
    commentedByName: trim(obj.commentedByName || ''),
    createdDate: convertLocalDateTime(obj.createdOn),
    lastUpdated: convertLocalDateTime(obj.updatedOn || obj.createdOn),
    requestedDate: convertLocalDateTime(obj.requestedByDate),
    ecd: convertLocalDateTime(obj.estimatedCompletion),
    isRead: !!obj.openQuestions,
  };
}

export const getTaskLogs = async (orgId, query, sort) => {
  const url = getLogsEndpointURL(`${Config.NEW_API}/Ticket/log`, orgId, query, sort);

  return fetchWithAuth.get(url).then((resp) => ({
    pageCount: resp.total,
    currentPage: resp.page,
    logs: resp.tickets.map(parseTaskLogFromResponse),
  }));
};

export const downloadLogsCSV = async (orgId, query, sort) => {
  const url = getLogsEndpointURL(`${Config.NEW_API}/Ticket/log/export`, orgId, query, sort, true);

  return fetchWithAuth
    .get(url, FetchOptions.stream)
    .then((res) => {
      const contentType = res.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        return res.json(); // handle JSON response
      } else {
        return res.blob(); // handle Blob stream response
      }
    })
    .then((data) => {
      if (data instanceof Blob) {
        return URL.createObjectURL(data);
      } else {
        return null;
      }
    });
};
