export const BASE_ROUTES = {
  main: '/',
  detail: '/detail',
  create: '/create',
  settings: '/settings',
  reporting: '/reporting',
  organizations: '/organizations',
};

export const UrlSearch = {
  organization: 'organization',
  menu: 'menu',
  workflowType: 'wconf',
  request: 'request',
  ticket: 'ticket',
  page: 'page',
  pageRows: 'pagerows',
  sort: 'sort',
  search: 'search',
  emailNotifications: 'receiveNotifications',
  createdAfter: 'createdAfter',
  tab: 'tab',
  hideBanner: 'ifr',
  token: 'token',
};

export const PageType = {
  accounts: 'accounts',
  members: 'members',
  profile: 'profile',
  support: 'support',
  notFound: 'notFound',
  taskLogs: 'task-logs',
  searchResult: 'search-result',
  taskDetail: 'task-detail',
  createTask: 'create-task',
  createUserTask: 'create-user-task',
  oneByOne: 'one-by-one',
  bulkUpdate: 'bulk-update',
  generalTask: 'general-task',
  variation: 'variation',
  suppression: 'suppression',
  newItemSetup: 'new-item-setup',
  case: 'case',
  caseModal: 'case-modal',
  autoCreate: 'autoCreate',
  question: 'question',
};
