import { SvgIcon } from '@mui/material';

export const SideCollapseIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 11" width="13px" height="11px" className={props.className}>
      <path
        d="M11.1673 0.5L12.334 1.66667L8.52149 5.5L12.334 9.33333L11.1673 10.5L6.16732 5.5L11.1673 0.5ZM5.66732 0.499999L6.83399 1.66667L3.02149 5.5L6.83398 9.33333L5.66732 10.5L0.667319 5.5L5.66732 0.499999Z"
        fill="#081949"
      />
    </SvgIcon>
  );
};
