import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import NotificationsComponent from './Notifications';

const NotificationsContainer = () => {
  const [notificationToasts, setNotificationToasts] = useState([]);

  const addNotification = (notification) => {
    setNotificationToasts((prevNotificationToasts) => [...prevNotificationToasts, notification]);
  };

  const removeNotification = (id) => {
    setNotificationToasts((prevNotificationToasts) =>
      prevNotificationToasts.filter((toast) => toast.id !== id),
    );
  };

  const createNotification = (variant, message, options = {}) => {
    const mapVariantToContent = {
      error: 'Error',
      success: 'Success',
      general: 'general',
    };

    const DEFAULT_AUTO_HIDE_DURATION = 5000;
    const notification = {
      autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
      content: message || mapVariantToContent[variant] || 'info',
      id: uuidv4(),
      variant,
      ...options,
    };

    addNotification(notification);
    return notification.id;
  };

  const showSuccessNotification = (message, options = {}) =>
    createNotification('success', message, options);

  const showErrorNotification = (message, options = {}) =>
    createNotification('error', message, options);

  const showGeneralNotification = (message, options = {}) =>
    createNotification('general', message, options);

  useEffect(() => {
    window[Symbol.for('notificationToasts')] = {
      createNotification: addNotification,
      removeNotification,
      showSuccessNotification,
      showErrorNotification,
      showGeneralNotification,
    };
  }, []);

  return (
    <NotificationsComponent
      notifications={notificationToasts}
      removeNotification={removeNotification}
    />
  );
};

export default NotificationsContainer;
