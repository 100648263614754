import { makeObservable, observable, action, computed, reaction } from 'mobx';
import { omit } from 'lodash';

import { UrlSearch } from '@/types';
import { isValidateToken } from '@/api/users';
import { userStore } from './UserStore';
import { clearToken } from '@/utils';
import { setError } from '@/utils/errors';

export class RouterStore {
  searchParams = null;
  setSearchParams = undefined;
  currentPage = '';
  mainPageUrlPath = null;
  isHideBanner = false;
  isValidating = false;
  isValidToken = false;
  token = '';

  constructor() {
    makeObservable(this, {
      searchParams: observable,
      reInitSearch: action,

      search: computed,
      setSearch: action,
      setSearchParam: action,
      removeSearchParam: action,
      setSearchChunkParams: action,

      currentPage: observable,
      setCurrentPage: action,

      mainPageUrlPath: observable,
      lastMainPageLink: computed,
      setLastMainPageLink: action,

      isHideBanner: observable,
      setHideBanner: action,

      token: observable,
      isValidating: observable,
      isValidToken: observable,
      setToken: action,
      removeToken: action,
    });

    this.validatorTokenAction = reaction(
      () => [this.token],
      () => this.validateToken(),
      { fireImmediately: true },
    );
  }

  get search() {
    if (!this.searchParams) {
      return {};
    }
    return Object.fromEntries(this.searchParams.entries());
  }

  setSearch(search) {
    this.setSearchParams?.(search);
  }

  getSearchParam(key) {
    return this.search[key] || '';
  }

  setSearchParam(key, value) {
    this.setSearch({ ...this.search, [key]: value });
  }

  removeSearchParam(key) {
    this.setSearch(omit(this.search, [key]));
  }

  removeSearchParams(keys) {
    this.setSearch(omit(this.search, keys));
  }

  getSearchChunkParams() {
    return this.search;
  }

  setSearchChunkParams(params) {
    this.setSearch(params);
  }

  setCurrentPage(path) {
    this.currentPage = path;
  }

  // NOTE: Global functions
  get lastMainPageLink() {
    return this.mainPageUrlPath || '';
  }

  setLastMainPageLink() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(UrlSearch.workflowType);
    this.mainPageUrlPath = `?${urlParams.toString()}`;
  }

  setHideBanner(value) {
    this.isHideBanner = value;
  }

  getMainParams(prefix) {
    const bannerParam = this.isHideBanner ? `${prefix}${UrlSearch.hideBanner}=true` : '';
    return bannerParam;
  }

  setToken(token) {
    clearToken();
    this.token = token;
  }

  removeToken() {
    clearToken();
    this.token = '';
    this.isValidToken = false;
  }

  async validateToken() {
    this.isValidating = true;
    try {
      if (!this.token) {
        this.isValidToken = false;
      } else {
        const isValidToken = await isValidateToken(this.token);
        if (isValidToken) userStore.login(this.token);

        this.isValidToken = isValidToken;
        this.isValidating = false;
      }
    } catch (err) {
      setError(err);
    }
    this.isValidating = false;
  }

  dispose() {
    this.validatorTokenAction.dispose();
  }

  reInitSearch(searchParams, setSearchParams) {
    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
  }
}
