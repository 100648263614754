import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Config } from '@/config';
import { Button, Typography, TextField, Switch, LoadingIndicator } from '@/components';
import { userApi } from '@/api';
import { withStore, userStore } from '@/services/store';
import { convertToBool, showSuccessNotification } from '@/utils';
import { NotificationText, UrlSearch } from '@/types';
import { useSettingsStore } from '../stores/useSettingsStore';
import { setError } from '@/utils/errors';

import styles from './MyProfile.module.css';

const PAGE_NAME = 'MyProfile';

const MyProfile = () => {
  const { routerStore } = useSettingsStore();
  const currentUser = userStore.currentUser;
  const [isPersonInfoEditing, setIsPersonInfoEditing] = useState(false);
  const [user, setUser] = useState(currentUser);
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const { organizationId, email, first: firstName, last: lastName, emailsEnabled } = user;

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  useEffect(() => {
    const emailNotificationEnabled = convertToBool(
      routerStore.getSearchParam(UrlSearch.emailNotifications),
    );
    if (
      user.id &&
      UrlSearch.emailNotifications in routerStore.search &&
      emailNotificationEnabled !== user.emailsEnabled
    ) {
      toggleEmailNotification(emailNotificationEnabled);
    }
  }, [user]);

  const handleChangeUser = (key, evt) => {
    setUser({
      ...user,
      [key]: key === 'emailsEnabled' ? evt : evt.target.value,
    });
  };

  const updateUser = async () => {
    userStore.updateName(firstName, lastName);
    await userApi.updateMyProfile();
  };

  const toggleEmailNotification = async (emailsEnabled) => {
    try {
      await userApi.toggleEmailsNotification(emailsEnabled);
      userStore.setUserEmailEnabled(emailsEnabled);
      handleChangeUser('emailsEnabled', emailsEnabled);
      routerStore.setSearchParam(UrlSearch.emailNotifications, emailsEnabled);
      showSuccessNotification(
        emailsEnabled
          ? 'Notifications turned on successfully. You will begin receiving them in the upcoming days.'
          : 'Notifications turned off successfully.',
      );
    } catch (err) {
      setError(err, false, NotificationText.toggleEmailsNotification);
    }
  };

  const handleSavePerson = async () => {
    setIsSaveButtonClicked(true);
    if (!firstName || !lastName) return;

    try {
      await updateUser();
      setIsPersonInfoEditing(false);
      await userStore.fetchUsers(organizationId);
    } catch (err) {
      setError(err, false, NotificationText.updateProfileNameError);
    }
    setIsSaveButtonClicked(false);
  };

  const sendResetEmail = async () => {
    const domain = Config.REACT_APP_AUTH0_DOMAIN;
    const clientId = Config.REACT_APP_AUTH0_CLIENT_ID;
    const connection = Config.REACT_APP_AUTH0_DB_CONNECTION;
    const url = `https://${domain}/dbconnections/change_password`;
    const data = {
      client_id: `${clientId}`,
      email,
      connection,
    };
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, options);
      if (response.status === 200) {
        showSuccessNotification('We have sent you an email to reset your password.');
      }
    } catch (err) {
      setError(err, false, 'Reset password email failed');
    }
  };

  if (!currentUser.id) return <LoadingIndicator fullScreen />;
  return (
    <div className={styles.accountPageContainer}>
      <div className={styles.accountSettings}>
        <div id={`${PAGE_NAME}_settingContainer`} className={styles.subPersonalSetting}>
          <Typography variant="h5" className={styles.personalInfo}>
            Personal Information
          </Typography>
          {!isPersonInfoEditing ? (
            <Typography
              variant="button-large"
              className={styles.edit}
              onClick={() => setIsPersonInfoEditing(true)}
              id={`${PAGE_NAME}_editProfileButton`}
            >
              Edit
            </Typography>
          ) : (
            <div className={styles.edit}>
              <Button
                size="small"
                variant="primary"
                onClick={handleSavePerson}
                id={`${PAGE_NAME}_saveButton`}
              >
                Save
              </Button>
              <Button
                size="small"
                variant="tertiary"
                onClick={() => {
                  setUser(currentUser);
                  setIsPersonInfoEditing(false);
                  setIsSaveButtonClicked(false);
                }}
                id={`${PAGE_NAME}_cancelButton`}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
        <div
          id={`${PAGE_NAME}_personalInformationContainer`}
          className={styles.personalInputContainer}
        >
          <TextField
            label="First Name*"
            value={firstName}
            style={{ width: 264 }}
            inactive={!isPersonInfoEditing}
            disabled={!isPersonInfoEditing}
            error={isSaveButtonClicked && !firstName}
            helperText={isSaveButtonClicked && !firstName ? 'Please provide a first name.' : null}
            onChange={(e) => handleChangeUser('first', e)}
            id={`${PAGE_NAME}_firstName_textField`}
          />
          <TextField
            label="Last Name*"
            value={lastName}
            style={{ width: 264 }}
            inactive={!isPersonInfoEditing}
            disabled={!isPersonInfoEditing}
            error={isSaveButtonClicked && !lastName}
            helperText={isSaveButtonClicked && !lastName ? 'Please provide a last name.' : null}
            onChange={(e) => handleChangeUser('last', e)}
            id={`${PAGE_NAME}_lastName_textField`}
          />
        </div>

        <div className={styles.divideLine} />

        <div className={styles.subAccountSetting}>
          <Typography variant="h5">Account Information</Typography>
          <div className={styles.edit} onClick={sendResetEmail}>
            <Typography
              variant="button-large"
              className={styles.editButton}
              id={`${PAGE_NAME}_editPasswordLink`}
            >
              Change Password
            </Typography>
            <OpenInNewIcon className={styles.editIcon} />
          </div>
        </div>
        <div className={styles.personalInputContainer}>
          <TextField label="Work Email*" value={email} style={{ width: 264 }} inactive disabled />
          <TextField
            type="password"
            label="Password*"
            value={'!@#$%^&*'}
            style={{ width: 264 }}
            inactive
            disabled
          />
        </div>

        <div className={styles.divideLine} />
        <Typography variant="h5" className={styles.subAccountSetting}>
          Email Notifications
        </Typography>
        <div className={styles.personalInputContainer}>
          <Switch
            label="Receive Daily Email Notifications "
            checked={emailsEnabled}
            id={`${PAGE_NAME}_switchItem`}
            onChange={(event) => toggleEmailNotification(event.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default withStore(observer(MyProfile));
