import { SvgIcon } from '@mui/material';

export const SatisfiedIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21" width="21px" height="21px" className={props.className}>
      <path d="M14.6207 8.84688C14.9957 8.84688 15.3145 8.71563 15.577 8.45312C15.8395 8.19062 15.9707 7.87188 15.9707 7.49688C15.9707 7.12188 15.8395 6.80313 15.577 6.54063C15.3145 6.27813 14.9957 6.14687 14.6207 6.14687C14.2457 6.14687 13.927 6.27813 13.6645 6.54063C13.402 6.80313 13.2707 7.12188 13.2707 7.49688C13.2707 7.87188 13.402 8.19062 13.6645 8.45312C13.927 8.71563 14.2457 8.84688 14.6207 8.84688ZM7.3207 8.84688C7.6957 8.84688 8.01445 8.71563 8.27695 8.45312C8.53945 8.19062 8.6707 7.87188 8.6707 7.49688C8.6707 7.12188 8.53945 6.80313 8.27695 6.54063C8.01445 6.27813 7.6957 6.14687 7.3207 6.14687C6.9457 6.14687 6.62695 6.27813 6.36445 6.54063C6.10195 6.80313 5.9707 7.12188 5.9707 7.49688C5.9707 7.87188 6.10195 8.19062 6.36445 8.45312C6.62695 8.71563 6.9457 8.84688 7.3207 8.84688ZM10.9707 20.1719C9.58737 20.1719 8.28737 19.9094 7.0707 19.3844C5.85404 18.8594 4.7957 18.1469 3.8957 17.2469C2.9957 16.3469 2.2832 15.2885 1.7582 14.0719C1.2332 12.8552 0.970703 11.5552 0.970703 10.1719C0.970703 8.78854 1.2332 7.48854 1.7582 6.27187C2.2832 5.05521 2.9957 3.99687 3.8957 3.09687C4.7957 2.19687 5.85404 1.48438 7.0707 0.959375C8.28737 0.434375 9.58737 0.171875 10.9707 0.171875C12.354 0.171875 13.654 0.434375 14.8707 0.959375C16.0874 1.48438 17.1457 2.19687 18.0457 3.09687C18.9457 3.99687 19.6582 5.05521 20.1832 6.27187C20.7082 7.48854 20.9707 8.78854 20.9707 10.1719C20.9707 11.5552 20.7082 12.8552 20.1832 14.0719C19.6582 15.2885 18.9457 16.3469 18.0457 17.2469C17.1457 18.1469 16.0874 18.8594 14.8707 19.3844C13.654 19.9094 12.354 20.1719 10.9707 20.1719ZM10.9707 18.6719C13.3436 18.6719 15.3535 17.8484 17.0004 16.2016C18.6473 14.5547 19.4707 12.5448 19.4707 10.1719C19.4707 7.79896 18.6473 5.78906 17.0004 4.14218C15.3535 2.49531 13.3436 1.67188 10.9707 1.67188C8.59779 1.67188 6.58789 2.49531 4.941 4.14218C3.29414 5.78906 2.4707 7.79896 2.4707 10.1719C2.4707 12.5448 3.29414 14.5547 4.941 16.2016C6.58789 17.8484 8.59779 18.6719 10.9707 18.6719Z" />
      <path d="M14.009 15.5156C13.1007 16.1406 12.0867 16.4531 10.9671 16.4531C9.84757 16.4531 8.8309 16.1448 7.91713 15.5281C7.00337 14.9115 6.33815 14.0781 5.92148 13.0281L16.0215 13.0281C15.5882 14.0615 14.9173 14.8906 14.009 15.5156Z" />
    </SvgIcon>
  );
};
