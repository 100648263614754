import { useEffect, createContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { rootStore } from './RootStore';

const StoreContext = createContext({});

export function withStore(WrappedComponent) {
  return (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      rootStore.routerStore.reInitSearch(searchParams, setSearchParams);
    }, [searchParams, setSearchParams]);

    return (
      <StoreContext.Provider value={rootStore}>
        <WrappedComponent {...props} />
      </StoreContext.Provider>
    );
  };
}
