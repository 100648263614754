import { Popover } from '@mui/material';

import { theme } from '@/config/theme';
import { ContextMenuItem } from '@/components';
import { ObjectTypes } from '@/types';

export const DetailCreateMenuPopover = ({ anchorEl, handleClose, handleSelectMenuItem }) => {
  return (
    <Popover
      id={`TopNavigation_Settings_Popover`}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      PaperProps={{
        style: {
          width: 144,
          marginTop: 4,
          borderRadius: '16px',
          border: `1px solid ${theme.palette.neutrals.lightGrey}`,
          boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      <ContextMenuItem
        small={false}
        onClick={() => handleSelectMenuItem(ObjectTypes.request)}
        id="detail_create_task_menuitem"
      >
        Service
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() => handleSelectMenuItem(ObjectTypes.question)}
        small={false}
        id="detail_create_question_menuitem"
      >
        Question
      </ContextMenuItem>
    </Popover>
  );
};
