export const FetchOptions = {
  stream: { isStream: true },
  status: { isStatus: true },
  noAuth: { hasNoAuth: true },
};

export const StatusCode = {
  ok: 200,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
};
