import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isEqual, orderBy, sortBy } from 'lodash';
import { userApi, platformApi, organizationApi } from '@/api';
import { getStatus, getStatusColor, mapToUserOption, saveToken, sortMembers } from '@/utils';
import { AssignType, NotificationText } from '@/types';
import { TicketStates } from '@/utils/constants';
import { DEFAULT_BRANDING_INFO } from '@/pages/settings/constants';
import { WORKFLOW_ORG_ID } from '../constants';
import { setError } from '@/utils/errors';

export class UserStore {
  version = '1.0.0.0';
  currentUser = {};
  organizationId = null;
  members = [];
  assignOptions = [];
  closeTaskTrigger = false;
  isUnAuthorizedError = false;
  apiCriticalIssue = null;
  states = [];
  platformTypes = [];
  appLoaded = false;
  prevUrlParams = null;
  lastUrlLoaded = false;
  organizationLogo = null;
  organizationName = '';
  organizationHelpLink = DEFAULT_BRANDING_INFO.helpUrl;
  isRefresh = false;
  workflowConfigList = [];
  errorAPIs = {};

  constructor() {
    makeObservable(this, {
      version: observable,
      errorAPIs: observable,
      // User parameters
      currentUser: observable,
      isWFLAdminUser: computed,
      setUser: action,
      setUserEmailEnabled: action,
      organizationId: observable,
      members: observable,
      assignOptions: observable,
      ownerOptions: computed,
      updatePinned: action,
      updateName: action,
      prevUrlParams: observable,
      setUrlParams: action,
      lastUrlLoaded: observable,
      setLastUrlLoaded: action,

      // Global parameters
      appLoaded: observable,
      setAppLoaded: action,
      states: observable,
      platformTypes: observable,

      closeTaskTrigger: observable,
      setCloseTaskTrigger: action,
      isUnAuthorizedError: observable,
      setIsUnAuthorizedError: action,
      apiCriticalIssue: observable,
      setApiCriticalIssue: action,

      organizationName: observable,
      organizationLogo: observable,
      organizationHelpLink: observable,
      updateLogoHelpLink: action,

      isRefresh: observable,
      setRefresh: action,

      workflowConfigList: observable,
    });
  }

  // NOTE: Update history profile
  async setUrlParams(params) {
    if (!this.appLoaded) return;

    if (!isEqual(params, this.prevUrlParams)) {
      try {
        await userApi.updateUserFilters({
          former: { path: params },
        });
        this.prevUrlParams = params;
      } catch (err) {
        setError(err, false, NotificationText.updateProfileLastLogError);
      }
    }
  }

  setLastUrlLoaded() {
    this.lastUrlLoaded = true;
  }

  // NOTE: Login
  async login(accessToken) {
    saveToken(accessToken);
    try {
      return await this.fetchUser();
    } catch (err) {
      return null;
    }
  }

  getFullName(user) {
    return `${user.first} ${user.last}`;
  }

  getAssign(assignId, userOptions) {
    return userOptions.find((item) => item.value === assignId) ?? {};
  }

  getUnknownUser(name) {
    return { label: name, type: AssignType.teal };
  }

  getAssignFromName(name) {
    return this.assignOptions.find((item) => item.label === name) ?? this.getUnknownUser(name);
  }

  getOwnerFromName(name) {
    return this.ownerOptions.find((item) => item.label === name) ?? this.getUnknownUser(name);
  }

  // NOTE: User manage
  setUser(user) {
    this.currentUser = { ...user };
    const options = this.assignOptions?.map((option) =>
      option.value === user.id ? { ...option, label: this.getFullName(user) } : option,
    );
    this.assignOptions = orderBy(options, [(option) => option.label.toLowerCase()], 'asc');
  }

  setUserEmailEnabled(value) {
    this.currentUser.emailsEnabled = value;
  }

  get isWFLAdminUser() {
    return this.organizationId === WORKFLOW_ORG_ID && this.currentUser.isAdmin;
  }

  get isNonAdmin() {
    return !this.currentUser.isAdmin;
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  async fetchUsers(organizationId) {
    try {
      const assigns = await organizationApi.getOrganizationUsers(organizationId);

      runInAction(() => {
        this.members = sortMembers(assigns);
        this.assignOptions = this.members.map(mapToUserOption);
      });
    } catch (err) {
      setError(err);
    }
    return [];
  }

  async fetchOrganizationInfo(selectedOrgId) {
    if (selectedOrgId) {
      const organizationInfo = await organizationApi.getOrganization(selectedOrgId);
      this.updateLogoHelpLink(
        organizationInfo.profile?.branding?.logoUrl,
        organizationInfo.profile?.branding?.helpUrl,
      );
      this.organizationName = organizationInfo.name;
    }
  }

  async fetchUser() {
    try {
      const me = await userApi.getMyProfile();
      await this.fetchOrganizationInfo(me.organizationId);
      await this.fetchUsers(me.organizationId);
      await this.fetchPicklist();
      runInAction(() => {
        this.currentUser = {
          ...me,
          type: this.members.find((user) => user.id === me.id)?.type,
        };
        this.organizationId = this.currentUser.organizationId;
        return this.currentUser;
      });
    } catch (err) {
      setError(err, true);
    }
  }

  updatePinned(id, isAdd, isRequest = false) {
    if (isAdd) {
      if (isRequest) {
        this.currentUser.pinnedRequests = this.currentUser.pinnedRequests.concat(id);
      } else {
        this.currentUser.pinnedTickets = this.currentUser.pinnedTickets.concat(id);
      }
    } else {
      if (isRequest) {
        this.currentUser.pinnedRequests = this.currentUser.pinnedRequests.filter(
          (item) => item !== id,
        );
      } else {
        this.currentUser.pinnedTickets = this.currentUser.pinnedTickets.filter(
          (item) => item !== id,
        );
      }
    }
  }

  updateName(firstName, lastName) {
    this.currentUser.first = firstName;
    this.currentUser.last = lastName;
    this.currentUser.name = `${firstName} ${lastName}`;
  }

  setRefresh() {
    this.isRefresh = !this.isRefresh;
  }

  updateLogoHelpLink(logoUrl, helpLink) {
    this.organizationLogo = logoUrl || DEFAULT_BRANDING_INFO.logoUrl;
    this.organizationHelpLink = helpLink || DEFAULT_BRANDING_INFO.helpUrl;
  }

  async fetchPicklist() {
    try {
      const states = await platformApi.getPickList(TicketStates);
      const platformTypes = await platformApi.getPlatformTypes();
      runInAction(() => {
        this.states = sortBy(states, 'name').map((item) => {
          const status = getStatus(item.name);
          return {
            id: item.id,
            value: status,
            label: item.name,
            color: getStatusColor(status),
          };
        });
        this.platformTypes = platformTypes;
      });
    } catch (err) {
      setError(err, true);
    }
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

  setCloseTaskTrigger() {
    this.closeTaskTrigger = !this.closeTaskTrigger;
  }

  setIsUnAuthorizedError() {
    this.isUnAuthorizedError = true;
  }

  setApiCriticalIssue(issue) {
    this.apiCriticalIssue = issue;
  }
}
/* Store end */

export const userStore = new UserStore();
