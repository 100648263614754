import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import { Psychology } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import { Button as MuiButton } from '@mui/material';

import { HelpDeskStatus, ObjectType, ObjectTypes } from '@/types';
import {
  Typography,
  Tooltip,
  LoadingIndicator,
  ConfirmDialog,
  TypeaheadDropdown,
  Button,
} from '@/components';
import { userStore } from '@/services/store/UserStore';
import { useCreateTaskStore } from '@/pages/create/stores/useCreateTaskStore';
import { useDetailStore } from '../../stores/useDetailStore';
import { StatusSelect } from './sharedComponent/StatusSelect';
import { AmazonStatus } from './sharedComponent/AmazonStatus';
import { TaskDetailPopover } from './TaskDetailPopover';
import { RequestContent } from './RequestContent';
import { TaskContent } from './TaskContent';
import { CaseContent } from './CaseContent';
import { QuestionContent } from './QuestionContent';
import { FeedbackPopover } from './FeedbackPopover';
import { DetailCreateMenuPopover } from './DetailCreateMenuPopover';
import { AutomationLog } from './DetailWorkflowContent/AutomationLog';

import styles from './DetailContent.module.css';

const PAGE_NAME = 'DetailContent';

export const DetailContent = observer(
  ({ isRequest, ticketId, openCreateTask, onRefresh, setIsConfigSettingsOpen }) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [createAnchorEl, setCreateAnchorEl] = useState(null);
    const { detailStore } = useDetailStore();
    const createTaskStore = useCreateTaskStore();

    useEffect(() => {
      detailStore.fetchDetailContent(ticketId);
    }, [ticketId]);

    const handleCancelRequest = async () => {
      setIsConfirmOpen(false);
      detailStore.updateStatus(HelpDeskStatus.canceled, isRequest);
    };

    const handleMenuItemClick = (ticketType) => {
      
      createTaskStore.setDetailTicketType(ticketType);

      if (createTaskStore.detailTicketType === ObjectTypes.question) {
        openCreateTask(ticketType);
      } else {
        openCreateTask(null);
      }

      setCreateAnchorEl(null);
    };

    const handleCreateClick = (e) => {
      createTaskStore.setSelectedWorkflowType(null);
      setCreateAnchorEl(e.target);
    };

    if (detailStore.isLoading || detailStore.objectType === ObjectType.none) {
      return (
        <div className={styles.detailContentContainer}>
          <LoadingIndicator />
        </div>
      );
    }

    return (
      <div className={styles.detailContentContainer} id={`${PAGE_NAME}_container_div`}>
        <div className={styles.titleContainer}>
          <div className={styles.titleWrapper}>
            <Typography variant="h6" id={`${PAGE_NAME}_header_title`} className={styles.title}>
              {detailStore.title}
            </Typography>
            <Button
              id="execute-btn"
              variant="secondary"
              disabled={!detailStore.canExecute}
              onClick={() => detailStore.executeTicket()}
            >
              Execute
            </Button>
            <Tooltip title="Remind assignee by sending an email." arrow>
              <MuiButton
                id={`${PAGE_NAME}_remind_button`}
                variant="secondary"
                classes={{ root: styles.secondaryButton }}
                onClick={() => detailStore.remindTicket()}
              >
                Remind
              </MuiButton>
            </Tooltip>
            <Button id="create_button" startIcon={<AddIcon />} onClick={handleCreateClick}>
              Create
            </Button>
          </div>

          <div className={styles.actionWrapper}>
            <div className={styles.dropdownWrapper} id={`${PAGE_NAME}_header_fieldContainer`}>
              <TypeaheadDropdown
                classes={{ root: styles.flexNone }}
                id={`${PAGE_NAME}_ownerDropdown`}
                value={detailStore.owner?.value}
                options={detailStore.ownerOptions}
                secondaryLabel="Owner"
                onChange={(value) => detailStore.updateOwner(value, isRequest)}
              />
              <TypeaheadDropdown
                classes={{ root: styles.flexNone }}
                id={`${PAGE_NAME}_assignDropdown`}
                value={detailStore.assign?.value}
                options={detailStore.assignOptions}
                secondaryLabel="Assigned to"
                onChange={(value) => detailStore.updateAssign(value, isRequest)}
              />
              <StatusSelect
                id={`${PAGE_NAME}_statusSelect`}
                value={detailStore.status}
                options={userStore.states}
                autoHeight
                onChange={(v) =>
                  v === HelpDeskStatus.canceled
                    ? setIsConfirmOpen(true)
                    : detailStore.updateStatus(v, isRequest)
                }
              />
              {detailStore.objectType === ObjectType.case && detailStore.externalStatus && (
                <AmazonStatus
                  id={`${PAGE_NAME}_externalStatusSelect`}
                  value={detailStore.externalStatus}
                />
              )}
              <TaskDetailPopover isRequest={isRequest} onRefresh={onRefresh} />
              {detailStore.isValidWorkflow && (
                <Tooltip title="Workflow Configuration" arrow placement="top">
                  <IconButton
                    id={`${PAGE_NAME}_close_iconButton`}
                    size="small"
                    color="inherit"
                    onClick={() => setIsConfigSettingsOpen((visible) => !visible)}
                    classes={{ root: styles.IconButton }}
                  >
                    <Psychology />
                  </IconButton>
                </Tooltip>
              )}
              <AutomationLog
                automation={detailStore.automation}
                isAdminUser={userStore.isWFLAdminUser}
              />
              <Tooltip
                title={detailStore.isCopied ? 'Copied to Clipboard' : 'Copy URL'}
                arrow
                placement="top"
              >
                <div
                  className={styles.actionItem}
                  onClick={() => detailStore.copyLink()}
                  id={`${PAGE_NAME}_header_copyLink`}
                >
                  <LinkIcon className={styles.actionIcon} />
                </div>
              </Tooltip>
            </div>
            <FeedbackPopover ticketId={ticketId} />
          </div>
        </div>

        {detailStore.objectType === ObjectType.request && <RequestContent ticketId={ticketId} />}
        {detailStore.objectType === ObjectType.task && <TaskContent ticketId={ticketId} />}
        {detailStore.objectType === ObjectType.case && <CaseContent ticketId={ticketId} />}
        {detailStore.objectType === ObjectType.question && <QuestionContent ticketId={ticketId} />}

        {isConfirmOpen && (
          <ConfirmDialog
            id={`${PAGE_NAME}_cancelConfirmDialog`}
            open
            title={`Cancel This ${isRequest ? 'Request' : 'Task'}?`}
            description={`Canceling this ${
              isRequest
                ? 'request will cancel all of the associated subtasks underneath it. To view them again, please go to the service log and search for items that are “canceled”.'
                : 'task will hide it on this detail page. You can find it later by searching for it on the service log.'
            }`}
            okText={isRequest ? 'Cancel Request' : 'Cancel Task'}
            cancelText="Do not cancel"
            onCancel={() => setIsConfirmOpen(false)}
            onOk={handleCancelRequest}
            containerClassName={styles.cancelDialog}
          />
        )}
        <DetailCreateMenuPopover
          anchorEl={createAnchorEl}
          handleSelectMenuItem={handleMenuItemClick}
          handleClose={() => {
            setCreateAnchorEl(null);
          }}
        />
      </div>
    );
  },
);
