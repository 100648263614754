import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import cx from 'clsx';
import Dropzone from 'react-dropzone';

import { makeStyles } from '@mui/styles';
import Typography from '../Typography';
import { AttachmentArea } from './AttachmentArea';

const useStyles = makeStyles((theme) => ({
  imageUploader: {
    fontSize: '14px',
    width: '338px',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    width: '100%',
    display: 'flex',
    color: theme.palette.neutrals.greyMed,
    cursor: 'pointer',
  },
  activeDropzone: {
    background: 'transparent',
  },
  errorLabel: {
    color: `${theme.palette.ui.error} !important`,
    marginTop: '4px !important',
  },
  selectFileWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  selectFile: {
    color: `${theme.palette.primary.purple} !important`,
    background: 'none !important',
    boxShadow: 'none !important',
    textTransform: 'none !important',
    paddingRight: '4px',
    height: '20px',
    fontFamily: 'Open Sans',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    letterSpacing: '0.15px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  fileInput: {
    display: 'none !important',
    userSelect: 'none !important',
  },
}));

const PAGE_NAME = 'FileAttachment';

function CompactAttachment({
  files,
  title,
  accept,
  error = false,
  contentClassName,
  dropZoneClassName,
  onUpdateFile,
  multiple,
}) {
  const styles = useStyles();
  const selectFile = (selectedFiles) => {
    onUpdateFile(files.concat(selectedFiles));
  };

  const handleDeleteFile = (file) => {
    onUpdateFile(files.filter((item) => !isEqual(file, item)));
  };

  return (
    <div className={cx(styles.imageUploader, contentClassName)} id={`${PAGE_NAME}_div`}>
      <Dropzone
        accept={accept}
        onDrop={(files) => selectFile(files)}
        className={cx(styles.dropzone, dropZoneClassName, { [styles.errorZone]: error })}
        activeClassName={styles.activeDropzone}
        multiple={multiple}
      >
        <div className={styles.selectFileWrapper}>
          <Typography variant="subtitle1">{title}</Typography>
          <span component="label" className={styles.selectFile}>
            [+]
          </span>
        </div>

        <input
          className={styles.fileInput}
          type="file"
          accept={accept}
          onChange={(event) => selectFile(event.target.files)}
          multiple={multiple}
        />
      </Dropzone>

      {error && (
        <Typography variant="subtitle2" className={styles.errorLabel}>
          File size exceeded limit (20MB).
        </Typography>
      )}

      {files?.length > 0 && (
        <div id={`${PAGE_NAME}_AttachedFiles_div`}>
          <AttachmentArea files={files} onDeleteFile={handleDeleteFile} />
        </div>
      )}
    </div>
  );
}

CompactAttachment.propTypes = {
  file: PropTypes.any,
  title: PropTypes.string,
  accept: PropTypes.string,
  onUpdateFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func,
  multiple: PropTypes.bool,
};

CompactAttachment.defaultProps = {
  file: null,
  title: 'Attachments',
  accept: '',
  onDeleteFile: undefined,
  multiple: true,
};

export default CompactAttachment;
