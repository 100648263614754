import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { TaskBullet } from '@/components';
import styles from './DetailList.module.css';

const PAGE_NAME = 'TaskDetailList';

export const DetailList = observer(
  ({ data, ticketId, scrollTicketId, isDragMode, onActiveTicket, onMoveTicket }) => {
    const containerRef = useRef();

    useEffect(() => {
      if (scrollTicketId && data.length > 0) {
        const pos = data.findIndex((item) => item.ticketId === scrollTicketId);
        let top = 0;
        for (let i = 0; i < pos; i++) {
          top += containerRef.current.children[i]?.offsetHeight || 0;
        }
        containerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }, [scrollTicketId, data, containerRef]);

    return (
      <div id={`${PAGE_NAME}_container`} className={styles.detailListContainer} ref={containerRef}>
        {data.map((ticket) => (
          <TaskBullet
            assignerCompanyName={ticket.assign?.organizationName}
            assignerRoleName={ticket.assign?.role}
            variant={ticket.serviceType}
            key={ticket.ticketId}
            id={`${PAGE_NAME}_taskItem`}
            selected={ticketId === ticket.ticketId}
            title={ticket.title}
            assignType={ticket.assign?.type}
            assignName={ticket.assign?.name}
            status={ticket.state}
            isDot={ticket.openQuestions > 0}
            externalStatus={ticket.externalStatus}
            generation={Math.max(-1, ticket.ticketPath.length - 2)}
            ticketId={ticket.ticketId}
            ticketPath={ticket.ticketPath}
            isDragMode={isDragMode}
            onMoveTicket={onMoveTicket}
            onClick={() =>
              onActiveTicket({
                isRequest: false,
                id: ticket.ticketId,
                type: ticket.objectType,
                organizationId: ticket.organizationId,
              })
            }
          />
        ))}
      </div>
    );
  },
);
