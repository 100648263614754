import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteIcon } from '@/assets/icons';
import { SaveAltIcon } from '@/assets/icons';
import { LoadingIndicator } from '@/components';
import { downloadFile } from '@/utils';

const useStyles = makeStyles((theme) => ({
  attachedFiles: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    gap: '12px',
  },
  attachment: {
    display: 'flex',
    alignItems: 'center',
    gap: '9px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '9px',
  },
  fileName: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.primary.purple,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 900px)',
  },
  deleteFile: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '13px',
  },
  uploadingFile: {
    maxWidth: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  uploadingLabel: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.secondary.darkBlue,
    whiteSpace: 'nowrap',
  },
  downloadIcon: {
    color: theme.palette.primary.purple,
    '&:hover': {
      color: theme.palette.primary.darkPurple,
    },
  },
  cancelIcon: {
    borderRadius: '50%',
    color: '#929398 !important',
    '&:hover': {
      color: `${theme.palette.secondary.darkBlue} !important`,
    },
  },
}));

const PAGE_NAME = 'CommentAttachmentArea';
const MAX_WORDS = 25;

export function CommentAttachmentArea({
  files,
  addingFiles,
  deletingFiles,
  maxWidth,
  onDeleteFile,
}) {
  const styles = useStyles();
  const firstWords = (name) => {
    if (name.length >= MAX_WORDS) return name.substring(0, MAX_WORDS / 2);
    return name;
  };
  const secondWords = (name) => {
    if (name.length >= MAX_WORDS) return `...${name.substring(name.length + 3 - MAX_WORDS / 2)}`;
    return null;
  };

  return (
    <Grid container className={styles.attachedFiles}>
      {files
        .filter((file) => file)
        .map((file, idx) => (
          <Grid key={idx} item className={styles.attachment}>
            <div
              id={`${PAGE_NAME}_AttachedFiles_fileInfo`}
              className={styles.attachment}
              onClick={() => downloadFile(file)}
            >
              <div className={styles.imageContainer}>
                <span className={styles.fileName} style={{ maxWidth }}>
                  {file.name}
                </span>
                {onDeleteFile ? (
                  <DeleteIcon
                    className={styles.cancelIcon}
                    id={`${PAGE_NAME}_AttachedFiles_deleteIcon`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteFile(file);
                    }}
                  />
                ) : (
                  <SaveAltIcon className={styles.downloadIcon} />
                )}
                {deletingFiles?.find((item) => item.id === file.id) && (
                  <div className={styles.deleteFile}>Deleting..</div>
                )}
              </div>
            </div>
          </Grid>
        ))}

      {addingFiles?.map((addingFile, index) => (
        <Grid
          item
          key={`${addingFile.name}_${index}`}
          className={styles.attachment}
          style={{ marginLeft: 20 }}
        >
          <div id={`${PAGE_NAME}_addedFile`} className={styles.uploadingFile}>
            <LoadingIndicator width={60} />
            <span className={styles.uploadingLabel}>{firstWords(addingFile.name)}</span>
            {secondWords(addingFile.name) && (
              <span className={styles.uploadingLabel}>{secondWords(addingFile.name)}</span>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

CommentAttachmentArea.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  addingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  deletingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  maxWidth: PropTypes.number,
  onDeleteFile: PropTypes.func,
};

CommentAttachmentArea.defaultProps = {
  files: [],
  addingFiles: [],
  deletingFiles: [],
  onDeleteFile: undefined,
  maxWidth: undefined,
};
