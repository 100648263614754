import { isEmpty, orderBy } from 'lodash';
import { PLATFORM_TYPE_FIELDS, WORKFLOW_TYPE_FIELD } from './constants';
import { userStore } from '@/services/store';
import { WORKFLOW_CONFIGS } from '@/services/constants';

export const getWorkflowConfigList = () => {
  const workflowConfigList = Object.entries(WORKFLOW_CONFIGS).map(([workflow, config]) => ({
    ...config,
    workflow,
    displayName: config.title,
    name: `${workflow}Config`,
    path: [config.category],
  }));
  const workflowTreeList = makeWorkflowTree(workflowConfigList);
  return { workflowConfigList, workflowTreeList };
};

const isEmptyValue = (value) =>
  (value !== 0 && !value) || (typeof value === 'object' && isEmpty(value));

export function getValidFields(obj) {
  if (typeof obj !== 'object' || isEmptyValue(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(getValidFields).filter((item) => !isEmptyValue(item));
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const validValue = getValidFields(value);

    if (key !== WORKFLOW_TYPE_FIELD && !isEmptyValue(validValue)) {
      if (PLATFORM_TYPE_FIELDS.includes(key)) {
        acc[key] = userStore.platformTypes.find(({ id }) => id === validValue)?.name || validValue;
        acc.PlatformId = validValue;
      } else acc[key] = validValue;
    }
    return acc;
  }, {});
}

export function getWorkflowFiles(workflowName, config) {
  if (workflowName && !isEmptyValue(config)) {
    if (workflowName.includes('Case')) {
      const filename = config.Create?.UpdatePdp?.Attachment;
      if (filename) return [{ id: `${filename}`, name: filename, hasWorkflowContent: true }];
    }
    const attachments = WORKFLOW_CONFIGS[workflowName]?.attachments;
    return attachments?.reduce((acc, attachedNameField) => {
      const filename = config[attachedNameField];
      if (!filename) return acc;
      const fieldAttachment = Array.isArray(filename)
        ? filename
            .filter((name) => name)
            .map((name) => ({ id: `${name}`, name, hasWorkflowContent: true }))
        : [
            {
              id: `${filename}`,
              name: filename,
              hasWorkflowContent: true,
            },
          ];
      return acc.concat(fieldAttachment);
    }, []);
  }
  return null;
}

const sortWorkflows = (workflowsList) => {
  const sortedWorkflows = orderBy(workflowsList, 'name', 'asc');
  return sortedWorkflows.map((category) => ({
    ...category,
    children: category.children?.length > 0 ? sortWorkflows(category.children) : [],
  }));
};

export const makeWorkflowTree = (workflowsList, isExpanded = false) => {
  const result = { children: [] };
  workflowsList.forEach((workflow) => {
    let currentCategory = result;
    workflow.path.forEach((name, index) => {
      const nextCategory = currentCategory.children.find((item) => item.workflow === name);
      if (!nextCategory) {
        const newCategory = { workflow: name, children: [], isExpanded };
        currentCategory.children.push(newCategory);
        currentCategory = newCategory;
      } else {
        currentCategory = nextCategory;
      }
      if (index === workflow.path.length - 1) currentCategory.children.push(workflow);
    });
  });
  return sortWorkflows(result.children);
};
