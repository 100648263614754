import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Popover, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, AssignLabel, Button } from '@/components';
import './TypeaheadDropdownV2.css';

const PAGE_NAME = 'TypeaheadDropdownV2';

const useStyles = makeStyles({
  button: {
    paddingLeft: '0 !important',
    paddingRight: '22px !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
  },
});
export const TypeaheadDropdownV2 = ({
  id,
  label,
  selectedValue: values,
  renderValue,
  options,
  hasSelectAllClear,
  classes,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const styles = useStyles();

  const handleOpen = (target) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearch('');
  };

  const handleChange = (value) => {
    onChange(value);
  };

  const filteredOptions = useMemo(() => {
    const result = [];
    options.forEach((option) => {
      if (search) {
        const value = option.label;
        const startIndex = value?.toLowerCase().indexOf(search.toLowerCase().trim());
        if (startIndex !== -1) {
          result.push({ ...option, match: [startIndex, startIndex + search.length] });
        }
      } else {
        result.push({ ...option, match: [0, 0] });
      }
    });
    return result;
  }, [options, search]);

  const selectedFilteredOptions = filteredOptions
    .filter((option) => values.includes(option.value))
    .map(({ value }) => value);

  const handleDropDownKeyDownProcess = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.stopPropagation();
      handleOpen(event.currentTarget);
    }
  };

  return (
    <>
      <div
        id={id}
        tabIndex={0}
        role="button"
        onKeyDown={handleDropDownKeyDownProcess}
        className={cx(
          'typeaheadV2',
          classes?.container,
          { selected: Boolean(anchorEl) },
          { typeaheadV2__selectedContainer: values.length > 0 },
        )}
        onClick={(event) => {
          handleOpen(event.currentTarget);
        }}
      >
        {renderValue ? (
          renderValue(values, label)
        ) : (
          <Typography id={`${PAGE_NAME}_value`}>{values}</Typography>
        )}
        <div className={cx('typeaheadV2__moreIcon', classes?.svgIcon, Boolean(anchorEl) && 'open')}>
          <ExpandMoreIcon />
        </div>
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{ paper: 'typeaheadV2__paper' }}
      >
        <div className="typeaheadV2__popoverContent">
          {(filteredOptions.length === 0 || hasSelectAllClear) && (
            <Typography variant="subtitle1">{label}</Typography>
          )}
          <TextField
            value={search}
            placeholder="Search members"
            autoFocus
            classes={{ root: 'typeaheadV2__searchMembers' }}
            InputProps={{
              classes: {
                root: 'typeaheadV2_inputRoot',
                input: 'typeaheadV2_inputInput',
                notchedOutline: 'typeaheadV2_inputNotchedOutline',
                focused: 'typeaheadV2_inputFocused',
              },
            }}
            onChange={(e) => setSearch(e.target.value)}
            id={`${PAGE_NAME}_Search`}
          />
          {hasSelectAllClear && filteredOptions.length > 0 && (
            <div className="typeaheadV2__buttonContainer">
              <Button
                id="btn-selectall-options"
                size="small"
                variant="tertiary"
                classes={{ root: styles.button }}
                onClick={() => handleChange(filteredOptions.map(({ value }) => value))}
              >
                Select All
              </Button>
              <Button
                id="btn-clear-options"
                size="small"
                variant="tertiary"
                disabled={!selectedFilteredOptions.length}
                classes={{ root: styles.button }}
                onClick={() =>
                  handleChange(values.filter((o) => !selectedFilteredOptions.includes(o)))
                }
              >
                Clear
              </Button>
            </div>
          )}
          {filteredOptions.length > 0 ? (
            <div
              className="typeaheadV2__listWrapper2"
              style={{ paddingRight: filteredOptions.length > 5 ? 4 : 20 }}
            >
              {filteredOptions.map(({ value: v, label, type, email, match }) => (
                <AssignLabel
                  key={v}
                  isEditable
                  checked={values.includes(v)}
                  name={label}
                  type={type}
                  email={email}
                  match={match}
                  onChange={(checked) =>
                    handleChange(checked ? values.concat(v) : values.filter((o) => o !== v))
                  }
                />
              ))}
            </div>
          ) : (
            <div
              className="typeaheadV2__noMatchingWrapper"
              id={`${PAGE_NAME}_noMatching_container`}
            >
              <SearchIcon className="typeaheadV2__noMatchingIcon" />
              <Typography variant="h6">No Matching Criteria</Typography>
              <Typography variant="body2" className="typeaheadV2__noMatchingLabel">
                Please try another search term.
              </Typography>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};
TypeaheadDropdownV2.propTypes = {
  hasSelectAllClear: PropTypes.bool,
};

TypeaheadDropdownV2.defaultProps = {
  hasSelectAllClear: false,
};

export default TypeaheadDropdownV2;
