import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  submitEditRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  submitEditContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flex: 1,
  },
  submitEditWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    borderRadius: '24px',
    backgroundColor: '#fff',
    '&::-webkit-scrollbar-track': {
      marginTop: 16,
      marginBottom: 16,
    },
  },
  submitEditWrapperDark: {
    backgroundColor: '#F0F2F7',
  },
  submitEditWrapperError: {
    border: `1px solid ${theme.palette.ui.error} !important`,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  fullWidth: {
    flex: 1,
  },
  submitEdit: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
    '& .MuiInput-root': {
      height: '46px',
      marginTop: '0px',
      borderRadius: '27px',
      backgroundColor: '#F0F2F7',
      border: '1px solid #F0F2F7',
      padding: '10px 24px',
      fontFamily: 'Open Sans !important',
      fontSize: '16px !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      letterSpacing: '0.15px !important',
      color: `${theme.palette.secondary.darkBlue} !important`,
      '&:before': {
        borderWidth: '0px !important',
      },
      '&:after': {
        borderWidth: '0px !important',
      },
      '& .MuiSvgIcon-root': {
        color: `${theme.palette.neutrals.greyMed} !important`,
        marginRight: '-16px',
        '&:hover': {
          color: `${theme.palette.secondary.darkBlue} !important`,
        },
      },
    },
  },
  submitEditMultiline: {
    '& .MuiInput-root': {
      height: 'fit-content',
    },
  },
  textFieldLight: {
    '& .MuiInput-root': {
      backgroundColor: '#fff',
      border: `1px solid #fff`,
    },
  },
  divDark: {
    backgroundColor: '#fff',
    border: `1px solid #fff`,
  },
  attachIcon: {
    color: '#afafaf !important',
    '&:hover': {
      cursor: 'pointer',
      color: '#081949 !important',
    },
  },
  attachButtonContainer: {
    position: 'sticky',
    width: 24,
    height: 24,
    top: 'calc(50% - 12px)',
  },
  attachButton: {
    background: 'transparent !important',
    minWidth: '0px !important',
    width: '0px !important',
    padding: '0px !important',
  },
  attachedFiles: {
    padding: '0px 24px 24px',
  },
  leftAlignItem: {
    flexDirection: 'row !important',
  },
  attachment: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    paddingTop: '8px',
    paddingRight: '12px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageContainer: {
    position: 'relative',
    height: 64,
    width: 64,
    minHeight: 64,
    minWidth: 64,
  },
  imgPreview: {
    width: 62,
    height: 62,
    border: '1px solid #E9EDF0',
    borderRadius: 12,
  },
  storybookPreview: {
    width: 64,
    height: 64,
    border: '1px solid #E9EDF0',
    borderRadius: 12,
  },
  deleteFileIcon: {
    position: 'absolute',
    right: -8,
    top: -8,
    zIndex: 2,
    cursor: 'pointer',
    color: '#929398 !important',
    '&:hover': {
      color: `${theme.palette.secondary.darkBlue} !important`,
    },
  },
  fileName: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.primary.purple,
    overflowWrap: 'anywhere',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    height: 'fit-content',
    display: '-webkit-box',
  },
  hoveredFile: {
    position: 'absolute',
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    width: 64,
    height: 64,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '24px',
  },
  downloadIcon: {
    color: '#fff',
  },
  outerBorder: {
    border: '1px solid #d3d3d3',
  },
  customFileInput: {
    display: 'none',
    '&::-webkit-file-upload-button': {
      visibility: 'hidden',
    },
    '&::file-selector-button': {
      width: '0px',
      visibility: 'hidden',
    },
  },
  assignWrapper: {
    display: 'flex',
  },

  assignContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 30px)',
    height: '44px !important',
    border: '1px solid #d3d3d3',
    borderRadius: '58px !important',
    padding: '0px 12px 0px 18px !important',
    backgroundColor: '#fff',
  },

  moreIcon: {
    color: theme.palette.primary.purple,
  },

  assignValue: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    color: theme.palette.secondary.darkBlue,
  },
  flexNone: {
    flex: 'none',
  },
  errorLabel: {
    marginTop: '4px !important',
    color: `${theme.palette.ui.error} !important`,
  },
}));
