import PropTypes from 'prop-types';
import cx from 'clsx';
import MuiChip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { AmazonStatus, HelpDeskStatus, MemberStatus } from '@/types';
import { getAmazonCaseStatusColor } from '@/utils';

const useStyles = makeStyles((theme) => ({
  chip: {
    width: 'fit-content !important',
    height: '22px !important',
    '& .MuiChip-label': {
      ...theme.typography.buttonExtraSmall,
      padding: '4px 8px !important',
    },
  },
  notStarted: {
    backgroundColor: '#ffce84 !important',
    color: `${theme.palette.neutrals.darkBlue}  !important`,
  },
  closed: {
    backgroundColor: `${theme.palette.secondary.darkBlue} !important`,
    color: `${theme.palette.neutrals.white} !important`,
  },
  unassigned: {
    backgroundColor: `${theme.palette.secondary.lightPurple} !important`,
    color: `${theme.palette.neutrals.darkBlue} !important`,
  },
  attention: {
    backgroundColor: `${theme.palette.secondary.salmon} !important`,
    color: `${theme.palette.secondary.darkBlue}  !important`,
  },
  pending: {
    backgroundColor: '#ffce84 !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  failed: {
    backgroundColor: `${theme.palette.ui.error} !important`,
    color: `${theme.palette.neutrals.white}  !important`,
  },
  answered: {
    backgroundColor: '#aae4ad !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  canceled: {
    backgroundColor: `${theme.palette.neutrals.lightGrey} !important`,
    color: `${theme.palette.neutrals.grey} !important`,
  },
}));

export const AmazonChip = ({ status }) => {
  const styles = useStyles();
  return (
    <MuiChip
      label={status}
      id="MuiChip"
      classes={{
        root: styles.chip,
      }}
      style={getAmazonCaseStatusColor(status)}
    />
  );
};

const Chip = ({ variant, label, width, height, ...props }) => {
  const styles = useStyles();

  return (
    <MuiChip
      label={label || variant}
      id="MuiChip"
      classes={{
        root: cx(styles.chip, {
          [styles.notStarted]: variant === HelpDeskStatus.notStarted,
          [styles.attention]:
            variant === AmazonStatus.attention ||
            variant === HelpDeskStatus.attention ||
            variant === HelpDeskStatus.blocked ||
            variant === MemberStatus.admin ||
            variant === AmazonStatus.reOpened,
          [styles.pending]:
            variant === MemberStatus.invited ||
            variant === HelpDeskStatus.inProgress ||
            variant === HelpDeskStatus.todo ||
            variant === HelpDeskStatus.open ||
            variant === HelpDeskStatus.needs ||
            variant === MemberStatus.contributor ||
            variant === AmazonStatus.pending,
          [styles.failed]: variant === HelpDeskStatus.failed,
          [styles.answered]:
            variant === AmazonStatus.answered ||
            variant === HelpDeskStatus.resolved ||
            variant === MemberStatus.active,
          [styles.canceled]:
            variant === HelpDeskStatus.canceled || variant === MemberStatus.inactive,
          [styles.unassigned]:
            variant === AmazonStatus.unassigned || variant === MemberStatus.assignable,
          [styles.closed]: variant === AmazonStatus.closed || variant === HelpDeskStatus.closed,
        }),
      }}
      {...props}
      style={{ width, height }}
    />
  );
};

Chip.propTypes = {
  variant: PropTypes.oneOf([
    AmazonStatus.unassigned,
    AmazonStatus.reOpened,
    AmazonStatus.attention,
    AmazonStatus.answered,
    AmazonStatus.pending,
    AmazonStatus.closed,

    HelpDeskStatus.notStarted,
    HelpDeskStatus.open,
    HelpDeskStatus.blocked,
    HelpDeskStatus.resolved,
    HelpDeskStatus.failed,
    HelpDeskStatus.canceled,
    HelpDeskStatus.closed,
    HelpDeskStatus.todo,
    HelpDeskStatus.needs,

    MemberStatus.active,
    MemberStatus.inactive,
    MemberStatus.invited,
    MemberStatus.contributor,
    MemberStatus.admin,
    MemberStatus.assignable,
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  height: PropTypes.number,
};

Chip.defaultProps = {
  variant: AmazonStatus.unassigned,
  label: '',
  width: null,
  height: null,
};

export default Chip;
