import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Popover } from '@mui/material';
import {
  Logout as LogoutIcon,
  SettingsOutlined as SettingsOutlinedIcon,
} from '@mui/icons-material';
import { theme } from '@/config/theme';
import { ContextMenuItem } from '@/components';
import { BASE_ROUTES, UrlSearch } from '@/types';
import { useSettingsStore } from '@/pages/settings/stores/useSettingsStore';
import { clearToken } from '@/utils';

export const SettingsPopopver = ({ anchorEl, handleClose }) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { routerStore } = useSettingsStore();

  return (
    <Popover
      id={`TopNavigation_Settings_Popover`}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      PaperProps={{
        style: {
          width: 164,
          marginTop: 7,
          marginLeft: 8,
          borderRadius: '16px',
          border: `1px solid ${theme.palette.neutrals.lightGrey}`,
          boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      <ContextMenuItem
        icon={<SettingsOutlinedIcon />}
        small={false}
        onClick={() => {
          handleClose();
          navigate(
            `${BASE_ROUTES.settings}/?${UrlSearch.menu}=profile${routerStore.getMainParams('&')}`,
          );
        }}
        id="topNavigation_settings_profile_menuitem"
      >
        Settings
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() => {
          clearToken();
          logout({ returnTo: window.location.origin });
        }}
        icon={<LogoutIcon />}
        small={false}
        id="topNavigation_settings_logout_menuitem"
      >
        Logout
      </ContextMenuItem>
    </Popover>
  );
};
