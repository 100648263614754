import { Config } from '@/config';
import * as fetchWithAuth from './fetchWithAuth';
import { TicketTypes } from '@/utils/constants';

function parsePickListFromResponse(type, obj) {
  if (type === TicketTypes) {
    return Object.keys(obj).map((name) => ({
      id: name,
      name,
    }));
  }
  return Object.keys(obj).map((key) => ({
    id: obj[key],
    name: key.trim(),
  }));
}

export const getPickList = async (type) => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Picklist?type=${type}`)
    .then((resp) => parsePickListFromResponse(type, resp.items));
};

function parsePlatformTypesFromResponse(obj) {
  return Object.entries(obj).map(([id, name]) => ({
    id: +id,
    name,
  }));
}

export const getPlatformTypes = async () => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Picklist/platforms`)
    .then((resp) => parsePlatformTypesFromResponse(resp));
};

function parseEventTypesFromResponse(eventName) {
  return { label: eventName, value: eventName };
}

export const getEventTypes = async () => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Picklist/events`)
    .then((resp) => resp.map(parseEventTypesFromResponse));
};
