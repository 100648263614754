import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConfirmDialog, TextLink, Typography } from '@/components';
import { useCreateTaskStore } from '@/pages/create/stores/useCreateTaskStore';
import { formatLocalDateTime } from '@/utils';
import { useDetailStore } from '../../stores/useDetailStore';
import { BASE_ROUTES, UrlSearch } from '@/types';
import { Config } from '@/config';

const useStyles = makeStyles((theme) => ({
  taskDetailMoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    width: 42,
    height: 24,
    minWidth: 46,
    border: '2px solid transparent',
    borderRadius: 70,
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #E2E5EB',
    },
  },
  clicked: {
    border: `2px solid ${theme.palette.primary.purple}`,
  },
  point: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    background: theme.palette.neutrals.grey,
  },
  hyperLink: {
    color: theme.palette.primary.purple,
  },
  clickedPoint: {
    background: theme.palette.primary.purple,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  deleteButton: {
    color: `${theme.palette.ui.error} !important`,
    fontWeight: `700 !important`,
  },
  removeIcon: {
    fontSize: '14px !important',
  },
  cancelDialog: {
    width: '617px',
  },
}));

const PAGE_NAME = 'TaskDetailPopover';

export const TaskDetailPopover = observer(({ isRequest, onRefresh }) => {
  const classes = useStyles();
  const [anchorEl, setAnchoreEl] = useState(null);
  const createTaskStore = useCreateTaskStore();
  const { detailStore } = useDetailStore();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const handleDeleteTask = async () => {
    await detailStore.deleteTask(detailStore.ticketId);
    onRefresh();
  };

  return (
    <>
      <div
        className={cx(classes.taskDetailMoreWrapper, {
          [classes.clicked]: Boolean(anchorEl),
        })}
        onClick={(e) => setAnchoreEl(e.currentTarget)}
        id={`${PAGE_NAME}_MoreButton`}
      >
        <div
          className={cx(classes.point, {
            [classes.clickedPoint]: Boolean(anchorEl),
          })}
        />
        <div
          className={cx(classes.point, {
            [classes.clickedPoint]: Boolean(anchorEl),
          })}
        />
        <div
          className={cx(classes.point, {
            [classes.clickedPoint]: Boolean(anchorEl),
          })}
        />
      </div>

      <Popover
        anchorEl={anchorEl}
        id={PAGE_NAME}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchoreEl(null)}
        PaperProps={{
          style: {
            marginTop: 8,
            padding: '20px 24px',
            borderRadius: 26,
            boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <div id={`${PAGE_NAME}_Container`} className={classes.content}>
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            Details
          </Typography>
          <div className={classes.item}>
            <Typography variant="caption-bold">Account:</Typography>

            <TextLink
              href={`${Config.ADMIN_APP_LINK}${BASE_ROUTES.organizations}?${UrlSearch.organization}=${detailStore.organizationId}`}
            >
              <Typography
                variant="caption-bold"
                id={`${PAGE_NAME}_accountName`}
                className={classes.hyperLink}
              >
                {detailStore.accountName}
              </Typography>
            </TextLink>
          </div>
          <div className={classes.item}>
            <Typography variant="caption-bold">Platform(s):</Typography>
            <Typography variant="caption" id={`${PAGE_NAME}_platforms`}>
              {createTaskStore.orgPlatformList.join(', ') || 'None'}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption-bold">Service:</Typography>
            <Typography variant="caption" id={`${PAGE_NAME}_service`}>
              {detailStore.serviceType ?? 'None'}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption-bold">Creator:</Typography>
            <Typography variant="caption" id={`${PAGE_NAME}_creator`}>
              {detailStore.createdBy?.name}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption-bold">Creation Date:</Typography>
            <Typography variant="caption" id={`${PAGE_NAME}_creationDate`}>
              {formatLocalDateTime(detailStore.createdDate, 'MM/DD/YYYY')}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption-bold">Updated Date:</Typography>
            <Typography variant="caption" id={`${PAGE_NAME}_updatedDate`}>
              {formatLocalDateTime(detailStore.lastUpdated, 'MM/DD/YYYY')}
            </Typography>
          </div>
          {detailStore.status === 'Resolved' && detailStore.resolutionDate && (
            <div className={classes.item}>
              <Typography variant="caption-bold">Resolved Date:</Typography>
              <Typography variant="caption" id={`${PAGE_NAME}_resolvedDate`}>
                {formatLocalDateTime(detailStore.resolutionDate, 'MM/DD/YYYY')}
              </Typography>
            </div>
          )}
          {detailStore.estimatedCompletion && (
            <div className={classes.item}>
              <Typography variant="caption-bold">Estimated Completion Date:</Typography>
              <Typography variant="caption" id={`${PAGE_NAME}_ecd`}>
                {formatLocalDateTime(detailStore.estimatedCompletion, 'MM/DD/YYYY')}
              </Typography>
            </div>
          )}
          {/* <div style={{ marginTop: 8 }}>
            <Button
              variant="secondary"
              size="small"
              startIcon={<RemoveIcon className={classes.removeIcon} />}
              className={classes.deleteButton}
              onClick={() => setDeleteConfirmOpen(true)}
            >
              Delete
            </Button>
          </div> */}
        </div>
      </Popover>

      {deleteConfirmOpen && (
        <ConfirmDialog
          id={`${PAGE_NAME}_cancelConfirmDialog`}
          open
          title="Are you sure?"
          description={`Deleting this ${
            isRequest ? 'request' : 'task'
          } will cancel all of the associated subtasks underneath it.`}
          okText={isRequest ? 'Delete Request' : 'Delete Task'}
          cancelText="Cancel"
          onCancel={() => setDeleteConfirmOpen(false)}
          onOk={handleDeleteTask}
          containerClassName={classes.cancelDialog}
        />
      )}
    </>
  );
});
