import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStore, userStore } from '@/services/store';
import { BASE_ROUTES, UrlSearch } from '@/types';
import { useSettingsStore } from './stores/useSettingsStore';
import SettingsMenu from './SettingsMenu';
import MyProfile from './MyProfile';
import Members from './Members';
import { SETTINGS_MENU } from './constants';
import OrganizationSettings from './OrganizationSettings/OrganizationSettings';
import DeveloperSettings from './DeveloperSettings/DeveloperSettings';

import './SettingsPage.css';

const PAGE_NAME = 'SettingsPage';

const SettingsPage = () => {
  const { routerStore, membersStore } = useSettingsStore();
  const [selectedTabName, setSelectedTabName] = useState(SETTINGS_MENU.myProfilePage);
  const isAdminUser = userStore.currentUser.isAdmin;

  useEffect(() => {
    membersStore.fetchAllAccounts(userStore.organizationId);
  }, [userStore.organizationId, userStore.isRefresh]);

  useEffect(() => {
    const tabName = routerStore.getSearchParam(UrlSearch.menu);
    if (
      tabName === SETTINGS_MENU.organizationPage ||
      tabName === SETTINGS_MENU.developerPage ||
      tabName === SETTINGS_MENU.membersPage
    ) {
      setSelectedTabName(tabName);
    } else {
      setSelectedTabName(SETTINGS_MENU.myProfilePage);
    }
    routerStore.setCurrentPage(BASE_ROUTES.settings);
  }, []);

  const handleSelectMenu = (value) => {
    setSelectedTabName(value);
    routerStore.setSearchParam(UrlSearch.menu, value);
  };

  return (
    <div id={`${PAGE_NAME}_container`} className="main-page-container">
      <div id={`${PAGE_NAME}_layout_div`} className="main-page-layout">
        <SettingsMenu
          selectedTabName={selectedTabName}
          setSelectedTabName={handleSelectMenu}
          isAdminUser={isAdminUser}
        />
        {selectedTabName === SETTINGS_MENU.myProfilePage && <MyProfile />}
        {selectedTabName === SETTINGS_MENU.organizationPage && isAdminUser && (
          <OrganizationSettings />
        )}
        {selectedTabName === SETTINGS_MENU.developerPage && userStore.isWFLAdminUser && (
          <DeveloperSettings />
        )}
        {selectedTabName === SETTINGS_MENU.membersPage && isAdminUser && <Members />}
      </div>
    </div>
  );
};

export default withStore(observer(SettingsPage));
