import { TextLink } from '@/components';
import CatImg from '@/assets/images/cat.png';
import HelpdeskColorLightIcon from '@/assets/logo/helpdesk-color-light.svg';
import styles from './ForbiddenPage.module.css';

const ForbiddenPage = ({ title }) => {
  return (
    <div className={styles.forbiddenPage}>
      <div id="ForbiddenPage_container" className={styles.forbiddenContainer}>
        <span className={styles.sorry}>Oops!</span>
        <span className={styles.errorText}>{title}</span>
        <span className={styles.errorText}>
          please go back to&nbsp;
          <TextLink href="/" id="homepage_link" className={styles.infoLink}>
            HelpDesk
          </TextLink>
        </span>
        <img src={CatImg} className={styles.catImg} alt="empty state" />
        <div className={styles.jinxContainer}>
          <TextLink href="/" id="homepage_link" className={styles.infoLink}>
            <img src={HelpdeskColorLightIcon} alt="empty state" />
          </TextLink>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenPage;
