export const ContentType = {
  manual: 'manual',
  auto: 'auto',
};

export const ImportStatus = {
  ready: 'ready',
  importing: 'importing',
  success: 'success',
  failed: 'failed',
  done: 'done',
};

export const AnchorOrigin = {
  auto: 'auto',
  bottom: 'bottom',
  top: 'top',
};

export const ServiceTypes = {
  single: 'single',
  multiple: 'multiple',
};
