import { makeStyles } from '@mui/styles';

import Avatar from './Avatar';
import Typography from '../Typography/Typography';

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    width: 'fit-content !important',
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  name: {
    fontWeight: 700,
    color: theme.palette.neutrals.grey,
  },
  description: {
    color: theme.palette.neutrals.greyBlueDark,
  },
}));

export const AvatarTooltip = ({ name, variant, companyName, roleName }) => {
  const classes = useStyles();

  return (
    <div className={classes.tooltipContainer}>
      <Avatar name={name} width={30} variant={variant} />
      <div className={classes.descriptionWrapper}>
        <Typography variant="subtitle1" className={classes.name}>
          {name}
        </Typography>
        {companyName && (
          <Typography variant="body1" className={classes.description}>
            {companyName}
          </Typography>
        )}
        {roleName && (
          <Typography variant="body1" className={classes.description}>
            {roleName}
          </Typography>
        )}
      </div>
    </div>
  );
};
