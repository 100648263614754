import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InputAdornment } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

import { fetchDataWithToken } from '@/api/fetchWithAuth';
import { VisibilityIcon, VisibilityOffIcon } from '@/assets/icons';
import { withStore } from '@/services/store';
import { Button, Typography, TextField, LoadingIndicator, Switch, Tooltip } from '@/components';
import { userStore } from '@/services/store/UserStore';
import { useSettingsStore } from '../stores/useSettingsStore';
import { getToken } from '@/utils';

import styles from './DeveloperSettings.module.css';

const PAGE_NAME = 'DeveloperSettings';

const DeveloperSettings = () => {
  const { organizationStore } = useSettingsStore();
  const currentUser = userStore.currentUser;
  const [isEditing, setIsEditing] = useState(false);
  const [isVisibilityKey, setIsVisibilityKey] = useState(false);
  const [rotate, setRotate] = useState(0);
  const apiKey = getToken();

  const handleSavePerson = async () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (organizationStore.isReceiveAPIKey) setIsVisibilityKey(false);
  }, [organizationStore.isReceiveAPIKey]);

  const handleGetNewAPIKey = async () => {
    await fetchDataWithToken();
    organizationStore.isCopied = false;
    setRotate(rotate + 1);
  };

  if (!currentUser.id) return <LoadingIndicator fullScreen />;
  return (
    <div className={styles.accountPageContainer}>
      <div className={styles.developerSettings}>
        <Typography variant="h5">Developer Settings</Typography>
        <div id={`${PAGE_NAME}_headerContainer`} className={styles.developerHeader}>
          <div id={`${PAGE_NAME}_descriptionContainer`} className={styles.apiDescription}>
            <Typography variant="h6">Secret</Typography>
            <Typography variant="body1">Receive and rotate API keys.</Typography>
          </div>

          {!isEditing && (
            <Button
              size="large"
              variant="secondary"
              onClick={() => setIsEditing(true)}
              id={`${PAGE_NAME}_editSettingsButton`}
              classes={{ root: styles.editButton }}
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <div>
              <Button
                size="large"
                variant="primary"
                onClick={handleSavePerson}
                id={`${PAGE_NAME}_saveButton`}
              >
                Save
              </Button>
              <Button
                size="large"
                variant="tertiary"
                onClick={() => setIsEditing(false)}
                id={`${PAGE_NAME}_cancelButton`}
              >
                Cancel
              </Button>
            </div>
          )}
          <div className={styles.receiveAPISwitch}>
            <Switch
              label="Receive API Key"
              checked={organizationStore.isReceiveAPIKey}
              id={`${PAGE_NAME}_switchItem`}
              disabled={!isEditing}
              onChange={(event) => organizationStore.setIsReceiveAPIKey(event.target.checked)}
            />
          </div>
        </div>

        {isEditing && organizationStore.isReceiveAPIKey && (
          <div className={styles.apiKeyContainer}>
            <TextField
              label="Access ID"
              value="MscTtv7wTsu7StG189Av1HnFOCWjdy"
              inactive
              disabled
              style={{ width: 466 }}
            />
            <div className={styles.apiKeyContent}>
              <TextField
                type={isVisibilityKey ? 'text' : 'password'}
                label="API Key"
                value={apiKey}
                inactive
                disabled
                className={styles.apiValue}
                endAdornment={
                  <InputAdornment
                    position="start"
                    classes={{
                      root: styles.visibilityIcon,
                    }}
                    onClick={() => setIsVisibilityKey(!isVisibilityKey)}
                  >
                    {!isVisibilityKey && <VisibilityIcon />}
                    {isVisibilityKey && <VisibilityOffIcon />}
                  </InputAdornment>
                }
              />
              <Tooltip
                title={organizationStore.isCopied ? 'Key Copied to Clipboard' : 'Copy Key'}
                arrow
                placement="top"
              >
                <div
                  className={styles.copyButton}
                  onClick={() => organizationStore.copyAPIKey()}
                  id={`${PAGE_NAME}_copyKey`}
                >
                  <LinkIcon className={styles.copyIcon} />
                </div>
              </Tooltip>
            </div>
            <Button
              size="large"
              variant="primary"
              onClick={handleGetNewAPIKey}
              id={`${PAGE_NAME}_saveButton`}
              style={{ width: 97 }}
            >
              Rotate
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStore(observer(DeveloperSettings));
