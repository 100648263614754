import { userStore } from '@/services/store';
import {
  AmazonBrandRegistry,
  AmazonSellerCentral,
  AmazonVendorCentral,
  GeneralPlatform,
  GoogleAds,
} from '@/services/AutoWorkflow/PlatformLogins';

export const PLATFORMS = {
  none: 0,
  AmazonSellerCentral: 1,
  AmazonVendorCentral: 2,
  AmazonBrandRegistry: 4,
  GoogleAds: 6,
};

const PLATFORM_MAPS = {
  [PLATFORMS.AmazonSellerCentral]: AmazonSellerCentral,
  [PLATFORMS.AmazonVendorCentral]: AmazonVendorCentral,
  [PLATFORMS.AmazonBrandRegistry]: AmazonBrandRegistry,
  [PLATFORMS.GoogleAds]: GoogleAds,
};

export function getPlatformLoginForm(platformId) {
  return {
    name: userStore.platformTypes.find(({ id }) => id === platformId)?.name,
    form: PLATFORM_MAPS[platformId] || GeneralPlatform,
  };
}
