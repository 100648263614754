import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';

import { parseCaseMessage } from '@/api/detail';
import { attachmentApi, createApi, detailApi, organizationApi, ticketApi } from '@/api';
import {
  formatUTCDateTime,
  getAssignFromId,
  getCurrentLocalDateTime,
  getStateId,
  mapToUserOption,
  showErrorNotification,
  showSuccessNotification,
  sortMembers,
} from '@/utils';
import {
  AutomationStatusColors,
  HelpDeskStatus,
  NotificationText,
  ObjectType,
  Unassigned,
} from '@/types';
import { ATTACHMENTS_FILED } from '@/services/constants';
import { getWorkflowFiles } from '@/utils/workflow';
import { setError } from '@/utils/errors';
import { userStore } from '@/services/store';

const DefaultComment = { title: '', attachments: [] };
const DefaultQuestion = { title: '', attachments: [], assignTo: Unassigned.value };

export class DetailContentStore {
  isLoading = true;
  parsedData = {};
  isCopied = false;
  draftComment = DefaultComment;
  accounts = [];
  addingFiles = [];
  deletingFiles = [];
  draftQuestion = DefaultQuestion;
  assigns = [];
  assignOptions = [];

  constructor(workListStore) {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action,

      reset: action,
      parsedData: observable,
      fetchDetailContent: action,

      accounts: observable,
      setAccounts: action,
      isCopied: observable,
      copyLink: action,

      parentId: computed,
      ticketId: computed,
      requestId: computed,
      objectType: computed,
      updateContent: action,
      title: computed,
      description: computed,
      owner: computed,
      updateOwner: action,
      assign: computed,
      updateAssign: action,
      status: computed,
      updateStatus: action,
      externalStatus: computed,
      isPinned: computed,
      setPinned: action,
      priority: computed,
      accountName: computed,
      serviceType: computed,
      workflowConfig: computed,
      workflowConfigFiles: computed,
      automation: computed,
      isValidWorkflow: computed,
      remainingWork: computed,
      createdBy: computed,
      createdDate: computed,
      lastUpdated: computed,
      requestedByDate: computed,
      resolutionDate: computed,
      estimatedCompletion: computed,
      attachments: computed,
      assigns: observable,
      assignOptions: observable,
      ownerOptions: computed,
      canExecute: computed,

      discussions: computed,
      externalId: computed,
      caseMessages: computed,

      // NOTE: Comments
      addComment: action,
      addingFiles: observable,
      deletingFiles: observable,
      draftComment: observable,
      updateDraftComment: action,
      updateComment: action,
      deleteComment: action,

      // NOTE: Question
      draftQuestion: observable,
      updateDraftQuestion: action,
      updateQuestion: action,
    });

    this.workListStore = workListStore;
  }

  // NOTE: Get and Update status

  get parentId() {
    return this.parsedData.parentId;
  }

  get ticketId() {
    return this.parsedData.ticketId;
  }

  get requestId() {
    return this.parsedData.requestId;
  }

  get objectType() {
    return this.parsedData.objectType;
  }

  get title() {
    return this.parsedData.title;
  }

  get description() {
    return this.parsedData.description;
  }

  get owner() {
    return this.parsedData.owner;
  }

  get canExecute() {
    const { name, automationTime } = this.parsedData.automation ?? {};
    const localAutomationTime = moment(automationTime);
    const currentTime = moment();
    // Calculate difference in milliseconds
    const differenceInMilliseconds = currentTime.diff(localAutomationTime);
    const differenceInSeconds = moment.duration(differenceInMilliseconds).asSeconds();

    if (
      userStore.isWFLAdminUser &&
      name === 'Failed' &&
      automationTime &&
      differenceInSeconds > 30
    ) {
      const { rerun } =
        userStore.workflowConfigList.find(
          ({ workflow }) => workflow === this.parsedData.workflow,
        ) ?? {};
      return !!rerun;
    } else {
      return false;
    }
  }

  async executeTicket() {
    if (!this.ticketId) return;
    try {
      this.parsedData.automation = {
        ...this.parsedData.automation,
        name: 'Queued',
        color: AutomationStatusColors.Queued,
        automationTime: getCurrentLocalDateTime(),
      };
      const buildId = await detailApi.executeTicket(this.ticketId);

      runInAction(() => {
        showSuccessNotification(`Build Id ${buildId} is running!`);
      });
    } catch (err) {
      setError(err, false, 'Execute ticket failed');
      showErrorNotification(err.preview ?? err.message);
    }
  }

  async updateOwner(newOwnerId, isRequest = false) {
    const originalOwner = { ...this.parsedData.owner };
    this.parsedData.owner = getAssignFromId(newOwnerId, this.assigns);
    const result = await this.updateTicket(isRequest, 'Update ticket owner failed');
    if (!result) this.parsedData.owner = originalOwner;
  }

  get assign() {
    return this.parsedData.assign;
  }

  async updateAssign(newAssignId, isRequest = false) {
    const originalAssigner = { ...this.parsedData.owner };
    this.parsedData.assign = getAssignFromId(newAssignId, this.assigns);
    const result = await this.updateTicket(isRequest, 'Update ticket assigner failed');
    if (!result) this.parsedData.assign = originalAssigner;
  }

  get status() {
    return this.parsedData.state;
  }

  async updateStatus(newStatus, isRequest = false) {
    const originalStatus = this.parsedData.state;
    this.parsedData.state = newStatus;
    const result = await this.updateTicket(isRequest, 'Update ticket status failed');
    if (!result) {
      this.parsedData.state = originalStatus;
    } else if (
      isRequest ||
      newStatus === HelpDeskStatus.canceled ||
      newStatus === HelpDeskStatus.closed
    ) {
      await this.refreshLeftNav();
    }
  }

  get externalStatus() {
    return this.parsedData.externalStatus;
  }

  get isPinned() {
    return this.parsedData.isPinned;
  }

  setPinned(pinned) {
    this.parsedData.isPinned = pinned;
  }

  get priority() {
    return this.parsedData.priority;
  }

  get organizationId() {
    return this.parsedData.organizationId;
  }

  get accountName() {
    const matchedAccount = this.accounts.find((item) => item.id === this.parsedData.organizationId);
    return matchedAccount?.name ?? 'None';
  }

  get serviceType() {
    return this.parsedData.workflow || 'None';
  }

  get workflowConfig() {
    return this.parsedData.config;
  }

  get workflowConfigFiles() {
    return this.parsedData.configFiles;
  }

  get automation() {
    return this.parsedData.automation;
  }

  get isValidWorkflow() {
    return !!this.parsedData.config;
  }

  get remainingWork() {
    return this.parsedData.remainingWork;
  }

  get createdBy() {
    return this.parsedData.createdBy;
  }

  get createdDate() {
    return this.parsedData.createdDate;
  }

  get lastUpdated() {
    return this.parsedData.lastUpdated;
  }

  get requestedByDate() {
    return this.parsedData.requestedByDate;
  }

  get resolutionDate() {
    return this.parsedData.resolutionDate;
  }

  get estimatedCompletion() {
    return this.parsedData.estimatedCompletion;
  }

  get attachments() {
    return this.parsedData.attachments || [];
  }

  get discussions() {
    return this.parsedData.discussions;
  }

  get externalId() {
    return this.parsedData.externalId;
  }

  get caseMessages() {
    return this.parsedData.caseMessages ?? [];
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  async updateContent(data, isRequest = false) {
    const originalData = { ...this.parsedData };
    this.parsedData.title = data.title;
    this.parsedData.description = data.description;
    this.parsedData.priority = data.priority;
    this.parsedData.remainingWork = data.remainingWork;
    this.parsedData.estimatedCompletion = data.estimatedCompletion;
    const result = await this.updateTicket(
      isRequest,
      'Update ticket content failed',
      data.attachments,
    );
    if (!result) this.parsedData = originalData;
    return result;
  }

  // NOTE: Other processing
  async updateTicket(isRequest, warningTitle, newAttachments = null) {
    this.setIsLoading(true);
    let result = false;
    try {
      let attachmentUpdated = false;
      if (newAttachments && !isEqual(this.parsedData.attachments, newAttachments)) {
        const attachedDiscussionId = this.parsedData.attachedDiscussionId;
        if (!attachedDiscussionId) {
          const formData = new FormData();
          formData.append('comment', `[TicketId=${this.ticketId}]`);
          newAttachments.forEach((file) => {
            formData.append(ATTACHMENTS_FILED, file);
          });
          await ticketApi.addComment(this.ticketId, formData);
        } else {
          await this.parsedData.attachments
            .filter((file) => !newAttachments.find((item) => item.id === file.id))
            .reduce(
              (callback, attachment) =>
                callback.then(async () => {
                  await attachmentApi.deleteAttachment(attachment.id);
                }),
              Promise.resolve(),
            );
          await newAttachments
            .filter((file) => !file.id)
            .reduce(
              (callback, file) =>
                callback.then(async () => {
                  await attachmentApi.addAttachment(attachedDiscussionId, file);
                }),
              Promise.resolve(),
            );
          if (newAttachments.length === 0) {
            await ticketApi.deleteComment(attachedDiscussionId);
          }
        }
        attachmentUpdated = true;
      }

      const savingData = {
        title: this.title,
        description: this.description,
        estimatedCompletion: formatUTCDateTime(this.estimatedCompletion),
        ownedBy: this.owner.id,
        assignedTo: this.assign.id,
        priority: this.priority,
        stateId: getStateId(this.status),
        quantity: this.remainingWork,
      };
      await ticketApi.updateTicket(this.ticketId, savingData);

      runInAction(() => {
        if (attachmentUpdated) {
          if (isRequest) {
            this.refreshLeftNav();
          }
          this.fetchDetailContent(this.ticketId);
        } else {
          this.workListStore.updateTaskList(
            this.ticketId,
            this.status,
            this.assign,
            this.title,
            this.description,
            this.priority,
            this.remainingWork,
            this.estimatedCompletion,
          );
        }
        result = true;
      });
    } catch (err) {
      setError(err, false, warningTitle);
      showErrorNotification(warningTitle);
    }
    this.setIsLoading(false);
    return result;
  }

  async copyLink() {
    await navigator.clipboard.writeText(window.location.href);
    this.isCopied = true;
  }

  setAccounts(arrangedAccounts) {
    this.accounts = arrangedAccounts || [];
  }

  async refreshLeftNav() {
    await this.workListStore.fetchRequestDetail(this.parsedData.ticketId, false);
  }

  // NOTE: Comment
  async addComment(type, ticketId, comment, attachments) {
    this.setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('comment', comment || '');
      attachments.forEach((file) => {
        formData.append(ATTACHMENTS_FILED, file);
      });
      await ticketApi.addComment(ticketId, formData);
      if (type === ObjectType.issue) {
        await this.refreshLeftNav();
      }
      this.fetchDetailContent(this.ticketId);
      runInAction(() => {
        if (type === ObjectType.issue) {
          this.draftQuestion = DefaultQuestion;
        } else {
          this.draftComment = DefaultComment;
        }
      });
    } catch (err) {
      setError(err, false, NotificationText.addCommentError);
    }
    this.setIsLoading(false);
  }

  async remindTicket() {
    try {
      const result = await ticketApi.remindTicket(this.parsedData.ticketId);
      if (result)
        showSuccessNotification(`Notification was sent to ${this.parsedData.assign.email}`);
      else showErrorNotification(`Notification was not sent to ${this.parsedData.assign.email}`);
      return result;
    } catch (err) {
      setError(err, false, 'Notification remind failed');
    }
  }

  updateDraftComment(title = null, attachments = null) {
    if (title) {
      this.draftComment.title = title;
    }
    if (attachments) {
      this.draftComment.attachments = attachments;
    }
  }

  async updateComment(isRequest, discussionId, comment, addingFiles, removingFiles) {
    this.setIsLoading(true);
    try {
      await addingFiles.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.addAttachment(discussionId, file);
          }),
        Promise.resolve(),
      );
      await removingFiles.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.deleteAttachment(file.id);
          }),
        Promise.resolve(),
      );
      await ticketApi.updateComment(discussionId, comment);

      this.fetchDetailContent(this.ticketId);
    } catch (err) {
      setError(err, false, NotificationText.updateCommentError);
      showErrorNotification(NotificationText.updateCommentError);
    }

    this.setIsLoading(false);
  }

  async deleteComment(commentId, files, isRequest = false) {
    if (!commentId) return;
    this.setIsLoading(true);
    try {
      await files.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.deleteAttachment(file.id);
          }),
        Promise.resolve(),
      );
      await ticketApi.deleteComment(commentId);

      this.fetchDetailContent(this.ticketId);
    } catch (err) {
      setError(err, false, NotificationText.deleteCommentError);
      showErrorNotification(NotificationText.deleteCommentError);
    }
    this.setIsLoading(false);
  }

  // NOTE: Question
  updateDraftQuestion(assignTo = null, title = null, attachments = null) {
    if (assignTo) {
      this.draftQuestion.assignTo = assignTo;
    }
    if (title) {
      this.draftQuestion.title = title;
    }
    if (attachments) {
      this.draftQuestion.attachments = attachments;
    }
  }

  async updateQuestion(id, assignTo, title, addedFiles, removedFiles) {
    try {
      await ticketApi.updateQuestion(id, title, assignTo);

      await removedFiles?.reduce(
        (callback, file) =>
          callback.then(async () => await attachmentApi.deleteAttachment(id, file.id)),
        Promise.resolve(),
      );
      await addedFiles?.reduce(
        (callback, file) => callback.then(async () => await attachmentApi.addAttachment(id, file)),
        Promise.resolve(),
      );
    } catch (err) {
      setError(err, false, NotificationText.updateQuestionError);
      showErrorNotification(NotificationText.updateQuestionError);
    }
  }

  reset() {
    this.isCopied = false;
    this.draftComment = DefaultComment;
    this.draftQuestion = DefaultQuestion;
  }

  setIsLoading(status) {
    this.isLoading = status;
  }

  async deleteTask(ticketId) {
    this.setIsLoading(true);
    this.reset();
    try {
      await createApi.deleteTask(ticketId);
    } catch (err) {
      showErrorNotification(NotificationText.deleteTicketError);
    }
    this.setIsLoading(false);
  }

  async fetchDetailContent(ticketId, isRefresh = true) {
    if (isRefresh) {
      this.setIsLoading(true);
      this.reset();
    }
    try {
      const parsedData = await detailApi.getTicket(ticketId);
      try {
        const configFiles = getWorkflowFiles(parsedData.workflow, parsedData.config);

        runInAction(() => {
          this.parsedData = {
            ...parsedData,
            assign: getAssignFromId(parsedData.assignedTo, this.assigns),
            owner: getAssignFromId(parsedData.ownedBy, this.assigns),
            createdBy: getAssignFromId(parsedData.createdById, this.assigns),
            discussions: parsedData.discussions?.map((discussion) => ({
              ...discussion,
              assign: getAssignFromId(discussion.createdBy, this.assigns),
            })),
            caseMessages: parsedData.caseMessages.map((message) =>
              parseCaseMessage(message, this.assigns),
            ),
            configFiles,
          };
        });
      } catch (err) {
        setError(err, false, NotificationText.getWorkflowInfoError);
        showErrorNotification(NotificationText.getWorkflowInfoError);
      }
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
    if (isRefresh) this.setIsLoading(false);
  }

  async fetchAssigns(organizationId) {
    if (!organizationId) return;

    try {
      const assigns = await organizationApi.getAssigners(organizationId);
      runInAction(() => {
        this.assigns = sortMembers(assigns);
        this.workListStore.assigns = this.assigns;
        this.assignOptions = this.assigns.map(mapToUserOption);
      });
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
  }
}
