import { Button, Modal, Typography } from '@/components';
import { ThreeUsersIcon } from '@/assets/icons';
import styles from './ReactivateMemberModal.module.css';

const PAGE_NAME = 'ReactivateMemberModal';

export const ReactivateMemberModal = ({ member, onUpdate, onClose }) => {
  return (
    <Modal
      open
      isCloseIconHidden
      id={PAGE_NAME}
      onClose={onClose}
      containerClassName={styles.modalContainer}
      contentClassName={styles.successContent}
      titleClassName={styles.modalTitle}
      actions={
        <>
          <Button size="large" onClick={onUpdate} id={`${PAGE_NAME}_removeMemberButton`}>
            Reactivate User
          </Button>
          <Button
            size="large"
            variant="tertiary"
            onClick={onClose}
            id={`${PAGE_NAME}_cancelRemoveButton`}
          >
            Cancel
          </Button>
        </>
      }
    >
      <ThreeUsersIcon className={styles.threeUsersIcon} />
      <Typography variant="h5">{`Reactivate ${member?.name}?`}</Typography>
      <Typography variant="subtitle3" className={styles.description}>
        Are you sure you want to reactivate {member?.name}’s account? Once reactivated, they will
        have access to HelpDesk again.
      </Typography>
    </Modal>
  );
};
