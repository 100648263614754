import { Config } from '@/config';
import * as fetchWithAuth from './fetchWithAuth';
import { FetchOptions } from './constants';

export const addComment = (ticketId, data) => {
  return fetchWithAuth
    .postFormData(`${Config.NEW_API}/Discussion?ticketId=${ticketId}`, data)
    .then((resp) => resp);
};

export const updateTicket = (taskId, data) => {
  return fetchWithAuth.put(`${Config.NEW_API}/Ticket/${taskId}`, data).then((resp) => resp);
};

export const updateComment = (discussionId, comment) => {
  const formData = new FormData();
  formData.append('comment', comment);
  return fetchWithAuth
    .putFormData(`${Config.NEW_API}/Discussion/${discussionId}`, formData)
    .then((resp) => resp);
};

export const deleteComment = (discussionId) => {
  return fetchWithAuth.del(`${Config.NEW_API}/Discussion/${discussionId}`).then((resp) => resp);
};

export const updateQuestion = (helpItemId, title, assignTo) => {
  return fetchWithAuth
    .put(
      `${
        Config.NEW_API
      }/HelpItem/UpdateHelpItem?HelpItemId=${helpItemId}&Title=${encodeURIComponent(
        title,
      )}&assignTo=${assignTo}`,
    )
    .then((resp) => resp);
};

export const moveTicket = (taskId, toParentId) => {
  return fetchWithAuth
    .put(`${Config.NEW_API}/Ticket/${taskId}/move?parentId=${toParentId}`, {}, FetchOptions.status)
    .then((resp) => resp);
};

export const remindTicket = (ticketId) => {
  return fetchWithAuth
    .post(`${Config.NEW_API}/Ticket/${ticketId}/remind`, {}, FetchOptions.status)
    .then((resp) => resp);
};
