import { observer } from 'mobx-react-lite';
import { CommentItem } from '../CommentItem/CommentItem';
import { Typography } from '@/components';
import { isCreatedByYou } from '@/utils';

import styles from './CommentContent.module.css';

const PAGE_NAME = 'CommentContent';

export const CommentContent = observer(
  ({ label = 'Comment(s)', comments, updateComment, deleteComment }) => {
    if (!comments?.length) return null;

    return (
      <div
        id={`${PAGE_NAME}_commentsContainer`}
        className={comments.length ? styles.content : styles.emptyContent}
      >
        {label && <Typography variant="subtitle2">{label}</Typography>}
        {comments.map((comment) => (
          <CommentItem
            id={`${PAGE_NAME}_commentItem`}
            comment={comment}
            key={comment.id}
            createdByYou={isCreatedByYou(comment.assign.id)}
            handleCommentUpdate={(text, addingFiles, deletingFiles) =>
              updateComment(comment.id, text, comment.attachments, addingFiles, deletingFiles)
            }
            handleCommentDelete={() => deleteComment(comment.id, comment.attachments)}
          />
        ))}
      </div>
    );
  },
);
