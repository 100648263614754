import { orderBy } from 'lodash';
import { Config } from '@/config';
import * as fetchWithAuth from './fetchWithAuth';
import { AssignTypeOptions, mapToUserOption } from '@/utils';

function parseFilterSetsFromResponse(obj) {
  return {
    assigned:
      obj.assigned?.map((assign, index) =>
        mapToUserOption({ ...assign, type: index % AssignTypeOptions.length }),
      ) || [],
    services: (obj.services ?? []).map((workflow) => ({
      value: workflow,
      label: workflow,
    })),
    organizations: orderBy(
      Object.keys(obj.organizations ?? {}).map((name) => ({
        value: obj.organizations[name],
        label: name,
      })),
      'label',
    ),
  };
}

export const getFilters = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Ticket/log/filters?organizationId=${orgId}`)
    .then((resp) => parseFilterSetsFromResponse(resp));
};

function parseReportingFilterSetsFromResponse(obj) {
  return {
    assigned: Object.keys(obj.assigned ?? {}).map((name) => obj.assigned[name]) || [],
    organizations: orderBy(
      Object.keys(obj.organizations ?? {}).map((name) => ({
        value: obj.organizations[name],
        label: name,
      })),
      'label',
    ),
  };
}

export const getReporingFilters = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Picklist/filters`)
    .then((resp) => parseReportingFilterSetsFromResponse(resp));
};
