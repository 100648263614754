import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { TextField } from '@/components';

const PAGE_NAME = 'AmazonVendorCentralLogin';

export const AmazonVendorCentral = ({
  username,
  onUsernameChange,
  showError,
  showWarning,
  defaultValue,
  onChange,
  disabled,
}) => {
  const [templateData, setTemplateData] = useState(defaultValue ?? {});

  useEffect(() => setTemplateData(defaultValue), [defaultValue]);

  const getValidator = (username, data) => Boolean(username && data.Account);
  const handleChangeData = (field, e) => {
    const newData = { ...templateData, [field]: e.target.value };
    setTemplateData(newData);
    onChange(newData, getValidator(username, newData));
  };
  const handleChangeUsername = (e) => {
    const newName = e.target.value;
    onUsernameChange(newName, getValidator(newName, templateData));
  };
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <TextField
          label="User Name*"
          value={username ?? ''}
          error={showError && !username}
          warning={showWarning && !username}
          helperText={showError && !username ? 'Please provide your name.' : null}
          onChange={handleChangeUsername}
          id={`${PAGE_NAME}_userName`}
          size="small"
          disabled={disabled}
          inactive={disabled}
        />
        <TextField
          label="Account*"
          value={templateData.Account ?? ''}
          error={showError && !templateData.Account}
          warning={showWarning && !templateData.Account}
          helperText={showError && !templateData.Account ? 'Please provide your Account.' : null}
          onChange={(e) => handleChangeData('Account', e)}
          id={`${PAGE_NAME}_account`}
          size="small"
          disabled={disabled}
          inactive={disabled}
        />
      </Stack>
      <Stack spacing={2} direction="row">
        <TextField
          label="Merchant"
          value={templateData.Merchant ?? ''}
          onChange={(e) => handleChangeData('Merchant', e)}
          id={`${PAGE_NAME}_userName`}
          size="small"
          disabled={disabled}
          inactive={disabled}
        />
        <TextField
          label="Marketplace"
          value={templateData.Marketplace ?? ''}
          onChange={(e) => handleChangeData('Marketplace', e)}
          id={`${PAGE_NAME}_marketplace`}
          size="small"
          disabled={disabled}
          inactive={disabled}
        />
      </Stack>
    </Stack>
  );
};
