import PropTypes from 'prop-types';
import cx from 'clsx';

import ListItemIcon from '@mui/material/ListItemIcon';
import { makeStyles } from '@mui/styles';

import MenuItem from '../MenuItem';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: '52px',
    lineHeight: '24px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    fontWeight: '400 !important',
    fontSize: '16px !important',
    '&:hover': {
      background: `${theme.palette.options.hover} !important`,
      color: `${theme.palette.secondary.darkBlue} !important`,
    },
    '&:hover svg': {
      fill: `${theme.palette.secondary.darkBlue} !important`,
    },
  },
  menuItemSmall: {
    height: '36px',
    lineHeight: '17px',
  },
  menuItemDisabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
    '&:hover svg': {
      fill: `${theme.palette.neutrals.greyMed} !important`,
    },
  },
  listItemIcon: {
    paddingRight: theme.spacing(1),
    color: `${theme.palette.secondary.darkBlue} !important`,
    minWidth: 'auto',
    fontSize: '24px',
    '& .MuiSvgIcon-root': {
      fontSize: 'inherit',
    },
  },
  listItemSmallIcon: {
    fontSize: '16px',
  },
  listItemDisabledIcon: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
}));

const ContextMenuItem = ({ classes = {}, icon, children, small, disabled, ...otherProps }) => {
  const styles = useStyles();

  return (
    <MenuItem
      id="ContextMenuItem_MenuItem"
      classes={{
        ...classes,
        root: cx(
          styles.menuItem,
          {
            [styles.menuItemSmall]: small,
            [styles.menuItemDisabled]: disabled,
          },
          classes.root,
        ),
      }}
      {...otherProps}
    >
      {Boolean(icon) && (
        <ListItemIcon
          classes={{
            root: cx(styles.listItemIcon, {
              [styles.listItemSmallIcon]: small,
            }),
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      {children}
    </MenuItem>
  );
};

ContextMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

ContextMenuItem.defaultProps = {
  small: false,
  disabled: false,
  icon: null,
};

export default ContextMenuItem;
