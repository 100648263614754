export const AssignType = {
  unassigned: 'Unassigned',
  user: 'user',
  helpdesk: 'helpdesk',
  workflow: 'workflow',
  amazon: 'amazon',
  lightBlue: 'light-blue',
  lightPurple: 'light-purple',
  darkBlue: 'dark-blue',
  purple: 'purple',
  teal: 'teal',
  organization: 'organization',
};

export const Unassigned = {
  id: null,
  value: null,
  name: AssignType.unassigned,
  label: AssignType.unassigned,
  email: '',
  type: AssignType.unassigned,
};
