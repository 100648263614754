import { useState } from 'react';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import { Typography } from '@/components';

const useStyles = makeStyles((theme) => ({
  moreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    width: 42,
    minWidth: 42,
    height: 22,
    border: '2px solid transparent',
    borderRadius: 70,
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #E2E5EB',
    },
  },
  clicked: {
    border: `2px solid ${theme.palette.primary.purple}`,
  },
  point: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    background: theme.palette.neutrals.grey,
  },
  clickedPoiint: {
    background: theme.palette.primary.purple,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  smallButton: {
    padding: '0px 8px 0px 0px !important',
  },
  menuItem: {
    cursor: 'pointer',
    padding: '2px 16px',
    textAlign: 'start',
    '&:hover': {
      background: theme.palette.neutrals.grayBlue,
    },
  },
}));

export const MoreButton = ({ cancelText, onUpdate, onDelete, classes }) => {
  const styles = useStyles();
  const [anchorEl, setAnchoreEl] = useState(null);

  return (
    <>
      <div
        className={cx(styles.moreButton, {
          [styles.clicked]: Boolean(anchorEl),
        })}
        onClick={(e) => setAnchoreEl(e.currentTarget)}
      >
        <div
          className={cx(styles.point, {
            [styles.clickedPoiint]: Boolean(anchorEl),
          })}
        />
        <div
          className={cx(styles.point, {
            [styles.clickedPoiint]: Boolean(anchorEl),
          })}
        />
        <div
          className={cx(styles.point, {
            [styles.clickedPoiint]: Boolean(anchorEl),
          })}
        />
      </div>

      <Popover
        anchorEl={anchorEl}
        id={`MoreMenu_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchoreEl(null)}
        PaperProps={{
          style: {
            marginTop: 8,
            width: 102,
            borderRadius: 16,
            boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
            textAlign: 'center',
          },
        }}
      >
        <div className={styles.content}>
          {Boolean(onUpdate) && (
            <Typography
              variant="body1"
              className={cx(styles.menuItem, classes?.menuItem)}
              onClick={() => {
                onUpdate();
                setAnchoreEl(null);
              }}
              id={`MoreButton_EditItem`}
            >
              Edit
            </Typography>
          )}
          <Typography
            variant="body1"
            className={cx(styles.menuItem, classes?.menuItem)}
            onClick={() => {
              onDelete();
              setAnchoreEl(null);
            }}
            id={`MoreButton_RemoveItem`}
          >
            {cancelText || 'Delete'}
          </Typography>
        </div>
      </Popover>
    </>
  );
};
