import { Config } from '@/config';
import { FetchOptions } from './constants';
import * as fetchWithAuth from './fetchWithAuth';

export const getProductsTemplateFile = () => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Template/RainforestProductsTemplate`, FetchOptions.stream)
    .then((res) => {
      const contentType = res.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        return res.json();
      } else {
        return res.blob();
      }
    })
    .then((data) => {
      if (data instanceof Blob) {
        return URL.createObjectURL(data);
      } else {
        return null;
      }
    });
};
