import { Config } from '@/config';
import * as fetchWithAuth from './fetchWithAuth';

export const createTicket = async (data) => {
  return fetchWithAuth
    .post(`${Config.NEW_API}/Requests/Create${data.workflow}`, data)
    .then((resp) => resp.id);
  return fetchWithAuth.post(`${Config.NEW_API}/Ticket`, data).then((resp) => resp);
};

const parseTaskTypesFromResponse = (obj) => {
  return Object.keys(obj).map((key) => ({
    id: obj[key],
    name: key,
  }));
};

export const getTaskTypes = async (requestId) => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Ticket/${requestId}/types`)
    .then((resp) => parseTaskTypesFromResponse(resp));
};

export const deleteTask = async (taskId) => {
  return await fetchWithAuth.del(`${Config.NEW_API}/Ticket/${taskId}`).then((resp) => resp);
};
