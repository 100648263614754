import { observer } from 'mobx-react-lite';

import { Modal } from '@/components';
import { CreateTaskPage } from '@/pages';
import { withStore } from '@/services/store';

import styles from './DetailCreateTask.module.css';

const CreateTaskModal = ({ open, handleClose }) => {
  return (
    <Modal
      containerClassName={styles.modalContainer}
      contentClassName={styles.modalContent}
      open={open}
      onClose={handleClose}
    >
      <ModalContainer>
        <CreateTaskPage onClose={handleClose} />
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = ({ children }) => {
  return <div className={styles.modalContentContainer}>{children}</div>;
};

export default withStore(observer(CreateTaskModal));
