import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  main: {
    width: '411px !important',
    display: 'flex !important',
    flexWrap: 'nowrap !important',
    alignItems: 'center !important',
    borderRadius: '12px !important',
    padding: '16px 23px !important',
    boxShadow: '0px 10px 10px rgba(0,0,0,0.1) !important',
    marginBottom: theme.spacing(2),
  },
  mainMobile: {
    width: 'calc(100vw - 16px) !important',
  },
  success: {
    backgroundColor: `${theme.palette.ui.success} !important`,
  },
  error: {
    backgroundColor: '#FCBFBF !important',
  },
  general: {
    backgroundColor: '#FFF !important',
  },
  content: {
    fontFamily: 'Open Sans !important',
    fontSize: '16px !important',
    lineHeight: '143% !important',
    letterSpacing: '0.17px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    fontWeight: '700  !important',
    whiteSpace: 'normal  !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    '-webkit-box-orient': 'vertical !important',
    '-webkit-line-clamp': 2,
    height: 'fit-content !important',
    display: '-webkit-box !important',
    flexDirection: 'column !important',
    justifyContent: 'center !important',
  },
  closeIconButton: {
    '&.MuiButtonBase-root': {
      padding: '5px  !important',
      '&:hover': { backgroundColor: 'transparent  !important' },
      '&:active': { backgroundColor: 'transparent !important' },
    },
  },
  closeIcon: {
    width: '14px  !important',
    height: '14px  !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    '&.MuiSvgIcon-root': {
      fontSize: '14px !important',
      transform: 'scale(1.715) !important',
    },
    '&:hover': {
      color: `${theme.palette.neutrals.grayBlue} !important`,
    },
  },
  messageContainer: {
    overflowX: 'hidden !important',
    padding: '0px !important',
    flex: 1,
  },
  messageWrapper: {
    display: 'flex !important',
  },
  message: {
    display: 'flex !important',
    flex: 1,
    alignItems: 'center !important',
    height: '100% !important',
    overflowY: 'hidden !important',
    justifyContent: 'center !important',
    flexDirection: 'column !important',
  },
  messageContent: {
    overflowX: 'hidden !important',
    textOverflow: 'ellipsis !important',
    width: '100% !important',
  },
  tooltip: {
    marginTop: '8px !important',
    color: `${theme.palette.primary.contrastText} !important`,
  },
}));
