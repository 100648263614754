import { SvgIcon } from '@mui/material';

export const UploadIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 49 49" width="48px" height="48px">
      <mask
        id="mask0_7969_66977"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="49"
        height="48"
      >
        <rect x="0.5" width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7969_66977)">
        <path
          d="M23 39.9969H13.05C10.1167 39.9969 7.625 38.9719 5.575 36.9219C3.525 34.8719 2.5 32.3802 2.5 29.4469C2.5 26.8469 3.33333 24.5635 5 22.5969C6.66667 20.6302 8.78333 19.4469 11.35 19.0469C12.0167 15.8135 13.5833 13.1719 16.05 11.1219C18.5167 9.07187 21.3667 8.04687 24.6 8.04688C28.3333 8.04688 31.4833 9.40521 34.05 12.1219C36.6167 14.8385 37.9 18.0969 37.9 21.8969V23.0969C40.3 23.0302 42.3333 23.8052 44 25.4219C45.6667 27.0385 46.5 29.0802 46.5 31.5469C46.5 33.8469 45.6667 35.8302 44 37.4969C42.3333 39.1635 40.35 39.9969 38.05 39.9969H26V24.0969L30.15 28.2469L32.3 26.0969L24.5 18.2969L16.7 26.0969L18.85 28.2469L23 24.0969V39.9969Z"
          fill="#AFAFAF"
        />
      </g>
    </SvgIcon>
  );
};
