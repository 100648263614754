import { makeObservable, computed } from 'mobx';
import { rootStore } from '@/services/store/RootStore';
import { accountStore } from '../../../services/store/AccountStore';
import { FilterStore } from './FilterStore';
import { TableStore } from './TableStore';
import { TaskLogStore } from './TaskLogStore';

export class MainStore {
  constructor() {
    makeObservable(this, {
      isLoading: computed,
    });

    this.routerStore = rootStore.routerStore;
    this.accountStore = accountStore;
    this.filterStore = new FilterStore(this.accountStore);
    this.tableStore = new TableStore(this.accountStore, this.filterStore);
    this.taskLogStore = new TaskLogStore(this.accountStore, this.tableStore, this.filterStore);
  }

  get isLoading() {
    return this.accountStore.isLoading || this.taskLogStore.isLoading;
  }

  dispose() {
    this.accountStore.dispose();
    this.taskLogStore.dispose();
  }
}
