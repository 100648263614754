import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import Typography from '@/components/Typography';
import { formatLocalDateTime } from '@/utils';
import { useStyles } from './sharedStyles';
import cx from 'clsx';

export const DateTimeCell = ({ column, value, isLastRow }) => {
  const styles = useStyles();

  return (
    <TableCell
      key={column}
      className={cx(styles.bodyCell, styles.textCell, { [styles.bodyLastRightCell]: isLastRow })}
      id="DateTimeCell"
    >
      <Typography variant="caption" className={styles.description}>
        {formatLocalDateTime(value, 'MM/DD/YY') || 'None'}
      </Typography>
    </TableCell>
  );
};

DateTimeCell.propTypes = {
  column: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
