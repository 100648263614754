import { SvgIcon } from '@mui/material';

export const ThreeUsersIcon = (props) => {
  return (
    <SvgIcon {...props} width="119" height="85" viewBox="0 0 119 85" fill="none">
      <path
        opacity="0.3"
        d="M87.5984 32.525C96.58 32.525 103.861 25.244 103.861 16.2625C103.861 7.28097 96.58 0 87.5984 0C78.6169 0 71.3359 7.28097 71.3359 16.2625C71.3359 25.244 78.6169 32.525 87.5984 32.525Z"
        fill="#B39DFF"
      />
      <path
        d="M59.4988 32.525C68.4804 32.525 75.7613 25.244 75.7613 16.2625C75.7613 7.28097 68.4804 0 59.4988 0C50.5173 0 43.2363 7.28097 43.2363 16.2625C43.2363 25.244 50.5173 32.525 59.4988 32.525Z"
        fill="#B39DFF"
      />
      <path
        d="M31.3303 32.525C40.2759 32.525 47.5277 25.244 47.5277 16.2625C47.5277 7.28097 40.2759 0 31.3303 0C22.3847 0 15.1328 7.28097 15.1328 16.2625C15.1328 25.244 22.3847 32.525 31.3303 32.525Z"
        fill="#5D36EF"
      />
      <path
        opacity="0.3"
        d="M54.8164 61.0145C54.8515 48.1469 65.2936 37.7266 78.1694 37.7266H95.3426C108.219 37.7266 118.66 48.1469 118.696 61.0145V61.0795V84.4325H54.8164V61.0145Z"
        fill="#B39DFF"
      />
      <path
        d="M21.1211 61.0145C21.1562 48.1469 31.5983 37.7266 44.4741 37.7266H61.6473C74.523 37.7266 84.9651 48.1469 85.0001 61.0145L85.0002 61.0795V84.4325H21.1211V61.0145Z"
        fill="#B39DFF"
      />
      <path
        d="M0.304688 61.0145C0.339815 48.1469 10.7819 37.7266 23.6576 37.7266H40.8309C53.7066 37.7266 64.1487 48.1469 64.1837 61.0145L64.1838 61.0795V84.4325H0.304688V61.0145Z"
        fill="#5D36EF"
      />
    </SvgIcon>
  );
};
