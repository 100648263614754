import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { orderBy, trim } from 'lodash';
import { organizationApi, userApi } from '@/api';
import { MemberSearchKeys, MemberStatus, NotificationText } from '@/types';
import { userStore } from '@/services/store/UserStore';
import { sortMembers } from '@/utils';
import { setError } from '@/utils/errors';

export const MemberFilterType = {
  status: 'status',
  organization: 'organization',
};

const InitFilterList = {
  status: {
    key: 'status',
    label: 'Status',
    values: [
      { value: MemberStatus.active },
      { value: MemberStatus.invited },
      { value: MemberStatus.inactive },
    ],
    selectedValues: [],
  },
  organization: {
    key: 'organization',
    label: 'Organization',
    values: [],
    selectedValues: [],
  },
};
export class MembersStore {
  selectedOrgId = null;
  organization = null;
  accounts = [];
  allAccounts = [];
  members = [];
  pageRows = 10;
  currentPage = 1;
  currentSort = { name: 'name', direction: 'asc' };
  selectedAccount = {};
  isLoading = true;

  filterList = InitFilterList;
  filterSearch = '';
  search = '';

  constructor(routerStore) {
    makeObservable(this, {
      organization: observable,
      accounts: observable,
      allAccounts: observable,

      selectedOrgId: observable,
      selectOrganizationId: action,

      members: observable,
      allFilteredMembers: computed,
      filteredCurrentPageMembers: computed,

      pageRows: observable,
      setPageRows: action,

      paginationCounts: computed,
      currentPage: observable,
      setPageNumber: action,

      isLoading: observable,
      setIsLoading: action,

      currentSort: observable,
      filterList: observable,
      filteredResult: computed,
      allFilteredCount: computed,

      setMembers: action,

      filterSearch: observable,
      setFilterSearch: action,

      search: observable,
      setSearch: action,
      clearFilter: action,
      isSearchedResultEmpty: computed,
      onToggleExpand: action,
      selectedAccount: observable,
      onSelectAccount: action,

      updateFilterValues: action,
    });

    this.routerStore = routerStore;

    reaction(
      () => [this.allAccounts],
      () => this.fetchMembers(),
      { fireImmediately: true },
    );
  }

  isActiveSort(sortName) {
    return this.currentSort.name === sortName;
  }

  getSortDirection(sortName) {
    if (this.currentSort.name === sortName) return this.currentSort.direction;
    return 'asc';
  }

  toggleSort(name) {
    let direction = 'asc';
    if (this.currentSort.name === name) {
      direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'desc';
    }
    this.currentSort = { name, direction };
  }

  get paginationCounts() {
    return Math.ceil(this.allFilteredMembers.length / this.pageRows);
  }

  setPageNumber(value) {
    this.currentPage = value;
  }

  setPageRows(value) {
    this.pageRows = value;
    if (this.currentPage > this.paginationCounts) this.setPageNumber(1);
  }

  selectOrganizationId(id) {
    this.selectedOrgId = id;
  }

  async createSaveUser(user, warningTitle) {
    let result = false;
    this.setIsLoading(true);
    try {
      const organization = this.getAccountFromId(this.allAccounts, user.organizationId);
      const organizationPath = organization
        ? organization.parentPath.concat(organization.path).join('/')
        : `${user.organizationId}`;
      const newUser = { ...user, organizationPath };
      await userApi.saveUser(newUser);
      if (userStore.currentUser.id === newUser.id) {
        userStore.setUser({ ...userStore.currentUser, ...newUser });
      }
      result = true;
      await this.fetchMembers();
    } catch (err) {
      setError(err, false, warningTitle);
    }
    this.setIsLoading(false);
    return result;
  }

  async removeUser(userId, email, helpItemCount, reassignMemberId) {
    this.setIsLoading(true);
    try {
      if (helpItemCount > 0) {
        await userApi.reassignTasks(userId, reassignMemberId);
      }
      const userInfo = await userApi.removeUser(userId, email);
      await this.fetchMembers();
      return userInfo;
    } catch (err) {
      setError(err, false, NotificationText.removeUserError);
    }
    this.setIsLoading(false);
    return null;
  }

  get allFilteredMembers() {
    const filter = this.filteredResult;
    const filteredMembers = this.members.filter((member) =>
      Object.keys(filter).every((key) => {
        if (filter[key]?.selectedValues?.length === 0) return true;
        return filter[key].selectedValues.includes(member[key]);
      }),
    );

    const orderedMembers = orderBy(
      filteredMembers,
      [(member) => member[this.currentSort.name]?.toString().toLowerCase(), 'date'],
      [this.currentSort.direction, 'desc'],
    );

    return orderedMembers.reduce((result, member) => {
      let match, matchedField;
      if (
        MemberSearchKeys.some((key) => {
          if (this.search.length === 0) return true;
          if (!member[key]) return false;
          // get match by search filter
          const startIndex = member[key].toLowerCase().indexOf(this.search.toLowerCase());
          if (startIndex === -1) return false;
          match = [startIndex, startIndex + this.search.length];
          matchedField = key;
          return true;
        })
      ) {
        if (matchedField) return [...result, { ...member, match, matchedField }];
        return [...result, member];
      }
      return result;
    }, []);
  }

  get filteredCurrentPageMembers() {
    return this.allFilteredMembers.slice(
      (this.currentPage - 1) * this.pageRows,
      this.currentPage * this.pageRows,
    );
  }

  getOrganizationInfo(accounts, orgId) {
    return accounts.reduce((res, account) => {
      if (account.id === orgId) {
        return account;
      }
      if (account.children.length > 0) {
        return this.getOrganizationInfo(account.children, orgId) || res;
      }
      return res;
    }, null);
  }

  getOrganizationName(orgId) {
    return this.getOrganizationInfo(this.allAccounts, orgId)?.name ?? '';
  }

  async fetchMembers() {
    if (!this.allAccounts.length) return;
    this.setIsLoading(true);
    try {
      const users = await organizationApi.getOrganizationUsers(userStore.organizationId);
      runInAction(() => {
        const members = sortMembers(users).map((user) => ({
          ...user,
          organization: this.getOrganizationName(user.organizationId),
        }));
        this.setMembers(members);
      });
    } catch (err) {
      setError(err);
    }
    this.setIsLoading(false);
  }

  async fetchAllAccounts(organizationId) {
    if (!organizationId) return;
    this.setIsLoading(true);

    try {
      const { parent: organization, children: accounts } =
        await organizationApi.getOrganizationFullTree(organizationId, true);
      this.organization = organization;
      this.allAccounts = [this.organization, ...accounts];
      this.accounts = accounts;
      if (this.accounts.length > 0) {
        this.selectOrganizationId(this.accounts?.[0].id);
      }
    } catch (err) {}
    this.setIsLoading(false);
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  //NOTE: Fetch filter list from members
  setMembers(members) {
    this.members = members;
    const filters = this.filterList;

    this.members.forEach((member) => {
      // status filter
      if (
        !filters.organization.values.find(
          ({ value }) => value.toLowerCase() === member.organization.toLowerCase(),
        )
      ) {
        filters.organization.values.push({ value: member.organization });
      }
    });
    this.filterList = filters;
  }

  get filteredResult() {
    const filteredResult = Object.keys(this.filterList).reduce((result, filterKey) => {
      const newValues = [];
      this.filterList[filterKey].values.forEach((item) => {
        // get match by search filter
        const startIndex = item.value?.toLowerCase().indexOf(this.filterSearch.toLowerCase());
        if (startIndex !== -1) {
          newValues.push({
            ...item,
            match: [startIndex, startIndex + this.filterSearch.length],
          });
        }
      });

      return {
        ...result,
        [filterKey]: { ...this.filterList[filterKey], values: newValues },
      };
    }, {});

    return filteredResult;
  }

  selectAll() {
    this.setFilterSearch('');
    this.filterList = Object.keys(this.filterList).reduce(
      (result, key) => ({
        ...result,
        [key]: { ...this.filterList[key], selectedValues: [] },
      }),
      {},
    );
  }

  updateFilterValues(key, value) {
    this.filterList[key].selectedValues = value;
  }

  toggleCheckbox(type, selectedValue, checked) {
    const currentValues = this.filterList[type]?.selectedValues;
    if (checked) {
      this.filterList[type].selectedValues = [...currentValues, selectedValue];
    } else {
      this.filterList[type].selectedValues = currentValues?.filter(
        (value) => value !== selectedValue,
      );
    }
  }

  setFilterSearch(value) {
    this.filterSearch = value;
  }

  setSearch(value) {
    this.search = trim(value);
  }

  get isSearchedResultEmpty() {
    return Object.keys(this.filteredResult).every(
      (key) => this.filteredResult[key].values.length === 0,
    );
  }

  clearFilter() {
    this.setSearch('');
    this.selectAll();
  }

  getSelectedCount(type) {
    return this.filteredResult[type]?.selectedValues?.length || 0;
  }

  get allFilteredCount() {
    const count = Object.keys(this.filteredResult).reduce(
      (acc, key) => acc + this.getSelectedCount(key),
      0,
    );
    return count;
  }

  toggleExpandMore(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) {
        accounts[i] = {
          ...accounts[i],
          isExpanded: account.isExpanded ? false : true,
        };
        return;
      }
      if (account.children.length > 0) {
        const result = this.toggleExpandMore(account.children, selectedId);
        if (result) return;
      }
    }
  }

  onToggleExpand(accountId) {
    this.toggleExpandMore(this.accounts, accountId);
  }

  resetAccounts(accounts) {
    return accounts.map((account) => {
      return {
        ...account,
        isExpanded: false,
        children: account.children.length > 0 ? this.resetAccounts(account.children) : [],
      };
    });
  }

  updateAccounts(accountId) {
    const selectedAccount = this.getAccountFromId(this.allAccounts, accountId);
    if (!selectedAccount) return;
    this.accounts = this.resetAccounts(this.accounts);

    let accounts = this.accounts;
    selectedAccount.path.forEach((orgId) => {
      const index = accounts.findIndex((item) => item.id === orgId);
      if (index !== -1) {
        accounts[index] = { ...accounts[index], isExpanded: true };
        accounts = accounts[index].children;
      }
    });
  }

  getAccountFromId(accounts, selectedId) {
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      if (account.id === selectedId) return account;
      if (account.children.length > 0) {
        const result = this.getAccountFromId(account.children, selectedId);
        if (result) return result;
      }
    }
    return null;
  }

  onSelectAccount(accountId) {
    if (!this.allAccounts?.length) return;
    this.selectedAccount = this.getAccountFromId(this.allAccounts, accountId);
  }

  dispose() {
    // TBD
  }
}
