import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { makeStyles } from '@mui/styles';
import { isMobileDetected } from '@/utils/mobileDetect';

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiPaginationItem-root': {
      width: '24px',
      minWidth: '24px',
      height: '24px',
      margin: '0px 10px',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
      color: theme.palette.secondary.darkBlue
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: theme.palette.secondary.darkBlue,
      color: 'white'
    },
    '& .MuiSvgIcon-root.MuiPaginationItem-icon': {
      color: theme.palette.secondary.darkBlue
    }
  },
  hiddenItem: {
    display: 'none'
  }
}));

const Pagination = ({ ...props }) => {
  const styles = useStyles();

  const renderItem = (item) => {
    const { boundaryCount, siblingCount } = props;
    if (
      boundaryCount === 0 &&
      siblingCount === 0 &&
      item.type === 'end-ellipsis'
    ) {
      return <PaginationItem {...item} className={styles.hiddenItem} />;
    }
    return <PaginationItem {...item} />;
  };

  return (
    <MuiPagination
      className={styles.pagination}
      renderItem={renderItem}
      siblingCount={isMobileDetected ? 0 : props.siblingCount}
      {...props}
    />
  );
};

export default Pagination;
