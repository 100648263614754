export const ColumnType = {
  id: 'id',
  workActivityId: 'workActivityId',
  status: 'status',
  state: 'state',
  accountId: 'accountId',
  accountName: 'accountName',
  taskType: 'taskType',
  description: 'description',
  createdDate: 'createdDate',
  ownerId: 'ownerId',
  ownedByName: 'ownedByName',
  ownerType: 'ownerType',
  assignedId: 'assignedId',
  assignedName: 'assignedName',
  assignType: 'assignType',
  commenterName: 'commenterName',
  commenterType: 'commenterType',
  lastUpdated: 'lastUpdated',
  isPinned: 'isPinned',
  isRead: 'isRead',
  externalId: 'externalId',
  externalStatus: 'externalStatus',
  priority: 'priority',
  estimatedDate: 'ecd',
  intentType: 'intentType',
  objectType: 'objectType',
  remainingWork: 'remainingWork',
  requestedByDate: 'requestedByDate',
  attachments: 'attachments',
  addedAttachments: 'deletedAttachments',
  deletedAttachments: 'deletedAttachments',
  // Reporting Log Table
  createdAfter: 'createdAfter',
  service: 'service',
  totalRequests: 'totalRequests',
  quantity: 'quantity',
  pastDue: 'pastDue',
  resolvedRequests: 'resolvedRequests',
  sumOfCount: 'sumOfCount',
  daysToResolve: 'daysToResolve',
  pastDueResolve: 'pastDueResolve',
  openRequests: 'openRequests',
  pastDueOpen: 'pastDueOpen',
  pastDueOpenCount: 'pastDueOpenCount',
  avgResolution: 'avgResolution',
  standardDeviation: 'standardDeviation',
  cases: 'cases',
  casePerRequest: 'casePerRequest',
};
