import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DoneIcon from '@mui/icons-material/Done';
import { userApi } from '@/api';
import { Button, Modal, Typography } from '@/components';
import { CopyOutlineIcon } from '@/assets/icons';
import { FeedbackLink } from '@/utils/constants';
import { userStore } from '@/services/store';

import styles from './ErrorBoundaryModal.module.css';

const PAGE_NAME = 'ErrorBoundaryModal';
const MAX_TEXT_LIMIT = 2900;

export const ErrorBoundaryModal = observer(
  ({ url, payload, preview, statusText, isCritical, isReload, errorAPIs }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isShowMore, setIsShowMore] = useState(true);

    const errorMessage = useMemo(() => {
      const urlMessage = url ? `URL: ${url}\r\n\r\n` : '';
      const payloadMessage = payload
        ? `Payload: ${payload}\r\n\r\n`
        : Object.entries(errorAPIs).map(([apiURL, statusCode]) => `${apiURL}: ${statusCode}\r\n`);
      const headerString = `${statusText}\r\n${urlMessage}${payloadMessage}`;
      const previewCount = Math.floor((MAX_TEXT_LIMIT - headerString.length) / 2);
      let filteredPreview;
      if (preview.length > MAX_TEXT_LIMIT - headerString.length) {
        filteredPreview = `${preview.substring(0, previewCount)}...\r\n\r\n...${preview.substring(
          preview.length - previewCount,
          preview.length,
        )}`;
      } else {
        filteredPreview = preview;
      }
      setIsCopied(false);
      return {
        text: `${statusText}\r\n${urlMessage}${
          (isShowMore && `${payloadMessage}${filteredPreview}`) || ''
        }\r\n`,
        fullText: `${statusText}\r\n${urlMessage}${
          `${payloadMessage}${filteredPreview}` || ''
        }\r\n`,
      };
    }, [payload, preview, statusText, url, isShowMore]);

    useEffect(() => {
      userApi.sendErrorMessageToSlack(
        `API Error:\nUser Name: ${userStore.currentUser?.name}\n${errorMessage.fullText}`,
      );
    }, [errorMessage, errorMessage.fullText]);

    const copyReport = async () => {
      await navigator.clipboard.writeText(errorMessage.text);
      setIsCopied(true);
    };

    const handleClickReportBug = () => {
      window.open(FeedbackLink, '_blank');
    };

    const handleClickBack = () => {
      window.location.href = '/';
    };

    return (
      <Modal
        open
        id={`${PAGE_NAME}_Modal`}
        title="Sorry, something went wrong"
        onClose={() => userStore.setApiCriticalIssue(null)}
        isCloseIconHidden={isCritical}
        containerClassName={styles.errorHandlerModal}
        contentClassName={styles.errorHandlerContent}
        titleClassName={styles.errorHandlerTitle}
        actions={
          <>
            <Button
              size="large"
              id={`${PAGE_NAME}_details`}
              variant="primary"
              onClick={() => setIsShowMore((prev) => !prev)}
            >
              {`${isShowMore ? 'Hide' : 'Show'} Details`}
            </Button>
            <Button
              size="large"
              id={`${PAGE_NAME}_reportBug`}
              variant="primary"
              onClick={handleClickReportBug}
            >
              Report Bug
            </Button>
            {isReload && (
              <Button
                size="large"
                id={`${PAGE_NAME}_reload`}
                variant="primary"
                onClick={() => window.location.reload()}
              >
                Reload Page
              </Button>
            )}
            {!isReload && (
              <Button
                size="large"
                id={`${PAGE_NAME}_backToHelpdesk`}
                variant="tertiary"
                onClick={handleClickBack}
              >
                Back to HelpDesk
              </Button>
            )}
          </>
        }
      >
        <Typography variant="subtitle3">
          Please copy and paste the following code block so our developers can resolve this issue
          for you.
        </Typography>

        <div className={styles.copyCodeBlock}>
          <Button
            variant="secondary"
            size="medium"
            startIcon={isCopied ? <DoneIcon /> : <CopyOutlineIcon />}
            onClick={copyReport}
          >
            {isCopied ? 'Copied' : 'Copy code block'}
          </Button>
        </div>

        <div className={styles.codeBlock}>
          <Typography variant="subtitle1" className={styles.errorMessage}>
            {errorMessage.text}
          </Typography>
        </div>
      </Modal>
    );
  },
);
