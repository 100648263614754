import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { InputLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      marginTop: 25,
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .Mui-focused .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  rootLabelHidden: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
  },
  rootError: {
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.ui.error} !important`,
    },
  },
  rootDisabled: {
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.neutrals.greyMed} !important`,
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  dropdown: {
    display: 'flex !important',
    alignItems: 'center !important',
    fontFamily: 'Open Sans !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '150% !important',
    letterSpacing: '0.15px !important',
    height: '26px !important',
    padding: '0px 36px 0px 12px !important',
    borderRadius: '30px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    backgroundColor: `#fff !important`,
    border: `1px solid #fff !important`,
  },
  dropdownDark: {
    backgroundColor: `${theme.palette.neutrals.grayBlue} !important`,
    border: `1px solid ${theme.palette.neutrals.grayBlue} !important`,
  },
  dropdownError: {
    border: `1px solid ${theme.palette.ui.error} !important`,
    color: `${theme.palette.secondary.darkBlue} !important`,
    '&:focus': {
      border: `1px solid ${theme.palette.ui.error} !important`,
    },
  },
  dropdownDisabled: {
    backgroundColor: `${theme.palette.neutrals.lightGrey} !important`,
  },
  placeholder: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  selectLabel: {
    fontFamily: 'Open Sans !important',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    transform: 'none !important',
  },
  selectLabelDisabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  selectLabelError: {
    color: `${theme.palette.ui.error} !important`,
  },
  selectOptions: {
    backgroundColor: '#FFFFFF',
    padding: '0px !important',
    '& .MuiMenuItem-root': {
      height: '46px',
      '&:hover': {
        backgroundColor: '#E8E4F9 !important',
      },
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.secondary.lightPurple} !important`,
    },
  },
  selected: {
    border: `1px solid ${theme.palette.primary.purple} !important`,
  },
  icon: {
    transform: 'rotate(0deg) !important',
  },
  iconOpen: {
    transform: 'rotate(180deg) !important',
  },
  paper: {
    marginTop: '8px !important',
    borderRadius: '26px !important',
    maxHeight: '323px !important',
    boxShadow: 'none !important',
    filter: 'drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.05)) !important',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      marginTop: 24,
      marginBottom: 24,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      background: 'rgba(16, 20, 30, 0.15)',
      borderRadius: 26,
    },
  },
}));
const PAGE_NAME = 'DropdownV2';

const DropdownV2 = ({
  isDarkTheme,
  label,
  value,
  options,
  error,
  placeholder,
  disabled,
  MenuProps,
  MenuItemComponent,
  RootStyle,
  DropdownStyle,
  OptionStyle,
  renderValue,
  ...rest
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormControl
      {...rest}
      error={error}
      disabled={disabled}
      variant="standard"
      classes={{
        root: cx(styles.root, RootStyle, {
          [styles.rootLabelHidden]: !label,
          [styles.rootError]: error,
          [styles.rootDisabled]: disabled,
        }),
      }}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor="component-selection-label"
          classes={{
            root: cx(styles.selectLabel, {
              [styles.selectLabelError]: error,
              [styles.selectLabelDisabled]: disabled,
            }),
          }}
        >
          {label}
        </InputLabel>
      )}
      <MuiSelect
        {...rest}
        error={error}
        aria-describedby="component-selection-select"
        displayEmpty
        value={value}
        renderValue={renderValue}
        classes={{
          select: cx(styles.dropdown, DropdownStyle, {
            [styles.dropdownDark]: isDarkTheme,
            [styles.dropdownError]: error,
            [styles.dropdownDisabled]: disabled,
            [styles.selected]: isOpen,
          }),
        }}
        MenuProps={{
          classes: {
            paper: styles.paper,
          },
          MenuListProps: {
            classes: {
              root: cx(styles.selectOptions, OptionStyle),
            },
          },
          ...MenuProps,
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        id={`${PAGE_NAME}_MuiSelect`}
      >
        {options
          .filter((option) => !option.isHidden)
          .map((option) => (
            <MenuItem key={option?.value} value={option?.value} id={`${PAGE_NAME}_MenuItem`}>
              {MenuItemComponent ? (
                <MenuItemComponent option={option} checked={value === option?.value} />
              ) : (
                option?.label
              )}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
};

DropdownV2.propTypes = {
  isDarkTheme: PropTypes.bool,
  RootStyle: PropTypes.string,
  DropdownStyle: PropTypes.string,
  OptionStyle: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
  MenuItemComponent: PropTypes.func,
};

DropdownV2.defaultProps = {
  isDarkTheme: false,
  RootStyle: '',
  DropdownStyle: '',
  OptionStyle: '',
  error: false,
  disabled: false,
  options: [],
  MenuItemComponent: null,
};

export default DropdownV2;
