import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  sideContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F8FAFF',
    borderRight: '1px solid #E9EBEE',
  },
  sideContent: {
    position: 'relative',
    padding: '20px 7px',
    height: '100%',
    width: '228px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topDivider: {
    width: 'calc(100% - 10px)',
    marginLeft: '5px',
    height: 0,
    background: 'rgba(196, 196, 196, 0.15)',
    borderBottom: '1px solid #E9EBEE',
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: '12px',
    overflowY: 'auto',
    gap: '3px',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&:hover': {
      /* width */
      '&::-webkit-scrollbar': {
        width: 8,
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        borderRadius: 8,
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#546596',
        borderRadius: 8,
      },
    },
  },
  customizeItem: {
    bottom: 0,
    paddingRight: 8,
  },
  title: {
    fontWeight: '700 !important',
    fontSize: '16px !important',
    letterSpacing: '0.15px !important',
    color: theme.palette.secondary.darkBlue,
    lineHeight: '26px',
    height: '26px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '9px 4px 20px 20px',
    cursor: 'default',
  },
}));
