import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(3px)',
  },
  modalContainer: {
    backgroundColor: theme.palette.neutrals.grayBlue,
    borderRadius: '26px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxHeight: '80%',
    maxWidth: '80%',
    outline: 'none',
    width: '564px',
  },
  modalTitle: {
    textAlign: 'center',
    color: theme.palette.secondary.darkBlue,
    padding: '48px 48px 16px 48px',
  },
  modalTitleText: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '133.4%',
  },
  modalContent: {
    overflowY: 'auto',
    padding: '0 48px',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  cancelButton: {
    paddingLeft: '0px !important',
    fontSize: '16px !important',
  },
  modalActions: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 48px 40px 48px',
    gap: '24px',
  },
  backDrop: {
    backgroundColor: 'rgba(12, 26, 66, 0.6) !important',
  },
}));
