import PropTypes from 'prop-types';

const Spacing = ({ gap, space }) => {
  const heightRatio = gap > 0 && gap < 7 ? gap : 1;

  return (
    <div
      style={{
        width: '100%',
        height: space || 8 * heightRatio,
        minHeight: space || 8 * heightRatio,
        backgroundColor: 'transparent',
      }}
    />
  );
};

Spacing.propTypes = {
  gap: PropTypes.number,
  space: PropTypes.number,
};

Spacing.defaultProps = {
  gap: 1,
  space: 0,
};

export default Spacing;
