import { NotificationsStore } from './NotificationsStore';
import { RouterStore } from './RouterStore';
export class RootStore {
  constructor() {
    this.routerStore = new RouterStore();
    this.notificationsStore = new NotificationsStore();
  }

  dispose() {
    this.notificationsStore.dispose();
    this.routerStore.dispose();
  }
}

export const rootStore = new RootStore();
