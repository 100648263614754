import PropTypes from 'prop-types';
import cx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { Typography } from '@/components';
import { useRef } from 'react';

const useStyles = makeStyles(({ palette }) => ({
  navItemContainer: {
    color: palette.secondary.darkBlue,
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '8px',
    border: '1px solid transparent',
    height: '46px',
    boxSizing: 'border-box',
    '&:hover': {
      background: palette.neutrals.grayBlue,
    },
  },
  isHover: {
    background: palette.options.hover,
  },
  childItemWithMore: {
    flex: 1,
    padding: '10px 10px 10px 0px',
  },
  childItem: {
    flex: 1,
    padding: '10px',
  },
  itemSelected: {
    fontWeight: 700,
    background: `${palette.options.hover} !important`,
  },
  labelWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  moreIcon: {
    color: palette.secondary.darkBlue,
  },
}));

const TreeSubItem = ({
  isChild,
  isExpanded,
  isAllAccount,
  isSelected,
  label,
  match,
  onSelect,
  onToggleExpand,
  isHover,
}) => {
  const styles = useStyles();
  const singleClickTimer = useRef(null);

  const handleDoubleClick = (e) => {
    clearTimeout(singleClickTimer.current);
    onToggleExpand(e);
  };

  const handleSingleClick = (e) => {
    if (singleClickTimer.current) clearTimeout(singleClickTimer.current);
    singleClickTimer.current = setTimeout(() => {
      onSelect(e);
    }, 300);
  };

  return (
    <div
      className={cx(styles.navItemContainer, {
        [styles.childItemWithMore]: !isAllAccount && isChild,
        [styles.childItem]: !isAllAccount && !isChild,
        [styles.itemSelected]: isSelected,
        [styles.isHover]: isHover,
      })}
      style={{ paddingLeft: 10 }}
      onDoubleClick={handleDoubleClick}
      onClick={handleSingleClick}
      id="sideNavigation_sideMenuItem"
    >
      <div className={styles.labelWrapper}>
        <Typography
          variant={isAllAccount || (!match && isChild) || isSelected ? 'subtitle1' : 'body1'}
          match={match}
        >
          {label}
        </Typography>
      </div>
      {isChild && (
        <ExpandMoreIcon
          className={styles.moreIcon}
          style={{ transform: isExpanded && 'rotate(180deg)' }}
          onClick={onToggleExpand}
        />
      )}
    </div>
  );
};

TreeSubItem.propTypes = {
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAllAccount: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func,
};

TreeSubItem.defaultProps = {
  isChild: false,
  isExpanded: false,
  isAllAccount: false,
  isSelected: false,
  onToggleExpand: undefined,
};

export default TreeSubItem;
