import { last } from 'lodash';
import { MemberStatus, MemberStatusList, Unassigned } from '@/types';

export function parseUser(obj) {
  const name = obj.name?.trim();
  const organizationId =
    obj.organizationId || Number(last(obj.organizationPath.split('/').filter((id) => id)));
  const organizationName = last(obj.organizationPathByName.split('/').filter((name) => name));
  const status = MemberStatusList.filter(({ key }) => obj[key]).map(({ label }) => label) ?? [
    MemberStatus.inactive,
  ];

  if (name.startsWith(Unassigned.name)) {
    Unassigned.id = obj.id;
    Unassigned.value = obj.id;
    Unassigned.name = name;
    Unassigned.organizationId = organizationId;
    Unassigned.organizationName = organizationName;
    Unassigned.status = status;
  }

  return {
    id: obj.id,
    authId: obj.authId,
    email: obj.email,
    emailsEnabled: obj.profile?.emailsEnabled ?? obj.emailsEnabled,
    first: obj.first,
    last: obj.last,
    name: name ?? (obj.first ? `${obj.first?.trim()} ${obj.last?.trim()}` : null),
    image: obj.image,
    organizationId,
    organizationName,
    organizationPath: obj.organizationPath,
    status,
    isActive: obj.isActive,
    isAdmin: obj.isAdmin,
    isContributor: obj.isContributor,
    isAssignable: obj.isAssignable,
    pinnedTickets: obj.profile?.pinnedTickets ?? [],
    pinnedRequests: obj.profile?.pinnedRequests ?? [],
    helpItemTags: obj.helpItemTags ?? [],
    createdOn: obj.createdOn,
    filters: obj.profile?.filters ?? obj.filters ?? {},
    ticketColumns: obj.profile?.ticketColumns,
  };
}

export function parseUserFromObj(obj) {
  return {
    id: obj.id,
    email: obj.email,
    first: obj.first,
    last: obj.last,
    name: `${obj.first} ${obj.last}`,
    organizationId: obj.organizationId,
    organizationPath: obj.organizationPath,
    profile: obj.profile,
    isActive: obj.isActive,
    isAdmin: obj.isAdmin,
    isContributor: obj.isContributor,
    isAssignable: obj.isAssignable,
  };
}
